import { Paper, ListItem, ListItemText, List } from '@mui/material';
import { usePlanningContext } from '../../../context/PlanningContext';
import { ClientSummaryTableRow } from '../types';
import dayjs from 'dayjs';
import { MutationState, useMutationState, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import QAWTask from '../../../../types';

export function CapacityPanel({
  client,
  selectedTaskWeek,
  clientTasks,
}: {
  client: ClientSummaryTableRow;
  selectedTaskWeek: dayjs.Dayjs | null;
  clientTasks: QAWTask[];
}) {
  const { remainingCapacity, selectedWeek, selectedQaTeamIds } = usePlanningContext();
  const queryClient = useQueryClient();

  // Get task due date mutations
  const singleTaskDueDateMutations: MutationState<unknown, unknown, { task: QAWTask; id: string; dueDate: string }, unknown>[] = useMutationState({
    filters: {
      exact: true,
      mutationKey: ['updateTask'],
      status: 'success',
      predicate: ({ state }) =>
        state.variables && // mutation has variables
        state.variables.dueDate && // mutation has dueDate
        state.variables.task && // mutation has task
        ['testCreation', 'testMaintenance'].includes(state.variables.task.type) && // task type is testCreation or testMaintenance
        state.variables.task.status !== 'blocked', // task status is not blocked
    },
  });
  const multipleTaskDueDateMutations: MutationState<unknown, unknown, { dueDate: string; tasks: QAWTask[] }, unknown>[] = useMutationState({
    filters: {
      exact: true,
      mutationKey: ['bulkUpdateTasks'],
      status: 'success',
      predicate: ({ state }) => {
        return (
          state.variables &&
          state.variables?.dueDate &&
          state.variables?.tasks &&
          state.variables?.tasks.some((task: QAWTask) => task.status !== 'blocked' && ['testCreation', 'testMaintenance'].includes(`${task.type}`))
        );
      },
    },
  });

  useEffect(() => {
    if (singleTaskDueDateMutations.length > 0) {
      // Get the last mutation return early if it doesn't have variables
      const lastMutation = singleTaskDueDateMutations.at(-1);
      if (!lastMutation?.variables) return;

      const { task, dueDate } = lastMutation.variables;

      // Update the client summaries scheduled test count if the due date has changed to a different week
      if (!dayjs(dueDate).isSame(dayjs(task.dueAt), 'week')) {
        // Determine if the number should increase or decrease
        const isNewDateInSelectedWeek = dayjs(dueDate).isSame(dayjs(selectedWeek), 'week');
        const isOldDateInSelectedWeek = dayjs(task.dueAt).isSame(dayjs(selectedWeek), 'week');
        const scheduledCountChange =
          isNewDateInSelectedWeek && !isOldDateInSelectedWeek ? 1 : isOldDateInSelectedWeek && !isNewDateInSelectedWeek ? -1 : 0;

        // Update the client summaries scheduled test count
        queryClient.setQueryData(
          ['clientSummaries', selectedWeek.format('YYYY-MM-DD'), selectedQaTeamIds],
          (oldData: ClientSummaryTableRow[] | undefined) => {
            if (!oldData) return oldData;
            return oldData.map((clientSummary) => {
              if (clientSummary.id === client.id) {
                return {
                  ...clientSummary,
                  creation:
                    task.type === 'testCreation'
                      ? { ...clientSummary.creation, scheduledTestCount: clientSummary.creation.scheduledTestCount + scheduledCountChange }
                      : clientSummary.creation,
                  maintenance:
                    task.type === 'testMaintenance'
                      ? { ...clientSummary.maintenance, scheduledTestCount: clientSummary.maintenance.scheduledTestCount + scheduledCountChange }
                      : clientSummary.maintenance,
                };
              }
              return clientSummary;
            });
          },
        );
      }
    }
  }, [singleTaskDueDateMutations]);

  useEffect(() => {
    if (multipleTaskDueDateMutations.length > 0) {
      // Get the last mutation return early if it doesn't have variables
      const lastMutation = multipleTaskDueDateMutations.at(-1);
      if (!lastMutation?.variables) return;
      console.log('lastBulkUpdateMutation', lastMutation);

      const { dueDate, tasks } = lastMutation.variables;
      if (!dueDate || !tasks?.length) return;

      // Determine how much the scheduled test counts should change
      const isNewDateInSelectedWeek = dayjs(dueDate).isSame(dayjs(selectedWeek), 'week');

      const unblockedTasks = tasks.filter(
        (task: QAWTask) => task.status !== 'blocked' && ['testCreation', 'testMaintenance'].includes(`${task.type}`),
      );
      const maintTasksThatChangedWeeks = unblockedTasks.filter(
        (task: QAWTask) => task.type === 'testMaintenance' && !dayjs(task.dueAt).isSame(dayjs(dueDate), 'week'),
      );
      const creationTasksThatChangedWeeks = unblockedTasks.filter(
        (task: QAWTask) => task.type === 'testCreation' && !dayjs(task.dueAt).isSame(dayjs(dueDate), 'week'),
      );

      const maintScheduledTestCountChange = maintTasksThatChangedWeeks.length;
      const creationScheduledTestCountChange = creationTasksThatChangedWeeks.length;
      const multiplier = isNewDateInSelectedWeek ? 1 : -1;

      // // Update the client summaries scheduled test count
      queryClient.setQueryData(
        ['clientSummaries', selectedWeek.format('YYYY-MM-DD'), selectedQaTeamIds],
        (oldData: ClientSummaryTableRow[] | undefined) => {
          if (!oldData) return oldData;
          return oldData.map((clientSummary) => {
            if (clientSummary.id === client.id) {
              return {
                ...clientSummary,
                creation: {
                  ...clientSummary.creation,
                  scheduledTestCount: clientSummary.creation.scheduledTestCount + creationScheduledTestCountChange * multiplier,
                },
                maintenance: {
                  ...clientSummary.maintenance,
                  scheduledTestCount: clientSummary.maintenance.scheduledTestCount + maintScheduledTestCountChange * multiplier,
                },
              };
            }
            return clientSummary;
          });
        },
      );
    }
  }, [multipleTaskDueDateMutations]);

  const scheduledCreationTasks = clientTasks
    .flatMap((x) => x.childTasks || [])
    .filter((task: QAWTask) => {
      const isInSelectedWeek = !selectedTaskWeek || (task.dueAt && dayjs(task.dueAt).isSame(dayjs(selectedTaskWeek), 'week'));
      return task.type === 'testCreation' && task.status !== 'blocked' && task.status !== 'bugged' && isInSelectedWeek;
    });
  const scheduledCreationTestCount = scheduledCreationTasks.reduce((acc, task) => {
    const testCount = Number(task.workflow?.stepCount) || 0;
    return acc + testCount;
  }, 0);
  const scheduledMaintenanceTasks = clientTasks
    .flatMap((x) => x.childTasks || [])
    .filter((task: QAWTask) => {
      const isInSelectedWeek = !selectedTaskWeek || (task.dueAt && dayjs(task.dueAt).isSame(dayjs(selectedTaskWeek), 'week'));
      return task.type === 'testMaintenance' && task.status !== 'blocked' && task.status !== 'bugged' && isInSelectedWeek;
    });
  const scheduledMaintenanceTestCount = scheduledMaintenanceTasks.reduce((acc, task) => {
    const testCount = Number(task.workflow?.stepCount) || 0;
    return acc + testCount;
  }, 0);

  return (
    <Paper
      sx={{
        mt: 2,
        borderRadius: 2,
        boxShadow: (theme) => theme.shadows[2],
      }}
    >
      <List
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '& .MuiListItem-root': {
            width: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          },
          '& .MuiListItemText-primary': {
            fontSize: '0.875rem',
            color: 'text.secondary',
            mb: 1,
          },
          '& .MuiListItemText-secondary': {
            fontSize: '1.2rem',
            fontWeight: 'medium',
            color: 'text.primary',
          },
        }}
      >
        <ListItem>
          <ListItemText primary="Scheduled Creation" secondary={scheduledCreationTestCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Scheduled Maintenance" secondary={scheduledMaintenanceTestCount} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Remaining QA Team Capacity" secondary={remainingCapacity} />
        </ListItem>
      </List>
    </Paper>
  );
}

import { Button } from '@mui/material';
import CustomSnackbar from '../../shared/CustomSnackbar';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest, sendPostRequest } from '../../../utils/tanstackNetwork';
import { useMutation } from '@tanstack/react-query';
import { StoredUser } from '../../HowlUtilization/types';
import { usePlanningContext } from '../../context/PlanningContext';

export default function ProjectPlanButton({ teamId }: { teamId: string }) {
  const user: StoredUser = JSON.parse(localStorage.getItem('user') || '{}');
  const { clientSummaries } = usePlanningContext();

  const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['clientSummary', teamId],
    queryFn: () => sendGetRequest(`/client-summaries/${teamId}`),
  });

  const taskMutation = useMutation({
    mutationKey: ['taskMutation'],
    mutationFn: ({ endpoint, task }: { endpoint: string; task: { id?: string } }) => {
      return sendPostRequest(endpoint, { task, taskId: task?.id });
    },
    onSuccess: () => {
      refetch();
    },
  });

  const approveProjectPlan = () => {
    taskMutation.mutate({ endpoint: '/done', task: data.projectPlanTask });
  };

  // Show load spinner on initial load
  if (isError) return <Button disabled={true}>{error.message}</Button>;

  const projectPlanTask = clientSummaries.data?.find((cs) => cs.id === teamId)?.incompleteProjectPlanTask;
  const canApprove = projectPlanTask && user.qawId === projectPlanTask.assignedTo?.id;

  return (
    <>
      {canApprove && (
        <Button
          size="medium"
          variant="contained"
          sx={{ minWidth: 'max-content' }}
          onClick={approveProjectPlan}
          disabled={taskMutation.isPending || taskMutation.isSuccess || isFetching || isLoading}
      >
          {isFetching || isLoading ? 'Loading...' : !taskMutation.isPending ? 'Approve Project Plan' : taskMutation.isSuccess ? 'Project Plan Approved' : 'Pending...'}
        </Button>
      )}
      <CustomSnackbar open={taskMutation.isSuccess} message="Project Plan Approved!" />
      <CustomSnackbar open={taskMutation.isError} message="Error Approving Project Plan - Please reach out in the #dragons" />
    </>
  );
}

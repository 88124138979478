import { ConversationList } from './ConversationList';
import { useCommunications } from '../../context/CommunicationsContext';
import { ConversationDetails } from './ConversationDetails';
import Toast from '../../Layout/Toast';

function MessageHQMainPage() {
  const { selectedCustomerId, selectedConversationId, setSelectedConversationId, toast, setToast } = useCommunications();

  const handleSelectConversation = (conversationId: number, state: 'inProgress' | 'completed') => {
    setSelectedConversationId(conversationId);
    if (selectedCustomerId) {
      const savedViewState = localStorage.getItem('savedViewState') ? JSON.parse(localStorage.getItem('savedViewState') as string) : {};

      localStorage.setItem(
        'savedViewState',
        JSON.stringify({
          ...savedViewState,
          activeTab: state, // Save the current tab state
          conversationByCustomer: {
            ...savedViewState?.conversationByCustomer,
            [`${selectedCustomerId}`]: {
              ...savedViewState?.conversationByCustomer?.[selectedCustomerId],
              [`${state}`]: { conversationId },
            },
          },
        }),
      );
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Toast
        title={toast?.title ?? ''}
        message={toast?.message ?? ''}
        show={toast !== null}
        onDone={() => setToast(null)}
        isSuccess={toast?.isSuccess}
        content={<></>}
      />
      <ConversationList
        customerId={selectedCustomerId}
        onSelectConversation={handleSelectConversation}
        selectedConversationId={selectedConversationId}
      />
      {selectedConversationId && <ConversationDetails conversationId={selectedConversationId} />}
    </div>
  );
}

export function MessageHQMain() {
  return <MessageHQMainPage />;
}

import { Button, Tab, Tabs, IconButton } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/material';
import { NavigateNextRounded, CloseRounded } from '@mui/icons-material';
import { Inner, Header, Content, StyledDrawer, Title } from '../ClientMilestones/subComponents';
import { ClientSummaryTableRow } from '../types';
import { useQuery } from '@tanstack/react-query';
import { getAllTasks } from '../../../Tasks/api';
import QAWTask from '../../../../types';
import dayjs from 'dayjs';
import { defaultTableState, unblockedStatuses, usePlanningContext } from '../../../context/PlanningContext';
import { DatePicker } from '@mui/x-date-pickers';
import { PlanningTasksTable } from './PlanningTasksTable';
import { CapacityPanel } from './CapacityPanel';
import ProjectPlanButton from '../ProjectPlanButton';
import { CustomTableState } from '../../../shared/ColumnComponents/types';

const typeFilterMap = {
  grouped: {
    testCoverageRequest: { id: 'type', value: ['testCoverageRequest'] },
    testMaintenance: { id: 'type', value: ['testMaintenance'] },
    blockers: { id: 'type', value: ['testCoverageRequest', 'testMaintenance'] },
  },
  unGrouped: {
    testCoverageRequest: { id: 'type', value: ['testCreation'] },
    testMaintenance: { id: 'type', value: ['testMaintenance'] },
    blockers: { id: 'type', value: ['testCreation', 'testMaintenance'] },
  },
};

const statusFilterMap = {
  testCoverageRequest: { id: 'status', value: unblockedStatuses },
  testMaintenance: { id: 'status', value: unblockedStatuses },
  blockers: { id: 'status', value: ['blocked'] },
};

export default function PlanningDrawer({ client, buttonColor }: { client: ClientSummaryTableRow; buttonColor: 'warning' | 'success' }) {
  const [isHovering, setIsHovering] = useState(false);
  const [selectedTaskWeek, setSelectedTaskWeek] = useState<dayjs.Dayjs | null>(null);
  const { selectedWeek, openPlanningDrawerId, setOpenPlanningDrawerId, selectedView, setSelectedView, setTableState } = usePlanningContext();
  const isOpen = openPlanningDrawerId === client.id;

  // Get tasks for specific client
  const queryKey = ['allTasks', client.id];
  const {
    data: clientTasks,
    isPlaceholderData,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey,
    queryFn: ({ queryKey }) => getAllTasks(queryKey[1]), // queryKey[1] is the teamId var
    placeholderData: [],
    enabled: isHovering || isOpen,
  });

  // Reset state when the drawer is closed
  const resetState = () => {
    // Build initial state
    const initialTableState = {  
      ...defaultTableState,
      columnFilters: [
        typeFilterMap.grouped.testCoverageRequest,
        statusFilterMap.testCoverageRequest,
        { id: 'dueDate', value: [selectedWeek, selectedWeek?.endOf('week') || null] },
      ],
    } as CustomTableState<QAWTask>;
    
    setTableState(initialTableState);
    setSelectedTaskWeek(selectedWeek);
    setSelectedView('testCoverageRequest');
  };

  // Set open planning drawer id and reset table filters, selected task week, and selected
  const openSelf = () => {
    resetState();
    setOpenPlanningDrawerId(client.id);
  };

  const closeSelf = () => {
    resetState();
    setOpenPlanningDrawerId(null);
  };

  const handleViewChange = (view: 'testCoverageRequest' | 'testMaintenance' | 'blockers') => {
    setSelectedView(view);
    setTableState((prev) => {
      // Get the type filters for the selected view
      const typeFilter = typeFilterMap[prev.groupCRandMRs ? 'grouped' : 'unGrouped'][view];
      const statusFilter = statusFilterMap[view];

      // Persist the other filters between views if applicable
      const prevViewFilters = prev.columnFilters?.filter((filter) => !['type', 'status'].includes(filter.id)) || [];

      return {
        ...prev,
        columnFilters: [...prevViewFilters, typeFilter, statusFilter],
      };
    });
  };

  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <Button
        onClick={openSelf}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        variant="contained"
        endIcon={<NavigateNextRounded />}
        color={buttonColor}
        sx={{ width: '10rem' }}
        disableRipple
      >
        {buttonColor === 'success' ? 'Done' : 'In Progress'}
      </Button>
      <StyledDrawer anchor="right" open={isOpen} onClose={closeSelf} variant="temporary" sx={{ width: '60%' }}>
        <Inner>
          <Header>
            <IconButton onClick={closeSelf} disableRipple>
              <CloseRounded />
            </IconButton>
            <Title text={`${client.name}`} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
              <ProjectPlanButton teamId={client.id} />
              <Button
                variant="contained"
                onClick={() => setSelectedTaskWeek(null)}
                disabled={!selectedTaskWeek}
                sx={{ mr: 1, height: '2.25rem', minWidth: 'max-content' }}
                size="small"
              >
                Clear Week Filter
              </Button>
              <DatePicker
                value={selectedTaskWeek}
                onChange={(e) => setSelectedTaskWeek(e?.startOf('week') ?? dayjs())}
                label="Select Week"
                format="MMM D - MMM D"
                showDaysOutsideCurrentMonth
                slotProps={{
                  actionBar: {
                    actions: ['today'],
                  },
                  textField: {
                    size: 'small',
                    InputProps: {
                      placeholder: 'Select Week',
                      value: selectedTaskWeek ? `${selectedTaskWeek.format('MMM D')} - ${selectedTaskWeek.endOf('week').format('MMM D')}` : '',
                      sx: { height: '2.25rem', width: 'max-content' },
                    },
                  },
                }}
              />
            </Box>
          </Header>
          <Content>
            <CapacityPanel client={client} selectedTaskWeek={selectedTaskWeek} clientTasks={clientTasks as QAWTask[]} />
            <Tabs value={selectedView} onChange={(e, newVal) => handleViewChange(newVal)} variant="fullWidth">
              <Tab label="Creation" value="testCoverageRequest" disableRipple />
              <Tab label="Maintenance" value="testMaintenance" disableRipple />
              <Tab label="Blockers" value="blockers" disableRipple />
            </Tabs>
            <PlanningTasksTable
              clientTasks={clientTasks as QAWTask[]}
              isPlaceholderData={isPlaceholderData}
              isFetching={isFetching}
              isError={isError}
              error={error}
              selectedView={selectedView}
              selectedTaskWeek={selectedTaskWeek}
              queryKey={queryKey}
            />
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}

import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { CustomTooltip } from '../HiringUtils/helperFunctions';
import dayjs from 'dayjs';
import { statusMap } from '../HiringUtils/StatusConstants';

export default function StatusBarChart({ data, filters }) {
    // Define valid statuses
    let validStatuses = statusMap.map((status) => status.value);

    // Initialize an empty object to group counts by level and source
    const groupedData = {};

    // Initialize array to count the total num of interviews
    let total = [];

    // Process data to calculate counts per level and source
    data.candidates.forEach((submission) => {
        if (
            dayjs(submission.dateSubmitted).isValid() &&
            dayjs(filters.gte).isValid() &&
            dayjs(filters.lte).isValid() &&
            dayjs(submission.dateSubmitted).isBetween(dayjs(filters.gte), dayjs(filters.lte), "day", "[]") &&
            validStatuses.includes(submission.status)
        ) {
            // Keep track of the submission
            total.push(submission);

            // Ensure the level exists in the groupedData
            if (!groupedData[submission.status]) {
                groupedData[submission.status] = { status: submission.status };
            }

            // Increment the count for the source
            groupedData[submission.status][submission.jobSourceName] =
                (groupedData[submission.status][submission.jobSourceName] || 0) + 1;
        }
    });

    // Convert the grouped data object into an array
    const formattedData = Object.values(groupedData);

    // Get all unique sources to ensure all levels have consistent keys
    const allSources = [
        ...new Set(data.candidates.map((submission) => submission.jobSourceName)),
    ];

    // Ensure every level has a count for each source, even if it's 0
    formattedData.forEach((entry) => {
        allSources.forEach((source) => {
            if (entry[source] === undefined) {
                entry[source] = 0;
            }
        });
    });


    // Add missing statuses to formattedData with zero counts for all sources
    validStatuses.forEach((status) => {
        if (!formattedData.some((entry) => entry.status === status)) {
            const newEntry = { status };
            allSources.forEach((source) => {
                newEntry[source] = 0; // Set count to 0 for all sources
            });
            formattedData.push(newEntry);
        }
    });

    return (
        <div>
            <div style={{ width: '100%', height: '300px', margin: '20px 0px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={1000}
                        height={300}
                        data={formattedData}
                        margin={{
                            right: 10,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis
                            dataKey="status"
                            tick={{
                                fontSize: 12,
                                width: 20,
                            }}
                            tickFormatter={(tick) => statusMap.find((status) => status.value === tick)?.label || tick}
                            interval={0} // Show all labels
                        />
                        <YAxis domain={[0, 'dataMax']} label={{ value: 'Total Count', angle: -90, position: 'insideLeft' }} interval={0}/>
                        <Tooltip
                            content={<CustomTooltip totalCount={total.length} filters={filters} />}
                        />
                        {(filters.source === "All" ? allSources : [filters.source]).map((source) => (
                            <Bar
                                key={source}
                                dataKey={source}
                                stackId="submissions"
                                fill={hslStringToRgbString(stringToColor(source))}
                                opacity={0.65}
                            />
                        ))}
                        <Legend
                            payload={[
                                { value: `Totals Based on Submission Statuses`, color: hslStringToRgbString(stringToColor(filters.source)) },
                            ]}
                            verticalAlign="top"
                            height={32}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div >
    );
}

import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { Combobox } from "@headlessui/react";
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { DateFilters } from "@/components/UtilityComponents";

import dayjs from "dayjs";
import StatusBarChart from "./StatusBarChart";
import SourcesPieChart from "./SourcesPieChart";
import SourcesLineChart from "./SourcesLineChart";
import JobSourceView from "./JobSourceView";
import FinalRoundLevelBarChart from "./FinalRoundLevelBarChart";

export default function MetricsView({ data }) {
    // Set the default filters 
    const [filters, setFilters] = useState({
        source: "All", // first job source
        gte: dayjs().subtract(1, 'w').format('YYYY-MM-DD'), // a week ago
        lte: dayjs().format('YYYY-MM-DD'), // today
    });

    // Get current search param strings
    const currentSearchStr = window.location.search;

    // Create a new URLSearchParams instance, using current if available, otherwise stored
    const searchParamsInstance = new URLSearchParams(currentSearchStr);

    const [searchParams, setSearchParams] = useSearchParams(searchParamsInstance);

    const handleJobSourceChange = (source) => {
        setFilters((prevState) => {
            return {
                ...prevState,
                source,
            };
        });
    };

    // Update search params in url
    useEffect(() => {
        // Set job source
        searchParams.set('source', filters.source);

        // Set date ranges
        searchParams.set('gte', filters.gte);
        searchParams.set('lte', filters.lte);

        setSearchParams(searchParams);
    }, [searchParams, filters]);

    return (
        <div className="p-6">
            <div className="flex justify-between">
                <div>
                    <Combobox
                        as="div"
                        onChange={handleJobSourceChange}
                    >
                        <label className="block text-sm font-medium leading-6 text-gray-400">
                            Select Job Source
                        </label>
                        <div className="relative mt-2">
                            <Combobox.Input
                                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder={filters.source ? filters.source : ""}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                            {data.jobSources && (
                                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                                    <Combobox.Option value="All" className='m-4 hover:cursor-pointer'>
                                        All
                                    </Combobox.Option>
                                    {data.jobSources.map((source, idx) => (
                                        <Combobox.Option
                                            key={idx}
                                            value={source}
                                            className='m-4 hover:cursor-pointer'
                                        >
                                            {source}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            )}
                        </div>
                    </Combobox>
                </div>
                <DateFilters
                    values={{ gte: filters.gte, lte: filters.lte }}
                    updateFn={(dateParam, newDate) => {
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            [dateParam]: dayjs(newDate).format('YYYY-MM-DD'),
                        }));
                    }}
                />
            </div>
            {filters.source === "All" && (
                <div>
                    <SourcesPieChart data={data} filters={filters} />
                    <StatusBarChart data={data} filters={filters} />
                    <FinalRoundLevelBarChart data={data} filters={filters} />
                    <SourcesLineChart data={data} filters={filters} />
                </div>
            )}
            {filters.source !== "All" && (
                <JobSourceView data={data} filters={filters} />
            )}
        </div>
    );
}

import { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function Toast({ title, message, show, isSuccess = true, onDone, content }) {
  const [isVisible, setIsVisible] = useState(show);

  let icon = <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
  if (!isSuccess) {
    icon = <ExclamationTriangleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
  }

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <>
      <div aria-live="assertive" className="pointer-events-none fixed inset-y-10 -inset-x-1 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={isVisible}
            as={Fragment}
            enter="duration-500 transform ease-out transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-1 opacity-100 sm:translate-x-0"
            leave="duration-1000 transition ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => onDone()}
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{icon}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{message}</p>
                    {content && content}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsVisible(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

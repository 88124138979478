import { Message } from '../data/types';

export function generateSlackUrl(message: Message) {
  if (message.platform === 'slack') {
    return `https://qawolfhq.slack.com/archives/${message.platformChannelId}/p${message.timestamp.replace('.', '')}?thread_ts=${message.thread}&cid=${
      message.platformChannelId
    }`;
  } else if (message.platform === 'teams') {
    return `https://teams.microsoft.com/l/message/${message.platformChannelId}/${message.msTeamsId}?context=%7B%22contextType%22%3A%22chat%22%7D`;
  }
}

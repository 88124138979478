import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent } from 'react';

export default function DaySelector({
  days,
  selectedDay,
  setSelectedDay,
  startDay = 0,
}: {
  days: number[];
  selectedDay: number;
  setSelectedDay: (day: number) => void;
  startDay?: number;
}) {
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Full Week'];

  // Reorder days array to start from startDay, excluding Full Week (7)
  const reorderedDays = days
    .slice(0, 7) // Remove Full Week
    .sort((a, b) => {
      const adjustedA = (7 + a - startDay) % 7;
      const adjustedB = (7 + b - startDay) % 7;
      return adjustedA - adjustedB;
    });

  // Add Full Week back at the end
  const orderedDays = [...reorderedDays, 7];

  const handleChange = (_event: MouseEvent<HTMLElement>, newDay: number) => {
    if (newDay !== null) {
      setSelectedDay(newDay);
    }
  };

  return (
    <ToggleButtonGroup value={selectedDay} exclusive onChange={handleChange} aria-label="day selector" fullWidth sx={{ height: 40 }}>
      {orderedDays.map((day) => (
        <ToggleButton key={day} value={day} disableRipple>
          {dayNames[day]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

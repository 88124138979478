import { FormControl, InputLabel, Select, MenuItem, ListItemText, Checkbox, ListItemAvatar, Avatar, SelectChangeEvent } from '@mui/material';
import { usePlanningContext } from '../../context/PlanningContext';

import { plural } from '../../InvestigationBoard/helpers';
import { ChangeEvent } from 'react';

export function QaTeamSelector() {
  const { selectedQaTeamIds, setSelectedQaTeamIds, allTeamsSelected, setAllTeamsSelected, qaTeams } = usePlanningContext();

  const getSelectLabel = () => {
    if (selectedQaTeamIds.length === 0) return '';
    if (selectedQaTeamIds.length === 1) {
      return qaTeams.find((team) => team.id === selectedQaTeamIds[0])?.teamName;
    }
    const firstTeam = qaTeams.find((team) => team.id === selectedQaTeamIds[0])?.teamName;
    return `${firstTeam} + ${selectedQaTeamIds.length - 1} other${plural(selectedQaTeamIds.length - 1)}`;
  };

  const handleQaTeamChange = (e: SelectChangeEvent<number[]>) => {
    const newValue = typeof e.target.value === 'string' ? JSON.parse(e.target.value) : e.target.value;
    if (newValue.length > 0) {
      setSelectedQaTeamIds(newValue);
    }
    setAllTeamsSelected(qaTeams.length === newValue.length);

    // Store the selected QA team IDs in local storage
    localStorage.setItem('selectedQaTeamIds', JSON.stringify(newValue));
  };

  const handleAllTeamsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAllTeamsSelected(e.target.checked);

    // If all teams are selected, set the selected QA team IDs to all QA team IDs, otherwise set the selected QA team IDs to the first QA team ID
    if (e.target.checked) {
      setSelectedQaTeamIds(qaTeams.map((team) => team.id));
    } else {
      setSelectedQaTeamIds([qaTeams[0].id]);
    }

    // Store the selected QA team IDs in local storage
    localStorage.setItem('selectedQaTeamIds', JSON.stringify(qaTeams.map((team) => team.id)));
  };

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel>QA Team</InputLabel>
      <Select multiple value={selectedQaTeamIds} onChange={handleQaTeamChange} label="QA Team" renderValue={getSelectLabel}>
        <MenuItem>
          <ListItemText primary="All QA Teams" />
          <Checkbox checked={allTeamsSelected} onChange={handleAllTeamsChange} />
        </MenuItem>
        {qaTeams.map((team) => (
          <MenuItem key={team.id} value={team.id}>
            <ListItemAvatar>
              <Avatar src={team.imageUrl} />
            </ListItemAvatar>
            <ListItemText primary={team.teamName} secondary={team.name} />
            <Checkbox
              checked={selectedQaTeamIds.includes(team.id)}
              disabled={selectedQaTeamIds.length === 1 && selectedQaTeamIds.includes(team.id)}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

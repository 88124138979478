import { classNames } from '../../InvestigationBoard/helpers';
import {
  computeComparisonInsightsForMaintenanceCompTable,
  computeQAEInsightsForMaintenanceCompTable,
  computeTeamInsightsForMaintenanceCompTable,
  determineColorForMaintenanceCompTable,
  MaintenanceComparisonInsights,
} from './tableHelpers';
import { sumAllCreationOutliningMaintenanceTasks } from '../helpers';
import { TeamCreationOutlineMaintenanceTasksInsights, SumObject } from '../types';

type WeeklyTeamMaintenanceTableProps = {
  maintenanceInsights: TeamCreationOutlineMaintenanceTasksInsights;
  teamSums: SumObject;
};

export default function WeeklyTeamMaintenanceTable({ maintenanceInsights, teamSums }: WeeklyTeamMaintenanceTableProps) {
  const CATEGORIES: Array<keyof MaintenanceComparisonInsights> = [
    'Tests Maintained',
    'Workflows Maintained',
    'Average Tests Maintained Per Week',
    'Median Tests Maintained Per Week',
    'Max Tests Maintained Per Week',
    'Min Tests Maintained Per Week',
  ];

  const computedTeamInsights = computeTeamInsightsForMaintenanceCompTable(maintenanceInsights, teamSums);

  const qaeData = (maintenanceInsights.team.members || [])
    .filter((member) => !member.isManager)
    .sort((a, b) => {
      if (a.isLead && !b.isLead) return 1;
      if (!a.isLead && b.isLead) return -1;
      return a.name.localeCompare(b.name);
    })
    .map((qae) => {
      const qaeTasksByWeekAndType = maintenanceInsights.teamTasksByWeekAndType.filter((task) => task.completed_by_qaw_id === qae.qawId);
      const qaeSums = sumAllCreationOutliningMaintenanceTasks(qaeTasksByWeekAndType);
      const qaeInsights = computeQAEInsightsForMaintenanceCompTable({ qae, qaeTasksByWeekAndType }, qaeSums);
      const comparisonInsights = computeComparisonInsightsForMaintenanceCompTable(qaeInsights, computedTeamInsights);

      return {
        qae,
        qaeInsights,
        comparisonInsights,
      };
    });

  return (
    <div className="overflow-x-auto border border-gray-600 shadow-sm">
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            <th className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-100 min-w-[200px] border-b border-gray-400"></th>
            <th className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-900 bg-gray-100 min-w-[110px] border-b border-gray-400"></th>
            {qaeData.map((data, index) => (
              <th
                key={`qae-header-${index}`}
                colSpan={2}
                className="px-4 py-2 text-center text-sm font-medium text-gray-900 bg-gray-100 border-b border-l border-gray-400"
              >
                <div className="flex items-center justify-center space-x-2">
                  <img src={data.qae.avatar48} alt={`${data.qae.name}'s avatar`} className="h-6 w-6 rounded-full" />
                  <span>{data.qae.name.split(' ')[0]}</span>
                </div>
              </th>
            ))}
          </tr>
          <tr>
            <th className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-200 min-w-[200px] border-b border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
              Metric
            </th>
            <th className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-l border-b border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
              Team Total
            </th>
            {qaeData.map((_, index) => (
              <>
                <th
                  key={`metric-header-${index}`}
                  className="px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]"
                >
                  Metric
                </th>
                <th
                  key={`comparison-header-${index}`}
                  className="px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]"
                >
                  Comparison
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {CATEGORIES.map((category, categoryIndex) => (
            <tr
              key={categoryIndex}
              className={classNames(
                'divide-x divide-x-gray-200 border-b border-b-gray-200 hover:border-t-2 hover:border-b-2 hover:border-gray-400 hover:shadow-lg',
                category === 'Median Tests Maintained Per Week' && 'border-b-2 border-b-gray-400',
              )}
            >
              <td className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-800 min-w-[240px] bg-white outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                {category}
              </td>
              <td className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-900 border-l border-gray-400 bg-white outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                {computedTeamInsights[category] || 0}
              </td>
              {qaeData.map((data, colIndex) => (
                <>
                  <td
                    key={`metric-${categoryIndex}-${colIndex}`}
                    className={classNames(
                      'px-4 py-2 text-center text-sm border-l border-gray-400',
                      [4, 5, 6].includes(categoryIndex)
                        ? ''
                        : determineColorForMaintenanceCompTable(
                            category,
                            data.comparisonInsights[category],
                            data.qae,
                            maintenanceInsights.team,
                            teamSums,
                          ),
                    )}
                  >
                    {data.qaeInsights[category] || 0}
                  </td>
                  <td
                    key={`comparison-${categoryIndex}-${colIndex}`}
                    className={classNames(
                      'px-4 py-2 text-center text-sm',
                      [4, 5, 6].includes(categoryIndex)
                        ? ''
                        : determineColorForMaintenanceCompTable(
                            category,
                            data.comparisonInsights[category],
                            data.qae,
                            maintenanceInsights.team,
                            teamSums,
                          ),
                    )}
                  >
                    {data.comparisonInsights[category] || 0}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// React imports
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// Material UI imports
import { Box, CardMedia, Tabs, Tab, Typography } from '@mui/material';

// File imports
import MonacoEditor from './MonacoEditor/MonacoEditor';
import EmailEditor from './EmailSection/EmailEditor';
import { getCandidateEmails } from '../HiringUtils/api';

// Date imports
import * as dateFns from 'date-fns';
import ChatGPTDecision from './NotesSection/ChatGPTDecision';
import MarkdownPreviewer from './NotesSection/MarkdownPreviewer';


const CandidateDetailPanel = ({ row, data, idx, showTab, height }) => {
  const [tabValue, setTabValue] = useState(idx ? idx : 0);
  const { id, candidate: { email } } = row.original; // Adjust based on your data structure

  // Fetch additional data for the row
  const { data: emailData, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['rowDetails', id],
    queryFn: () => getCandidateEmails(email),
    enabled: !!id, // Only run the query if id is available
    staleTime: 5 * 60 * 1000,
  });

  const handleTabChange = (event, selectedTab) => {
    setTabValue(idx ? idx : selectedTab);
  };

  return (
    <Box
      className="panelContainer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 6,
        margin: 0,
        borderRadius: 1,
        height: '70vh',
        width: '100%',
        boxSizing: 'borderBox',
        position: 'relative',
        '@media (max-width: 1320px) or (max-height: 870px)': {
          width: '90%', // Set a smaller width for screens less than 1320px
          height: '60vh',
        },
      }}
    >
      {showTab ?
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="detail-panel-tabs"
          variant="fullWidth"
        >
          <Tab label="Editor" />
          <Tab label="Extra Info" />
          <Tab label="Notes" />
        </Tabs>
        : ""
      }
      {tabValue === 0 && (
        <Box
          className="editorContainer"
          value={tabValue}
          index={0}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: height ? height : 'calc(100% - 48px)',  // 48px accounts for the tab bar
            paddingBottom: '2px',
          }}
        >
          {/* =============================== MONACO EDITOR SECTION =============================== */}
          <Box
            className="monacoContainer"
            sx={{
              boxShadow: 4,
              zIndex: 10,
              marginLeft: '2px',
              width: '100%',
              overflow: 'clip',
              position: 'relative',
            }}
          >
            <MonacoEditor
              fileUrl={
                data.filter((candidateName) => `${candidateName.candidate.firstName} ${candidateName.candidate.lastName}` === row.getValue('name'))[0]
                  .submissionFilePath
              }
              candidateName={{ firstName: row.original.candidate.firstName, lastName: row.original.candidate.lastName }}
            />
          </Box>
          {/* ==================================== EMAIL SECTION ==================================== */}
          <Box
            className="emailContainer"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <EmailEditor
              candidateInfo={row.original.candidate}
              emailData={emailData}
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
              error={error}
            />
          </Box>
        </Box>
      )}

      {tabValue === 1 && (
        <Box
          className="extraInfoEmailContainer"
          value={tabValue}
          index={1}
          sx={{
            display: 'flex',
            paddingBottom: '2px',
            height: height ? height : 'calc(100% - 48px)', // 48px accounts for the tab bar
          }}
        >
          {/* =============================== CANDIDATE EXTRA INFO SECTION =============================== */}
          <div
            className='extraInfo flex flex-col p-4 border-r border-gray-700 bg-gray-900 w-[25%] text-white overflow-y-auto'
          >
            <Typography
              variant='h6'
            >
              Candidate Extra Information:
            </Typography>
            <div>
              <div className="p-4 text-gray">
                <b>First applied:</b> {dateFns.format(new Date(row.original.candidate.firstApplied), "MM/dd/yyyy")}
              </div>
              <div className="p-4 text-gray">
                <b>Cooldown dates:</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  <li>
                    <b>Take-home:</b> {row.original.candidate.takeHomeCooldown
                      ? dateFns.format(new Date(row.original.candidate.takeHomeCooldown), "MM/dd/yyyy")
                      : 'N/A'}
                  </li>
                  <li>
                    <b>Final-round:</b> {row.original.candidate.finalRoundCooldown
                      ? dateFns.format(new Date(row.original.candidate.finalRoundCooldown), "MM/dd/yyyy")
                      : 'N/A'}
                  </li>
                </ul>
              </div>
              <div className="p-4 text-gray">
                <b>Referer:</b> {row.original.refereeName ? row.original.refereeName : "N/A"}
              </div>
              <div className="p-4 text-gray">
                <b>Institute:</b> {row.original.instituteName ? row.original.instituteName : "N/A"}
              </div>
              {
                row.original.finalRoundTeam &&
                <div className="p-4 text-gray">
                  <b>Final Round Team:</b>
                  <div>
                    <a
                      href={`https://app.qawolf.com/${row.original.finalRoundTeam}`}
                      target='_blank'
                      rel="noopener noreferrer"
                      style={{
                        color: '#4544E5',
                        textDecoration: 'underline',
                      }}
                    >Candidate Team</a>

                  </div>
                </div>
              }
            </div>
          </div>
          {/* =============================== LOOM SECTION =============================== */}
          <CardMedia
            component="iframe"
            src={data
              .filter((candidateName) => `${candidateName.candidate.firstName} ${candidateName.candidate.lastName}` === row.getValue('name'))[0]
              .submissionVideo.replace('share', 'embed')}
            title="title"
            className={'.MuiCardMedia-media'}
            sx={{
              boxShadow: 4,
              width: '100%',
              display: 'flex',
            }}
          />
        </Box>
      )}

      {tabValue === 2 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: '2px',
            height: '100%', 
            backgroundColor: 'rgb(17, 24, 39)',
          }}
        >
          <Box
            className="panelTopContainer"
            sx={{
              display: 'flex',
              boxShadow: 6,
              margin: 0,
              borderRadius: 1,
              width: '40%',
              height: '90%',
              paddingLeft: '1rem',
              overflowY: 'auto', // Added for scrollability
            }}
          >
            <ChatGPTDecision row={row.original} />
          </Box>
          <Box 
            className=" panelBottomContainer px-4 border-r border-gray-700 bg-gray-900 overflow-auto" 
            sx={{
              width: '60%',
              height: '90%',
            }}
          >
            <div className='h-full'>
              <MarkdownPreviewer 
                notes={row.original.notes ? row.original.notes : '*Candidate does not have any notes yet.*'} 
                row={row.original} 
                style={{ 
                  height: "545px",
                  display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '1rem',
                    fontFamily: 'Arial, sans-serif',
                    width: '100%',
                    color: 'black',
                    '@media (max-width: 1320px) or (max-height: 870px)': {
                        width: '90%', // Set a smaller width for screens less than 1320px
                        maxHeight: '375px',
                    },
                }} 
              />
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CandidateDetailPanel;

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import dayjs from 'dayjs';

export default function SourcesLineChart({ data, filters }) {
    let jobSourceCountsByWk = [];
    let allJobSourceCountsByWk = [];

    // Filter data by job source
    data.candidates.forEach((submission) => {
        // Filters candidates based on job source & dates selected
        if (submission.jobSourceName === filters.source && dayjs(submission.dateSubmitted).isAfter(dayjs(filters.gte)) && dayjs(submission.dateSubmitted).isSameOrBefore(dayjs(filters.lte))) {
            if (jobSourceCountsByWk.some((countObj) => countObj.week === dayjs(submission.dateSubmitted).week())) {
                let sourceObj = jobSourceCountsByWk.find((obj) => obj.week === dayjs(submission.dateSubmitted).week());
                sourceObj.count += 1;
            } else {
                jobSourceCountsByWk.push({
                    week: dayjs(submission.dateSubmitted).week(),
                    year: dayjs(dayjs(submission.dateSubmitted).endOf('week')).year(),
                    count: 1,
                });
            }
        }

        // Filters candidates based on dates selected for "All" job sources
        if (filters.source === "All" && dayjs(submission.dateSubmitted).isAfter(dayjs(filters.gte)) && dayjs(submission.dateSubmitted).isSameOrBefore(dayjs(filters.lte))) {
            if (allJobSourceCountsByWk.some((countObj) => countObj.week === dayjs(submission.dateSubmitted).week())) {
                let sourceObj = allJobSourceCountsByWk.find((obj) => obj.week === dayjs(submission.dateSubmitted).week());
                sourceObj[submission.jobSourceName] += 1;
            } else {
                let newObj = {
                    week: dayjs(submission.dateSubmitted).week(),
                    year: dayjs(dayjs(submission.dateSubmitted).endOf('week')).year(),
                };
                data.jobSources.forEach((source) => {
                    newObj[source] = 0;
                });
                allJobSourceCountsByWk.push(newObj);
            }

        }
    });

    const sortedCountsByWk = jobSourceCountsByWk.sort((a, b) => {
        if (a.year !== b.year) {
            return a.year - b.year;
        } else {
            return a.week - b.week;
        }
    });
    const allSortedCountsByWk = allJobSourceCountsByWk.sort((a, b) => {
        if (a.year !== b.year) {
            return a.year - b.year;
        } else {
            return a.week - b.week;
        }
    });

    return (
        <div style={{ width: '100%', height: '400px', margin: '20px 0px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={filters.source === "All" ? allSortedCountsByWk : sortedCountsByWk}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="week" label={{ value: 'Week #', position: 'insideBottom', offset: - 5 }} />
                    <YAxis label={{ value: 'Total Count', angle: -90, position: 'insideLeft' }} />
                    <Tooltip 
                        labelFormatter={(label) => {
                            return `Week ${label}`;
                        }} 
                        labelStyle={{ fontWeight: '600', textDecoration: 'underline', fontStyle: 'italic' }}
                    />
                    <Legend 
                        verticalAlign="top"
                        payload={[
                            { value: `Total Submissions by Job Source Over Time`, color: hslStringToRgbString(stringToColor(filters.source)) },
                        ]}
                    />

                    {filters.source === "All" ?
                        data.jobSources.map((source, index) => (
                            <Line type="monotone" name={`${source}`} key={index} dataKey={source} stroke={hslStringToRgbString(stringToColor(source))} activeDot={{ r: 8 }} />
                        ))
                        : <Line type="monotone" name={`Count for ${filters.source}`} dataKey={"count"} stroke={hslStringToRgbString(stringToColor(filters.source))} activeDot={{ r: 8 }} />
                    }
                </LineChart>

            </ResponsiveContainer>
        </div>
    );
}

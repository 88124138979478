import { useState } from "react";
// @ts-ignore
import emailTemplates from './emailTemplate.jsx';
import '../../../../css/hiring.css';
import { sendCandidateEmail } from "../../HiringUtils/api";
import { Editor, EditorState, CompositeDecorator } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import ReactDOMServer from 'react-dom/server';
import Toast from '../../../Layout/Toast';

const MessageInput = ({ candidate, singleMessageHeaders }) => {
  const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
  const [isDisabled, setIsDisabled] = useState(false);
  const [toastData, setToastData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Set the search term
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter through the email templates based on the search term
  const filteredItems = emailTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );


  // Define the Link Decorator
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const Link = (props) => {
    const { contentState, entityKey, children } = props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <a href={url} style={{ color: "teal", textDecoration: "underline" }}>
        {children}
      </a>
    );
  };

  // Create a CompositeDecorator
  const linkDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  // Handle template selection from buttons or dropdown
  const handleTemplateSelection = (templateObj) => {
    const templateContent = templateObj.content(candidate);
    const htmlString = ReactDOMServer.renderToString(templateContent);
    const blocksFromHTML = stateFromHTML(htmlString);

    // Apply the CompositeDecorator to highlight links
    const editorState = EditorState.createWithContent(
      blocksFromHTML,
      linkDecorator,
    );

    setEditorState(editorState);
    setIsOpen(false); // Close the dropdown after selection
    setSearchTerm(""); // Clear the search term after selection
  };

  const handleSendingEmail = async ({ candidate, singleMessageHeaders }) => {
    // Disable the button
    setIsDisabled(true);

    // Clears the input box
    setEditorState(() => EditorState.createEmpty());

    let state = editorState.getCurrentContent();
    let currentEditorState = stateToHTML(state);
    let result = await sendCandidateEmail({ currentEditorState, candidate, singleMessageHeaders });

    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ? `Email sent to candidate.` : 'Something went wrong! Unable to send email.';

    setToastData({
      title: title,
      message: message,
      isSuccess: success,
    });

    // Re-enable the button
    setIsDisabled(false);
  };

  return (
    <div className="template-container flex items-center p-4 border-t border-gray-700 bg-gray-900 h-[25%]">
      <div className="template-buttons flex flex-col items-center justify-center space-y-2.5 w-[33%] h-full">
        {/* ========================= Buttons ========================= */}
        <button
          className="reject-button bg-gray-700 text-white rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-2/3 p-0.75"
          onClick={() => handleTemplateSelection(
            emailTemplates.find((item) => item.name === "Reject Take-home"),
          )}
        >
          Reject Take Home
        </button>
        <button
          className="final-round-button bg-gray-700 text-white rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-2/3 p-0.75"
          onClick={() => handleTemplateSelection(
            emailTemplates.find((item) => item.name === "Schedule Final Round"),
          )}
        >
          Schedule Final Round
        </button>

        {/* ========================== Custom Dropdown ========================== */}
        <div className=" relative inline-block w-2/3 ">
          <button
            onClick={toggleDropdown}
            className="template-dropdown-button bg-white text-gray-700 rounded-md shadow-md hover:bg-gray-200 focus:outline-none w-full p-0.75"
          >
            Choose a template
          </button>

          {isOpen && (
            <div className="template-select absolute bottom-full mb-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 text-gray-700">
              <input
                type="text"
                placeholder="Search templates..."
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  padding: "20px 10px",
                  width: "100%",
                  height: "32px",
                  fontSize: "14px",
                  border: "1px solid #ccc",
                  borderRadius: "4px 4px 0 0",
                }}
              />
              {searchTerm ? (
                <ul className="py-1 max-h-48 overflow-y-auto divide-y divide-solid divide-gray-200">
                  {filteredItems.map((item, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleTemplateSelection(item)}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                      >
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="py-1 max-h-48 overflow-y-auto divide-y divide-solid divide-gray-200">
                  {emailTemplates.map((template, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleTemplateSelection(template)}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                      >
                        {template.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ======================= Email Textarea ======================= */}
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        placeholder={"Type your message here..."}
      />
      <button
        onClick={() => {
          handleSendingEmail({
            candidate, singleMessageHeaders,
          });
        }}
        className="ml-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        style={
          isDisabled
            ? {
                backgroundColor: 'grey',
                cursor: 'not-allowed',
              }
            : null
        }
        disabled={isDisabled}
      >
        Send
      </button>
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </div>
  );
};

export default MessageInput;

import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

// Define the type for the context value
interface SocketContextType {
  socket: Socket | null;
}

// Create context with a default value
const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Define the provider's props
interface SocketProviderProps {
  children: ReactNode;
}

// SocketProvider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    // Initialize the socket instance
    const socketInstance = io(process.env.REACT_APP_URL); // Replace with your server URL
    setSocket(socketInstance);

    // Cleanup function to disconnect the socket on unmount
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

// Custom hook to use the socket instance
export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

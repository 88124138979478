import {
  Box,
  Button,
  Drawer,
  ListItem,
  ListItemText,
  Typography,
  Skeleton,
  SxProps,
  Theme,
  ButtonProps,
  DrawerProps,
  BoxProps,
  TextField,
} from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { UpdatedMilestoneValues } from '../MsDrawer';

export function Inner({ children, sx = {} }: { children: ReactNode; sx?: SxProps<Theme> }) {
  return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', p: '1em 2em', ...sx }}>{children}</Box>;
}

export function Header({ children }: { children: ReactNode }) {
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>{children}</Box>;
}

export function Title({ text }: { text: string }) {
  return (
    <Box sx={{ display: 'flex', paddingLeft: '1em', width: '100%' }}>
      <Typography variant="h5">{text}</Typography>
    </Box>
  );
}

export function Content({ children, sx = {}, ...props }: { children: ReactNode; sx?: SxProps<Theme> } & BoxProps) {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', ...sx }} {...props}>
      {children}
    </Box>
  );
}

export function SubDrawerBtn({ text, sx = {}, ...props }: { text: string; sx?: SxProps<Theme> } & ButtonProps) {
  return (
    <Button
      fullWidth
      sx={{
        justifyContent: 'left',
        pl: '4em',
        color: 'GrayText',
        '&:hover': {
          bgcolor: 'white',
        },
        ...sx,
      }}
      size="large"
      disableRipple
      {...props}
    >
      {text}
    </Button>
  );
}

export function MsBtnSkeleton() {
  return (
    <Skeleton width="100%" sx={{ my: '-1em', py: 0 }}>
      <Button variant="contained" size="large" sx={{ py: '1.5em' }}>
        DUMMY TEXT
      </Button>
    </Skeleton>
  );
}

export function StyledDrawer({ children, sx = {}, ...props }: { children: ReactNode; sx?: SxProps<Theme> } & DrawerProps) {
  return (
    <Drawer PaperProps={{ sx: { backgroundColor: '#f1f1f1', width: '50%', ...sx } }} {...props}>
      {children}
    </Drawer>
  );
}

// Customized DatePicker component that opens when clicking the input field
export function NoInputDatePicker({ slotProps = { textField: { sx: {} } }, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          ...props,
          sx: { input: { cursor: 'pointer' }, ...slotProps.textField.sx },
          InputProps: {
            onClick: () => setOpen(true),
          },
        },
      }}
    />
  );
}

// Customized DatePicker component that opens when clicking the input field
export function CardItemDatePicker({ error, ...props }: DatePickerProps<dayjs.Dayjs, false> & { error?: boolean }) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        textField: {
          sx: { input: { cursor: 'pointer', textAlign: 'start', width: '150px', padding: '0 0 0 10px' } },
          InputProps: {
            onClick: () => setOpen(true),
            required: true,
          },
          error: !!error,
          variant: 'filled',
        },
        openPickerIcon: {
          sx: {
            display: 'none',
          },
        },
        popper: {
          placement: 'left-start',
        },
      }}
    />
  );
}

export function CardItem({
  name,
  value,
  handleChange,
  isUpdating,
  keyName,
  secondarySx = {},
  shouldDisable = false,
  minDate,
  maxDate,
  min,
  max,
  error,
}: {
  name: string;
  value: string | dayjs.Dayjs;
  handleChange?: (key: keyof UpdatedMilestoneValues, value: string | dayjs.Dayjs) => void;
  isUpdating?: boolean;
  keyName?: keyof UpdatedMilestoneValues;
  secondarySx?: SxProps<Theme>;
  shouldDisable?: boolean;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  min?: number;
  max?: number;
  error?: string;
}) {
  const isDate = keyName === 'startDate' || keyName === 'externalDueDate';
  const fieldType = keyName === 'testVelocity' || keyName === 'targetTestCount' ? 'number' : 'text';
  const showFormElement = !!isUpdating && typeof handleChange === 'function' && !!keyName;

  // If not updating, display values normally
  if (!showFormElement) {
    return (
      <ListItem sx={{ pt: 0, pb: 0 }}>
        <ListItemText primary={name} primaryTypographyProps={{ sx: { fontSize: 14 } }} />
        <Box sx={secondarySx}>
          <ListItemText secondary={typeof value === 'string' ? value : dayjs(value).format('MM/DD/YYYY')} />
        </Box>
      </ListItem>
    );
  }

  // If updating, display form element
  return (
    <ListItem sx={{ pt: 0, pb: 0 }}>
      <ListItemText primary={name} primaryTypographyProps={{ sx: { fontSize: 14 } }} secondaryTypographyProps={{ sx: { color: 'red' } }} />
      {error && <ListItemText secondary={error} />}
      {isDate ? (
        <CardItemDatePicker
          value={value as dayjs.Dayjs}
          onChange={(date) => handleChange(keyName, date as dayjs.Dayjs)}
          disabled={shouldDisable}
          minDate={minDate}
          maxDate={maxDate}
          error={!!error}
        />
      ) : (
        <TextField
          inputProps={{
            style: {
              width: '184px',
              padding: '0 0 0 10px',
              boxSizing: 'border-box',
            },
            min: min,
            max: max,
            inputMode: 'decimal',
          }}
          size="small"
          variant="filled"
          type={fieldType}
          value={value}
          onChange={(e) => handleChange(keyName, e.target.value)}
          error={!!error}
        />
      )}
    </ListItem>
  );
}

export function ScrollBox({ children, ...props }: { children: ReactNode; sx?: SxProps<Theme> }) {
  return (
    <Box sx={{ maxHeight: '100%', ...props.sx }}>
      <Box
        {...props}
        sx={{
          height: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          ...props.sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

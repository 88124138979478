import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import SourcesLineChart from './SourcesLineChart';
import { CustomTooltip } from '../HiringUtils/helperFunctions';
import FinalRoundLevelBarChart from './FinalRoundLevelBarChart';
import dayjs from 'dayjs';

export default function JobSourceView({ data, filters }) {
    let jobSourcesStatusMap = [
        {
            value: 'takeHomeRejected',
            label: 'Take Home Rejected',
            count: 0,
        },
        {
            value: 'blankTakeHome',
            label: 'Blank Take Home',
            count: 0,
        },
        {
            value: 'rejectNoVisa',
            label: 'Reject No Visa',
            count: 0,
        },
        {
            value: 'passedTakeHome',
            label: 'Passed Take-home',
            count: 0,
        },
    ];

    let jobSourcesFinalRoundStatusMap = [
        {
            value: 'finalRoundScheduled',
            label: 'Final Round Scheduled',
            count: 0,
        },
        {
            value: 'rejectBelowTechnicalBar',
            label: 'Reject - Below Technical Bar',
            count: 0,
        },
        {
            value: 'queuedForDirectorChat',
            label: 'Queued for Director Chat',
            count: 0,
        },
        {
            value: 'hired',
            label: 'Hired',
            count: 0,
        },
        {
            value: 'rejectEricInterview',
            label: 'Eric Rejected',
            count: 0,
        },
        {
            value: 'waitingOnFinalDecision',
            label: 'Waiting on Final Round Decision',
            count: 0,
        },
        {
            value: 'rejectGhosted',
            label: 'Reject - Ghosted',
            count: 0,
        },
        {
            value: 'queuedForFinalRound',
            label: 'Queued for Final Round',
            count: 0,
        },
    ];

    // Filter data by candidate status
    data.candidates.forEach((submission) => {
        // Filters candidates based on dates selected
        if (submission.jobSourceName === filters.source && dayjs(submission.dateSubmitted).isAfter(dayjs(filters.gte)) && dayjs(submission.dateSubmitted).isSameOrBefore(dayjs(filters.lte))) {
            if (jobSourcesStatusMap.some((countObj) => countObj.value === submission.status)) {
                let sourceObj = jobSourcesStatusMap.find((obj) => obj.value === submission.status);
                sourceObj.count += 1;
            }
            if (jobSourcesFinalRoundStatusMap.some((countObj) => countObj.value === submission.status)) {
                let sourceObj = jobSourcesStatusMap.find((obj) => obj.value === "passedTakeHome");
                sourceObj.count += 1;
            }
        }
    });

    let totalCount = 0;
    jobSourcesStatusMap.forEach((status) => {
        totalCount += status.count;
    });

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width="100%"
                    height={300}
                    data={jobSourcesStatusMap}
                    margin={{
                        right: 10,
                        left: 20,
                        bottom: 25,
                    }}
                >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis
                        dataKey="label"
                        tick={{
                            fontSize: 12,
                            width: 20,
                        }}
                        interval={0} // Show all labels
                    />
                    <YAxis domain={[0, 'dataMax']} label={{ value: 'Total Count', angle: -90, position: 'insideLeft' }} />
                    <Tooltip
                        content={<CustomTooltip totalCount={totalCount} filters={filters} />}
                    />
                    <Bar stackId="submissions" dataKey="count" fill={hslStringToRgbString(stringToColor(filters.source))} opacity={0.65} />

                    <Legend
                        payload={[
                            { value: `Totals Based on Submission Statuses`, color: hslStringToRgbString(stringToColor(filters.source)) },
                        ]}
                        verticalAlign="top"
                        height={32}
                    />
                </BarChart>
                <FinalRoundLevelBarChart data={data} filters={filters} />
                <SourcesLineChart data={data} filters={filters} />
            </ResponsiveContainer>
        </div>
    );
}

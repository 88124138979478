import { useState } from 'react';
import dayjs from 'dayjs';

import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { DateFilters } from '../../UtilityComponents';

import { classNames } from '../../InvestigationBoard/helpers';

export default function TeamUtilizationFilters({ filters, setFilters }) {
  const [query, setQuery] = useState('');
  const allTeams = JSON.parse(localStorage.getItem('teams')).filter((team) => team.teamName !== 'TestTeam');

  const filteredTeams = query === '' ? allTeams : allTeams.filter((team) => team.teamName.toLowerCase().includes(query.toLowerCase()));

  const handleChangeTeamFilters = (teamName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      team: {
        ...prevFilters.team,
        entity: {
          type: 'team',
          data: {
            name: teamName,
          },
        },
      },
    }));
  };

  return (
    <div className="bg-white">
      {/* Team Filter */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Team Filters
        </h2>
        <div className="bg-white pb-4">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
            <div className="flex items-center">
              <Combobox
                as="div"
                value={allTeams.find((team) => team.teamName === filters.entity?.data?.name) || ''}
                onChange={(team) => {
                  setQuery('');
                  handleChangeTeamFilters(team.teamName);
                }}
              >
                <label className="block text-sm font-medium leading-6 text-gray-400">Select Team</label>
                <div className="relative mt-2">
                  <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery('')}
                    displayValue={(team) => team?.teamName}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Combobox.Button>

                  {allTeams.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredTeams.map((team) => (
                        <Combobox.Option
                          key={team.id}
                          value={team}
                          className={({ active }) =>
                            classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <div className="flex items-center">
                                <img src={team.imageUrl} alt="avatar" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{team.teamName}</span>
                              </div>

                              {selected && (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-indigo-600',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}
                </div>
              </Combobox>
            </div>
            <div className="hidden sm:block">
              <DateFilters
                values={{ gte: filters.gte, lte: filters.lte }}
                updateFn={(dateParam, newDate) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    team: {
                      ...prevFilters.team,
                      [dateParam]: dayjs(newDate).format('YYYY-MM-DD'),
                    },
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import { motion } from 'framer-motion';
import { MouseEvent } from 'react';
import { Button } from '@/components/ui/button';
import { Conversation } from '../data/types';
import { useCommunications } from '@/components/context/CommunicationsContext';

// import { useMutationState, MutationState } from '@tanstack/react-query';

// interface TaskMutationVariables {
//   task?: {
//     id: string;
//   };
//   endpoint?: string;
// }

export function ClaimAndUnclaimButton({ conversation, action }: { conversation: Conversation; action: string }) {
  const { suiteAction, isPending } = useCommunications();
  const determineIsPending = (conversation: Conversation) => {
    return conversation.messages.some((message) => {
      if (isPending[String(message.messageTask?.id)]) {
        return isPending[String(message.messageTask?.id)][action];
      }
      return false;
    });
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>, conversation: Conversation, action: string) => {
    e.stopPropagation();

    if (action === '/claim') {
      if (conversation.messages.length) {
        for (const message of conversation.messages) {
          if (message.messageTask && !message.messageTask.assignedTo?.id && message.messageTask.status !== 'done') {
            suiteAction(message.messageTask, action);
          }
        }
      }
    } else if (action === '/unclaim') {
      if (conversation.messages.length) {
        for (const message of conversation.messages) {
          if (message.messageTask && message.messageTask.status !== 'done') {
            suiteAction(message.messageTask, action);
          }
        }
      }
    }
  };

  return (
    <div className="flex items-center justify-evenly">
      <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 0.2 }}>
        {action === '/unclaim' && <DoneButton conversation={conversation} />}
        <Button
          disabled={determineIsPending(conversation)}
          onClick={(e) => handleButtonClick(e, conversation, action)}
          variant={action === '/unclaim' ? 'unclaim' : action === '/claim' ? 'claim' : action === '/done' ? 'done' : 'default'}
          size="action"
          className="font-normal border-0 ml-3"
        >
          {determineIsPending(conversation) ? (
            <span className="p-1">{action === '/claim' ? 'Claiming' : 'Un-claiming'}</span>
          ) : (
            <span className="p-1">{action === '/claim' ? 'Claim' : 'Unclaim'}</span>
          )}
        </Button>
      </motion.div>
    </div>
  );
}

export function DoneButton({ conversation }: { conversation: Conversation }) {
  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
  const { suiteAction, isPending } = useCommunications();
  const determineIsPending = (conversation: Conversation) => {
    return conversation.messages.some((message) => {
      if (isPending[String(message.messageTask?.id)]) {
        return isPending[String(message.messageTask?.id)]['/done'];
      }
      return false;
    });
  };

  const handleDoneClick = (e: MouseEvent<HTMLButtonElement>, conversation: Conversation) => {
    e.stopPropagation();
    if (conversation.messages.length) {
      for (const message of conversation.messages) {
        if (message.messageTask && message.messageTask.assignedTo?.id === currentUser.qawId && message.messageTask.status !== 'done') {
          suiteAction(message.messageTask, '/done');
        }
      }
    }
  };

  return (
    <Button
      disabled={determineIsPending(conversation)}
      onClick={(e) => handleDoneClick(e, conversation)}
      variant="done"
      className="h-5 px-2 text-xs font-normal border-0"
    >
      {determineIsPending(conversation) ? <span className="p-1">Done-ing</span> : <span className="p-1">Done</span>}
    </Button>
  );
}

import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef, MRT_TableState } from 'material-react-table';
import { useMemo } from 'react';
import MainDrawer from '../ClientMilestones/MainDrawer';
import { ClientSummaryTableRow } from '../types';
import { usePlanningContext } from '../../../context/PlanningContext';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import ApproveAllButton from '../ApproveAllButton';
import PlanningDrawer from '../PlanningDrawer';

const getCellProps = ({ status }: { status: 'ok' | 'warning' | 'danger' }) => {
  const colorMap = {
    ok: '#e8f5e9', // light green
    warning: '#fff3e0', // light yellow
    danger: '#ffebee', // light red
  };
  return {
    sx: {
      backgroundColor: colorMap[status],
    },
  };
};

export default function ClientSummaryTable() {
  const { clientSummaries, selectedWeek, showApproveAllButton } = usePlanningContext();
  const data = clientSummaries.data ?? [];

  const initialState: Partial<MRT_TableState<ClientSummaryTableRow>> = {
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    sorting: [
      {
        id: 'name',
        desc: false,
      },
    ],
    density: 'compact',
    columnPinning: {
      left: ['name'],
    },
    columnSizing: {
      name: 120,
    },
  };

  const columns = useMemo<MRT_ColumnDef<ClientSummaryTableRow>[]>(() => {
    return [
      {
        header: 'Customer',
        enablePinning: true,
        columns: [
          {
            accessorKey: 'name',
            header: 'Name',
            filterVariant: 'multi-select',
            Cell: ({ row }) => {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                  <Tooltip title={`View Client Summary for ${row.original.name}`}>
                    <Link to={`/client-summary/${row.original.id}`} style={{ color: 'blue' }}>
                      {row.original.name}
                    </Link>
                  </Tooltip>
                </Box>
              );
            },
          },
          {
            accessorKey: 'isProjectPlanComplete',
            header: 'Project Plan Status',
            Cell: ({ row }) => {
              return <PlanningDrawer client={row.original} buttonColor={row.original.isProjectPlanComplete ? 'success' : 'warning'} />;
            },
          },
        ],
      },
      {
        header: 'Maintenance',
        columns: [
          {
            accessorKey: 'maintenance.incompleteTestCount',
            header: 'Tests In Maint',
            accessorFn: (row) => row.maintenance?.incompleteTestCount || 0,
            Cell: ({ row }) => (
              <Tooltip title="Total number of tests in maintenance that are unblocked">
                <span>{row.original.maintenance?.incompleteTestCount || 0}</span>
              </Tooltip>
            ),
            enableColumnFilter: false,
          },
          {
            accessorKey: 'maintenance.completedTestCount',
            header: 'Completed This Week',
            accessorFn: (row) => row.maintenance?.completedTestCount || 0,
            Cell: ({ row }) => (
              <Tooltip
                title={`Number of tests maintained between ${selectedWeek?.startOf('week').format('MM/DD')} and ${selectedWeek
                  ?.endOf('week')
                  .format('MM/DD')}`}
              >
                <span>{row.original.maintenance?.completedTestCount || 0}</span>
              </Tooltip>
            ),
            enableColumnFilter: false,
          },
          {
            accessorKey: 'maintenance.scheduledTestCount',
            header: 'Scheduled This Week',
            accessorFn: (row) => row.maintenance?.scheduledTestCount || 0,
            Cell: ({ row }) => (
              <Tooltip
                title={`Number of tests scheduled for maintenance between ${selectedWeek?.startOf('week').format('MM/DD')} and ${selectedWeek
                  ?.endOf('week')
                  .format('MM/DD')}`}
              >
                <span>{row.original.maintenance?.scheduledTestCount || 0}</span>
              </Tooltip>
            ),
            enableColumnFilter: false,
          },
        ],
      },
      {
        id: 'creationColumns',
        header: 'Creation',
        columns: [
          {
            accessorKey: 'creation.incompleteTestCount',
            header: 'Draft Tests',
            Cell: ({ row }) => {
              return (
                <Tooltip title="Total number of tests in unblocked draft workflows">
                  <span>{row.original.creation?.incompleteTestCount}</span>
                </Tooltip>
              );
            },
            accessorFn: (row) => row.creation?.incompleteTestCount || 0,
            enableColumnFilter: false,
          },
          {
            accessorKey: 'creation.completedTestCount',
            header: 'Completed This Week',
            accessorFn: (row) => row.creation?.completedTestCount || 0,
            Cell: ({ row }) => (
              <Tooltip
                title={`Number of tests created between ${selectedWeek?.startOf('week').format('MM/DD')} and ${selectedWeek
                  ?.endOf('week')
                  .format('MM/DD')}`}
              >
                <span>{row.original.creation?.completedTestCount || 0}</span>
              </Tooltip>
            ),
            enableColumnFilter: false,
          },
          {
            accessorKey: 'creation.scheduledTestCount',
            header: 'Scheduled This Week',
            accessorFn: (row) => row.creation?.scheduledTestCount || 0,
            Cell: ({ row }) => (
              <Tooltip
                title={`Number of tests scheduled for creation between ${selectedWeek?.startOf('week').format('MM/DD')} and ${selectedWeek
                  ?.endOf('week')
                  .format('MM/DD')}`}
              >
                <span>{row.original.creation?.scheduledTestCount || 0}</span>
              </Tooltip>
            ),
            muiTableBodyCellProps: ({ row }) => {
              const hasMilestone = row.original.activeMilestone;
              const hasEnoughScheduled = hasMilestone && row.original.creation?.scheduledTestCount >= row.original.requiredVelocity;
              return getCellProps({
                status: row.original.activeMilestoneBlockers?.length
                  ? 'danger'
                  : !row.original.activeMilestone
                  ? 'warning'
                  : hasEnoughScheduled
                  ? 'ok'
                  : 'danger',
              });
            },
            enableColumnFilter: false,
          },
        ],
      },
      {
        id: 'milestoneColumns',
        header: 'Milestone',
        columns: [
          {
            accessorKey: 'requiredVelocity',
            header: 'Req. Velocity',
            enableColumnFilter: false,
            sortUndefined: 1,
            muiTableBodyCellProps: ({ row }) => {
              return getCellProps({
                status: row.original.activeMilestoneBlockers?.length
                  ? 'danger'
                  : !row.original.activeMilestone
                  ? 'warning'
                  : row.original.isOnTrack
                  ? 'ok'
                  : 'danger',
              });
            },
            accessorFn: (row) => Math.max(row.requiredVelocity, 0),
          },
          {
            accessorKey: 'weeksRemaining',
            header: 'Weeks Remaining',
            enableColumnFilter: false,
            sortUndefined: 1,
            Cell: ({ row }) => row.original.weeksRemaining ?? 'N/A',
            muiTableBodyCellProps: ({ row }) =>
              getCellProps({
                status: row.original.activeMilestoneBlockers?.length
                  ? 'danger'
                  : !row.original.activeMilestone
                  ? 'warning'
                  : row.original.isOnTrack
                  ? 'ok'
                  : 'danger',
              }),
            accessorFn: (row) => row.weeksRemaining ?? 'N/A',
          },
          {
            accessorKey: 'isOnTrack',
            header: 'Milestone Status',
            filterVariant: 'multi-select',
            muiFilterCheckboxProps: { title: 'On Track?' },
            filterSelectOptions: ['On Track', 'Behind', 'N/A', 'Blocked'],
            accessorFn: (row) =>
              row.activeMilestoneBlockers?.length
                ? 'Blocked'
                : row.isOnTrack && row.activeMilestone
                ? 'On Track'
                : row.activeMilestone
                ? 'Behind'
                : 'N/A',
            muiTableBodyCellProps: ({ row }) =>
              getCellProps({
                status: row.original.activeMilestoneBlockers?.length
                  ? 'danger'
                  : !row.original.activeMilestone
                  ? 'warning'
                  : row.original.isOnTrack
                  ? 'ok'
                  : 'danger',
              }),
          },
          {
            accessorKey: 'activeMilestone',
            header: 'Active Milestone',
            accessorFn: (row) => !!row.activeMilestone,
            filterVariant: 'checkbox',
            muiFilterCheckboxProps: {
              title: 'Has Active Milestone?',
            },
            sortUndefined: 1,
            Cell: ({ row }) => <MainDrawer client={row.original} />,
            minSize: 200,
            muiTableBodyCellProps: ({ row }) =>
              getCellProps({
                status: row.original.activeMilestoneBlockers?.length
                  ? 'danger'
                  : !row.original.activeMilestone
                  ? 'warning'
                  : row.original.isOnTrack
                  ? 'ok'
                  : 'warning',
              }),
          },
        ],
      },
    ];
  }, []);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState,
    columnFilterDisplayMode: 'popover',
    enableColumnResizing: true,
    enableColumnActions: true,
    layoutMode: 'grid',
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableColumnPinning: true,
    state: {
      isLoading: clientSummaries.isLoading || clientSummaries.isFetching || clientSummaries.isPending,
      columnPinning: {
        left: ['name'],
      },
    },
    muiSkeletonProps: { animation: 'pulse' },
    renderTopToolbarCustomActions: () => {
      return showApproveAllButton ? (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <ApproveAllButton />
        </Box>
      ) : null;
    },
  });

  return <MaterialReactTable table={table} />;
}

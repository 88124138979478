import { useState, useEffect } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Typography, Button, List, ListItem, ListItemText, TextField, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest, sendPostRequest } from '../../utils/network';
import { DownloadRounded } from '@mui/icons-material';
import WolfLoader from '../WolfLoader/WolfLoader';
import Toast from '../Layout/Toast';

// Report definitions
const reports = [
  { id: 'bugs-by-group', name: 'Bugs By Group', hasWeeks: true },
  { id: 'bugs-by-month', name: 'Bugs By Month' },
  { id: 'bugs-by-trigger-env', name: 'Bugs By Trigger ENV' },
  { id: 'bugs-by-workflow', name: 'Bugs By Workflow', hasWeeks: true },
  { id: 'csv-tests-report', name: 'CSV Tests Report' },
  { id: 'csv-workflows-report', name: 'CSV Workflows Report' },
  { id: 'maint-by-workflow', name: 'Maintenance By Workflow', hasWeeks: true },
  { id: 'xlsx-tests-report', name: 'XLSX Tests Report' },
  { id: 'workflow-history', name: 'Workflow History' },
  { id: 'workflow-attempt-failure-overlap', name: 'Overlapping Workflow Failures', hasSuiteId: true },
  { id: 'workflow-stability', name: 'Workflow Stability', hasWeeks: true },
];

const specialReports = [
  { id: 'vanta-weekly-report', name: 'Vanta Weekly Report', clientName: 'Vanta' },
  { id: 'salesloft-monthly-report', name: 'Salesloft Monthly Report', clientName: 'Salesloft' },
  { id: 'figma-asana-bugs-report', name: 'Figma Asana Bugs Report', clientName: 'Figma' },
];

const reportDetails = {
  'bugs-by-group': {
    title: 'Bugs By Group',
    description: 'This report shows which groups caught the most bugs. We can set how many weeks back we want to fetch the data from.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728071079/bugs-by-group_zgprwy.png',
  },
  'bugs-by-month': {
    title: 'Bugs By Month',
    description: 'This report shows the distribution of bugs across different months.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728573686/bugs-by-month_bfkjmg.png',
  },
  'bugs-by-trigger-env': {
    title: 'Bugs By Trigger ENV',
    description: 'This report shows which triggers and environments caught the most bugs.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728071097/bugs-by-trigger-env_ve0zge.png',
  },
  'bugs-by-workflow': {
    title: 'Bugs By Workflow',
    description: 'This report shows which workflows caught the most bugs. We can set how many weeks back we want to fetch the data from.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728071104/bugs-by-workflow_pzumjn.png',
  },
  'csv-tests-report': {
    title: 'CSV Tests Report',
    description: 'This report provides a CSV every test for a client, along with the workflow, group, and environment for each test.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728071134/csv-tests-report_rogrzo.png',
  },
  'csv-workflows-report': {
    title: 'CSV Workflows Report',
    description:
      'This report provides a CSV file with every workflow for the client, along with environment, group, status, tags, number of tests, and url.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728071138/csv-workflows-report_xt4umk.png',
  },
  'xlsx-tests-report': {
    title: 'XLSX Tests Report',
    description:
      'This report provides an Excel file with test information grouped by environment, then group, then workflow. It serves as a bit of a test plan.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728071164/xlsx-tests-report_rricpm.png',
  },
  'vanta-weekly-report': {
    title: 'Vanta Weekly Report',
    description: 'This report provides a weekly summary for Vanta.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728591482/vanta_weekly_report_mzfmay.png',
  },
  'salesloft-monthly-report': {
    title: 'Salesloft Monthly Report',
    description: 'This report provides a monthly summary for Salesloft.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728591534/salesloft_monthly_report_braysh.png',
  },
  'figma-asana-bugs-report': {
    title: 'Figma Asana Bugs Report',
    description: 'This report provides a summary of bugs tracked in Figma and Asana.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1728591584/figma_asana_bug_report_p8mrjm.png',
  },
  'workflow-history': {
    title: 'Workflow History',
    description: 'This report provides a summary of workflow history with tags separated by column.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1729207298/workflow-history-example.png',
  },
  'maint-by-workflow': {
    title: 'Maintenance By Workflow',
    description: 'This report provides a summary of maintenance by workflow for a given client.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1732038651/maintenance_by_workflow_example.png',
  },
  'workflow-attempt-failure-overlap': {
    title: 'Overlapping Workflow Failures',
    description: 'This report provides a summary of overlapping workflow failures for a given suite.',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1732130842/overlapping_workflow_failures_example.png',
  },
  'workflow-stability': {
    title: 'Workflow Stability',
    description: 'This report provides a summary of workflows that have not been attached to bug reports in the given time range',
    image: 'https://res.cloudinary.com/huht2rslk/image/upload/v1733962685/workflow_stability_report_screenshot_ovhcun.png',
  },
};

// Download functions for each report type
const downloadFunctions = {
  'bugs-by-group': async (customerName, userId, numberOfWeeks) => {
    return await sendPostRequest('/reports/bugs-by-group', { customerName, userId, numberOfWeeks });
  },
  'bugs-by-month': async (customerName, userId) => {
    return await sendPostRequest('/reports/bugs-by-month', { customerName, userId });
  },
  'bugs-by-trigger-env': async (customerName, userId) => {
    return await sendPostRequest('/reports/bugs-by-trigger-env', { customerName, userId });
  },
  'bugs-by-workflow': async (customerName, userId, numberOfWeeks) => {
    return await sendPostRequest('/reports/bugs-by-workflow', { customerName, userId, numberOfWeeks });
  },
  'csv-workflows-report': async (customerName, userId) => {
    return await sendPostRequest('/reports/csv-workflows-report', { customerName, userId });
  },
  'csv-tests-report': async (customerName, userId) => {
    return await sendPostRequest('/reports/csv-tests-report', { customerName, userId });
  },
  'xlsx-tests-report': async (customerName, userId) => {
    return await sendPostRequest('/reports/xlsx-tests-report', { customerName, userId });
  },
  'vanta-weekly-report': async (customerName, userId) => {
    return await sendPostRequest('/reports/vanta-weekly-report', { customerName, userId });
  },
  'salesloft-monthly-report': async (customerName, userId) => {
    return await sendPostRequest('/reports/salesloft-monthly-report', { customerName, userId });
  },
  'figma-asana-bugs-report': async (customerName, userId) => {
    return await sendPostRequest('/reports/figma-asana-bugs-report', { customerName, userId });
  },
  'workflow-history': async (customerName, userId) => {
    return await sendPostRequest('/reports/workflow-history', { customerName, userId });
  },
  'maint-by-workflow': async (customerName, userId, numberOfWeeks) => {
    return await sendPostRequest('/reports/maint-by-workflow', { customerName, userId, numberOfWeeks });
  },
  'workflow-attempt-failure-overlap': async (customerName, userId, suiteId) => {
    return await sendPostRequest('/reports/failure-overlap', { customerName, userId, suiteId });
  },
  'workflow-stability': async (customerName, userId, numberOfWeeks) => {
    return await sendPostRequest('/reports/workflow-stability', { customerName, userId, numberOfWeeks });
  },
};

export default function ReportsPage() {
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedReport, setSelectedReport] = useState(reports[0].id);
  const [numberOfWeeks, setNumberOfWeeks] = useState(4);
  const [suiteId, setSuiteId] = useState('');
  const [toastData, setToastData] = useState(null);

  const {
    data: customers,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['allCustomers'],
    queryFn: async () => {
      const response = await sendGetRequest('/reports/fetch-all-customers');
      return response.data;
    },
  });

  useEffect(() => {
    if (customers && customers.length > 0) {
      setSelectedCustomer(customers[0].slug);
    }
  }, [customers]);

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
  };

  const handleReportSelect = (reportId) => {
    setSelectedReport(reportId);
  };

  const handleNumberOfWeeksChange = (event) => {
    setNumberOfWeeks(parseInt(event.target.value));
  };

  const handleSuiteIdChange = (event) => {
    setSuiteId(event.target.value);
  };

  const handleDownload = async () => {
    try {
      const selectedCustomerData = customers.find((customer) => customer.slug === selectedCustomer);
      const user = localStorage.getItem('user');
      const userId = user ? JSON.parse(user).slackId : null;

      if (!selectedCustomerData || !userId) {
        console.error('Missing customer data or user ID');
        setToastData({
          title: 'Error',
          message: 'Missing customer data or user ID',
          isSuccess: false,
        });
        return;
      }

      // Show initial toast message
      setToastData({
        title: 'Processing',
        message: 'Your report is being generated and will be sent to you via Slack',
        isSuccess: true,
      });

      const downloadFunction = downloadFunctions[selectedReport];
      if (downloadFunction) {
        const response = await downloadFunction(
          selectedCustomerData.officialName,
          userId,
          reports.find((r) => r.id === selectedReport)?.hasWeeks
            ? numberOfWeeks
            : reports.find((r) => r.id === selectedReport)?.hasSuiteId
            ? suiteId
            : undefined,
        );
        if (response.success) {
          setToastData({
            title: 'Success',
            message: 'Report has been sent to you on Slack',
            isSuccess: true,
          });
        } else {
          const errorMessage = response.error?.message || response.error || 'An error occurred';
          setToastData({
            title: 'Error',
            message: errorMessage,
            isSuccess: false,
          });
        }
      } else {
        console.error('No download function found for this report type');
        setToastData({
          title: 'Error',
          message: 'No download function found for this report type',
          isSuccess: false,
        });
      }
    } catch (error) {
      console.error('Error downloading report:', error);
      const errorMessage = error.message || 'An error occurred while downloading the report';
      setToastData({
        title: 'Error',
        message: errorMessage,
        isSuccess: false,
      });
    }
  };

  if (isLoading) return <WolfLoader />;
  if (isError) return <div>Error loading customers</div>;

  const selectedCustomerData = customers.find((customer) => customer.slug === selectedCustomer);
  const availableReports = [...reports, ...specialReports.filter((report) => report.clientName === selectedCustomerData?.officialName)];

  return (
    <>
      <div className="flex h-screen">
        <div className="w-1/5 p-4">
          <FormControl fullWidth>
            <InputLabel id="customer-select-label">Select Customer</InputLabel>
            <Select labelId="customer-select-label" value={selectedCustomer} onChange={handleCustomerChange} label="Select Customer">
              {customers &&
                customers.map((customer) => (
                  <MenuItem key={customer.id || `${customer.officialName}`} value={customer.slug}>
                    {customer.officialName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <List>
            {availableReports.map((report) => (
              <ListItem
                key={report.id}
                button
                selected={selectedReport === report.id}
                onClick={() => handleReportSelect(report.id)}
                sx={{
                  backgroundColor: specialReports.some((r) => r.id === report.id) ? '#fff9c4' : 'inherit', // Light yellow
                  '&:hover': {
                    backgroundColor: specialReports.some((r) => r.id === report.id) ? '#fff59d' : 'rgba(0, 0, 0, 0.04)', // Slightly darker yellow on hover
                  },
                  '&.Mui-selected': {
                    backgroundColor: specialReports.some((r) => r.id === report.id) ? '#ffeb3b' : 'rgba(0, 0, 0, 0.08)', // Even darker yellow when selected
                    '&:hover': {
                      backgroundColor: specialReports.some((r) => r.id === report.id) ? '#fdd835' : 'rgba(0, 0, 0, 0.12)',
                    },
                  },
                }}
              >
                <ListItemText
                  primary={report.name}
                  primaryTypographyProps={{
                    fontWeight: specialReports.some((r) => r.id === report.id) ? 'bold' : 'normal',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <div className="w-4/5 p-4 flex flex-col h-full">
          <Typography variant="h4" gutterBottom>
            {reportDetails[selectedReport].title}
          </Typography>
          <Typography variant="body1" paragraph>
            {reportDetails[selectedReport].description}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            {reports.find((r) => r.id === selectedReport)?.hasWeeks && (
              <TextField
                type="number"
                label="Number of Weeks"
                value={numberOfWeeks}
                onChange={handleNumberOfWeeksChange}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '36.5px',
                  },
                }}
              />
            )}
            {reports.find((r) => r.id === selectedReport)?.hasSuiteId && (
              <TextField
                type="text"
                label="Suite ID"
                placeholder="Enter Suite ID"
                autoFocus
                value={suiteId}
                onChange={handleSuiteIdChange}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '36.5px',
                  },
                }}
              />
            )}
            <Button variant="contained" startIcon={<DownloadRounded />} onClick={handleDownload}>
              Download Report
            </Button>
          </Box>
          <div className="flex-grow overflow-auto">
            <img
              src={reportDetails[selectedReport].image}
              alt={`${reportDetails[selectedReport].title} preview`}
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </>
  );
}

import { useState } from 'react';
import { formatTime } from '../timeUtlilzationHelpers';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { UtilizationEvents } from '../timeUtilizationTypes';
import { stringToColor, hslStringToRgbString } from '../../../utils/colorUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${formatTime(value)}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default function TotalActivityPieChart({ events }: { events: UtilizationEvents }) {
  const [activeIndex, setActiveIndex] = useState(0);

  if (!events || !events.totalTimeInMsPerCustomer) return <div>No events found</div>;

  const formattedData = Object.entries(events?.totalTimeInMsPerCustomer)
    .filter(([, value]) => value.durationInMs > 0)
    .map(([key, value]) => ({
      name: key,
      value: value.durationInMs,
    }));

  const onPieEnter = (_: unknown, index: number) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="50%" height={500}>
      <PieChart width={400} height={400}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={formattedData}
          cx="50%"
          cy="50%"
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {formattedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={hslStringToRgbString(stringToColor(entry.name))} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

import { Dispatch, SetStateAction, useState } from 'react';
import { TeamInsightsFilter } from '../types';

import { useQuery } from '@tanstack/react-query';

import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import WolfLoader from '../../WolfLoader/WolfLoader';
import { WeeklyMaintenanceChart } from '../charts';
import { WeeklyMaintenanceComparisonTable } from '../tables';
import WeeklyTeamMaintenanceTable from '../tables/WeeklyTeamMaintenanceTable';

import { getCreationOutlineMaintenanceInsights, protectFilters, sumAllCreationOutliningMaintenanceTasks, filterQAEsBySearchTerm } from '../helpers';
import { User } from '../types';

import { WOLF_LOGO } from '../../../constants';

type SumTypeKeys = 'sumTests' | 'sumTasks';

export default function TeamMaintenanceView({ filters }: { filters: TeamInsightsFilter; setFilters: Dispatch<SetStateAction<TeamInsightsFilter>> }) {
  const [selectedQAE, setSelectedQAE] = useState<User | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  // protect team filters from potential stale state
  filters = protectFilters(filters);

  // set filter type to `maintenance`
  filters.types = ['testMaintenance'];

  const {
    data: maintenanceInsights,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['maintenanceInsights', filters],
    queryFn: () => getCreationOutlineMaintenanceInsights(filters),
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const teamSums = sumAllCreationOutliningMaintenanceTasks(maintenanceInsights?.teamTasksByWeekAndType ?? []);

  // Filtered QAEs for the combobox
  const filteredQAEs = filterQAEsBySearchTerm(searchTerm, maintenanceInsights?.team?.members ?? []);

  if (maintenanceInsights?.team?.members && (selectedQAE == null || !maintenanceInsights.team.members.includes(selectedQAE))) {
    setSelectedQAE(maintenanceInsights.team.members[0]);
  }

  return (
    <div className="min-h-screen px-4 pb-4 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-6 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          {filters.entity.type === 'team' && (
            <img src={maintenanceInsights.team.imageUrl ?? WOLF_LOGO} alt="avatar" className="h-14 w-14 rounded-full" />
          )}
          <span className="font-semibold">{maintenanceInsights[filters.entity.type].name}</span>
        </div>
      </div>
      <div className="isolate flex-col divide-y divide-gray-200 border">
        <div className="isolate flex divide-x divide-gray-200">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tests Completed: {teamSums.sumTests}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tasks/Workflows Completed: {teamSums.sumTasks}
          </span>
        </div>
        {teamSums && (
          <div className="isolate flex divide-x divide-gray-200">
            {(['sumTests', 'sumTasks'] as SumTypeKeys[]).map((sumType, sumTypeIdx) => (
              <span
                key={sumTypeIdx}
                className="flex-col space-y-2 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-sm"
              >
                <p>
                  Maintenance - {sumType === 'sumTests' ? 'Tests' : 'Tasks'} {teamSums.sumsByType.testMaintenance?.[sumType] || 0}
                </p>
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="py-4">
        <WeeklyMaintenanceChart data={maintenanceInsights} />
      </div>
      <div className="border p-4 mb-4">
        <WeeklyTeamMaintenanceTable maintenanceInsights={maintenanceInsights} teamSums={teamSums} />
      </div>

      <div className="border p-4">
        <div className="flex flex-col items-center space-y-4">
          <div className="flex flex-wrap items-center justify-center space-x-4 mb-4">
            <span className="text-lg font-semibold">Select QAE to view their weekly maintenance insights:</span>
            <div className="relative w-64">
              <Combobox as="div" value={selectedQAE} onChange={setSelectedQAE}>
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(event) => setSearchTerm(event.target.value)}
                  displayValue={(qae: User | null) => qae?.name || ''}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                {filteredQAEs.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredQAEs
                      .sort((a, b) => {
                        if (a.isLead) return 1;
                        if (b.isLead) return -1;
                        return a.name.localeCompare(b.name);
                      })
                      .map((qae) => (
                        <Combobox.Option
                          key={qae.qawId}
                          value={qae}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'}`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <img src={qae.avatar48} alt="avatar" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                <span className={`ml-3 truncate ${selected ? 'font-semibold' : ''}`}>{qae.name}</span>
                              </div>
                              {selected && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                )}
              </Combobox>
            </div>
          </div>
        </div>

        {selectedQAE &&
          (() => {
            const selectedQAEMaintenanceTasks = maintenanceInsights.teamTasksByWeekAndType.filter(
              (task) => task.completed_by_qaw_id === selectedQAE?.qawId && task.type === 'testMaintenance',
            );

            const qaeSums = sumAllCreationOutliningMaintenanceTasks(selectedQAEMaintenanceTasks);

            return (
              <WeeklyMaintenanceComparisonTable
                maintenanceInsights={{
                  qae: selectedQAE,
                  qaeTasksByWeekAndType: selectedQAEMaintenanceTasks,
                }}
                qaeSums={qaeSums}
                teamMaintenanceInsights={maintenanceInsights}
                teamSums={teamSums}
              />
            );
          })()}
      </div>
    </div>
  );
}

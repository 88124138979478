import Message from './MessageBubble';
import MessageInput from './MessageInput';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

function EmailEditor({ candidateInfo, emailData, isLoading, isFetching, isError, error }) {
  const [singleMessageHeaders, setSingleMessageHeaders] = useState({});
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [selectedThread, setSelectedThread] = useState({});


  useEffect(() => {
    if (!isLoading && !isFetching && emailData?.threads?.length > 0) {
      const threads = emailData.threads;
      const mostRecentThread = threads[0];
      const mostRecentEmailHeaders = mostRecentThread.messages[mostRecentThread.messages.length - 1].headers;


      setSelectedThread(mostRecentThread);
      setSelectedThreadId(mostRecentThread.threadId);
      setSingleMessageHeaders(mostRecentEmailHeaders);
    }
  }, [emailData, isLoading, isFetching]);


  const handleThreadSelection = (threadId) => {
    const thread = emailData.threads.find((t) => t.threadId === threadId);

    if (thread) {

      setSelectedThreadId(thread.threadId);
      setSelectedThread(thread);
      setSingleMessageHeaders(thread.messages[thread.messages.length - 1].headers);
    }
  };


  return (
    <div className="flex flex-col bg-gray-900 text-white overflow-y-auto h-full">
      {/* =================================== THREAD BUTTONS =================================== */}
      <div className="sticky top-0 z-20 bg-gray-800 p-4">
        {(isFetching && isLoading) ? null :
          <div className=" flex overflow-x-auto">
            <select className="w-full bg-gray-800 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedThreadId || ''}
              onChange={(e) => handleThreadSelection(e.target.value)}>

              <option value="" disabled>
                Select a thread
              </option>
              {emailData?.threads?.map((thread) => (
                <option key={thread.threadId} value={thread.threadId}>
                  {`Thread from: ${dayjs(thread?.messages[thread.messages.length - 1].timestamp).format(`MM-DD-YYYY`)}`}
                </option>
              ))}
            </select>
          </div>
        }
      </div>


      {/* =================================== LOADING AREA =================================== */}
      <div className="flex-1 p-6 overflow-y-auto">
        {
          // Handle error state
          isError && (
            <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
              <h1>{'Error loading emails...'}</h1>
              <p>{error.message}</p>
            </div>
          )
        }

        {/* =================================== EMAIL THREADS =================================== */}
        {(isFetching && isLoading)
          ? (
            <div className='flex w-full justify-center align-center h-full'>
              <CircularProgress />
            </div>
          )
          :
          selectedThread && selectedThread.messages ? (
            <div >
              {selectedThread && selectedThread.messages && selectedThread.messages.map((message, idx) => (
                <Message
                  key={idx}
                  time={message.timestamp}
                  text={message.body}
                  sender={message.sender}
                />
              ))}
            </div>
          )
            :
            (
              <h1 className='flex w-full justify-center'>
                {'No emails found'}
              </h1>

            )}

        {/* =================================== MSG INPUT AREA =================================== */}
      </div>
      <MessageInput
        candidate={candidateInfo}
        emailData={emailData}
        singleMessageHeaders={singleMessageHeaders}
      />
    </div>
  );
}

export default EmailEditor;

import { PieChart, Pie, Legend, Cell, Label } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import dayjs from 'dayjs';

const CustomLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        outerRadius,
        fill,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 10) * cos; // angle of longest inner line (left)
    const sy = cy + (outerRadius - 10) * sin; // angle of longest inner line (right)
    const mx = cx + (outerRadius + 40) * cos; // move position of pointer and text left (-) or right (+)
    const my = cy + (outerRadius + 40) * sin; // move position of pointer and text up (-) or down (+)
    const ex = mx + (cos >= 0 ? 1 : -1) * 20; // length of horizontal connector
    const ey = my + (sin >= 0 ? 1 : -1); // angle of horizontal connector
    const textAnchor = cos >= 0 ? 'start' : 'end'; // puts text to left or right depending on chart's center

    return (
        <g>
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={percent >= 0.06 ? 0 : percent <= 0.01 ? 2 : 5}
                textAnchor={textAnchor}
                fill={hslStringToRgbString(stringToColor(payload.jobSource))}
                fontSize={14}
            >
                {percent > 0.01 && (
                    <tspan>{`${payload.jobSource} ${value}`}</tspan>
                )}
                {percent <= 0.01 && (
                    <tspan style={{ fontSize: '11' }}>{`${payload.jobSource}`}</tspan>
                )}
                {percent < 0.06 && percent > 0.01 && (
                    <tspan style={{ fontSize: '12', fill: '#999' }}> {`(${(percent * 100).toFixed(2)}% of total submissions)`}</tspan>
                )}
            </text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={percent < 0.06 ? 0 : 15}
                textAnchor={textAnchor}
                fill="#999"
                fontSize={12}
            >
                {percent >= 0.06 ? `(${(percent * 100).toFixed(2)}% of total submissions)` : ``}
            </text>
        </g>
    );
};


export default function SourcesPieChart({ data, filters }) {
    let jobSourceCounts = [];

    // Filter data by job source
    data.candidates.forEach((submission) => {
        // Filters candidates based on dates selected
        if (dayjs(submission.dateSubmitted).isBetween(dayjs(filters.gte), dayjs(filters.lte), "day", "[]")) {
            if (jobSourceCounts.some((countObj) => countObj.jobSource === submission.jobSourceName)) {
                let sourceObj = jobSourceCounts.find((obj) => obj.jobSource === submission.jobSourceName);
                sourceObj.count += 1;
            } else {
                jobSourceCounts.push({
                    jobSource: submission.jobSourceName,
                    count: 1,
                });
            }
        }
    });

    const totalCount = jobSourceCounts.reduce((accumulator, current) => accumulator + current.count, 0);

    return (
        <div className='w-full flex justify-center'>
            <PieChart width={1200} height={500} >
                <Legend
                    verticalAlign="top"
                    payload={[
                        { value: `Totals Based on Job Source`, color: hslStringToRgbString(stringToColor(filters.source)) },
                    ]}
                />
                <Pie
                    data={jobSourceCounts}
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="70%"
                    fill="#8884d8"
                    dataKey="count"
                    label={<CustomLabel />}
                    labelLine={false}
                >
                    <Label
                        value={`Total: ${totalCount}`}
                        position="center"
                        fontSize={20}
                        fill="#8884d8"
                    />
                    {jobSourceCounts.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={hslStringToRgbString(stringToColor(entry.jobSource))}
                        />
                    ))}›
                </Pie>
            </PieChart>
        </div >
    );
}



import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_GlobalFilterTextField } from "material-react-table";
import { useMemo, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

//Material UI Imports
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Box, lighten } from '@mui/material';

// Component and variable imports
import { statusMap } from '../HiringUtils/StatusConstants';
import EditableCell from '../HiringUtils/EditableCell';
import { useHandleSubmissionChange, useHandleCandidateInfoChange } from '../HiringUtils/helperFunctions';
import { unarchiveSingleSubmission } from '../HiringUtils/api';
import Toast from '../../Layout/Toast';
import CandidateDetailPanel from "../HiringCandidatePanel/CandidateDetailPanel";

function ArchivedTable({ data }) {
  const [toastData, setToastData] = useState(null);
  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.reduce((acc, row) => {
      acc[row.id] = row || '';
      return acc;
    }, {}),
  );
  const handleCandidateInfoChange = useHandleCandidateInfoChange(setValue);
  const handleSubmissionChange = useHandleSubmissionChange(setValue);

  const handleUnArchiveSubmission = async (rows) => {
    let result = await unarchiveSingleSubmission({ id: rows[0].original.id });

    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ? `Submission(s) unarchived.` : 'Something went wrong! Unable to unarchive submission(s).';

    setToastData({
      title: title,
      message: message,
      isSuccess: success,
    });

    await queryClient.refetchQueries({ queryKey: ['combinedData'] });
    await table.resetRowSelection();
  };

  const columns = useMemo(() => {
    return [
      // ========================= CANDIDATE NAME =========================
      {
        id: 'name',
        header: 'Candidate Name',
        accessorKey: 'candidateName',
        accessorFn: (row) => {
          const firstName = row.candidate.firstName;
          const lastName = row.candidate.lastName;


          return `${firstName} ${lastName}`;
        },
        Cell: ({ cell, row }) => {
          const fullName = cell.getValue();
          const candidate = row.original.candidate;
          const globalFilter = table.getState().globalFilter;

          // Handle saving the new name
          const handleSave = (newValue) => {
            const [newFirstName, newLastName] = newValue.split(/\s(.+)/);
            handleCandidateInfoChange({
              candidate: {
                id: candidate.id,
                firstName: newFirstName || '',
                lastName: newLastName || '',
              },
            });
          };

          return (
            <EditableCell
              initialValue={fullName}
              onSave={handleSave}
              placeholder="Candidate Name"
              globalFilter={globalFilter}
            />
          );
        },
      },
      // ========================= CANDIDATE EMAIL =========================
      {
        accessorFn: (row) => row?.candidate.email,
        id: 'email',
        header: 'Candidate Email',
        enableClickToCopy: true,
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.email || '';
          const globalFilter = table.getState().globalFilter;

          // Handle saving the new email
          const handleSave = (newValue) => {
            handleCandidateInfoChange({
              candidate: {
                id: candidate.id,
                email: newValue,
              },
            });
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Email"
              globalFilter={globalFilter}
            />
          );
        },
      },
      // ========================= CANDIDATE LOCATION =========================
      {
        accessorFn: (row) => (row.candidate.location ? row.candidate.location : 'N/A'),
        id: 'location',
        header: 'Location',
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.location || '';
          const globalFilter = table.getState().globalFilter;

          // Handle saving the new location
          const handleSave = (newValue) => {
            handleCandidateInfoChange({
              candidate: {
                id: candidate.id,
                location: newValue,
              },
            });
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Location"
              globalFilter={globalFilter}
            />
          );
        },
      },
      // ========================= INTERVIEW STATUS =========================
      {
        accessorFn: (row) => {
          const status = statusMap.find((s) => s.value === row.status);
          return status.label;
        }, 
        id: 'statusId',
        header: 'Status',

        Cell: ({ row }) => {
          const status = row.original.status;
          const submissionId = row.original.id;
          const matchedStatus = statusMap.find((s) => s.value === status);

          return (
            <>
              <select
                id="status"
                name="status"
                style={{
                  backgroundColor: matchedStatus?.color || 'blue',
                  color: matchedStatus?.textColor || 'white',
                  textAlign: 'center',
                  borderRadius: '20px',
                  padding: '5px 10px',
                  overflow: 'clip',
                  fontSize: '14px',
                }}
                value={status}
                onChange={(e) => {
                  handleSubmissionChange(
                    {
                      submissionId: submissionId,
                      newInfo: { status: e.target.value },
                      row,
                    },
                  );
                }}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
      {
        accessorKey: 'archivedDate',
        id: 'archivedDateId',
        header: 'Archived Date',
        sortingFn: (rowA, rowB, columnId) => {
          const dateA = rowA.getValue(columnId);
          const dateB = rowB.getValue(columnId);
      
          // Handle missing dates first
          if (!dateA && !dateB) return 0;
          if (!dateA) return -1; // Put empty/missing dates first
          if (!dateB) return 1;
      
          // Convert both to timestamps and subtract for ascending order
          const timeA = dayjs(dateA).valueOf(); 
          const timeB = dayjs(dateB).valueOf();
      
          return timeA - timeB;
        },
        Cell: ({ cell }) => {
          const archivedDate = cell.getValue(); // raw value from accessorKey
      
          // Check if valid
          if (!archivedDate || isNaN(new Date(archivedDate))) {
            return <p>No Date Available</p>;
          }
      
          // Format with dayjs
          const formattedDate = dayjs(archivedDate).format('MM/DD/YYYY hh:mm A');
          return <p>{formattedDate}</p>;
        },
      },
    ];
  }, [data, value]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      sorting: [{ id: 'archivedDateId', desc: true }],
      showGlobalFilter: true,
    },
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    autoResetPageIndex: false,
    enableRowSelection: true,
    enableExpanding: true,
    enableSorting: true,

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: ({ row }) => <CandidateDetailPanel row={row} data={data} showTab={true} />,
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            {/* ==================HEADER SEARCH BAR================== */}
            <MRT_GlobalFilterTextField table={table} />

            {/* ===============HEADER HIDE COLS BUTTON=============== */}
            <MRT_ShowHideColumnsButton table={table} />

            <button
              disabled={!table.getIsSomeRowsSelected()}
              style={{
                cursor: table.getIsSomeRowsSelected() ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                handleUnArchiveSubmission(table.getSelectedRowModel().rows, table);
              }}
            >
              <UnarchiveIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>
          </Box>
        </Box>
      );
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </div>
  );
}

export default ArchivedTable;

import '../../../css/gantt.css';
import ClientSummaryTable from './ClientSummaryTable/ClientSummaryTable';
import { Box, Select, MenuItem, Typography, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { QaTeamSelector } from './QaTeamSelector';
import { TeamCapacityPanel } from './TeamCapacityPanel';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
import { usePlanningContext } from '../../context/PlanningContext';
import ProjectedSuiteFailureChart from './ProjectedSuiteFailureChart';
import TaskHeatmap from '../TestHeatMap';
import CsmSelector from './ProjectedSuiteFailureChart/CsmSelector';
import './styles.css';

export default function Summary() {
  const { selectedWeek, setSelectedWeek, filterBy, setFilterBy } = usePlanningContext();
  
  const handleFilterChange = (e: SelectChangeEvent<string>) => {
    setFilterBy(e.target.value as 'qaTeam' | 'csm');
  };

  return (
    <Box>
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4">Planning Hub</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <DatePicker
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(e?.startOf('week') || dayjs())}
              label="Select Week"
              format="MMM D - MMM D"
              showDaysOutsideCurrentMonth
              slotProps={{
                actionBar: {
                  actions: ['today'],
                },
                textField: {
                  InputProps: {
                    value: `${selectedWeek.format('MMM D')} - ${selectedWeek.endOf('week').format('MMM D')}`,
                  },
                },
              }}
            />
          </Box>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Filter by</InputLabel>
            <Select value={filterBy} onChange={handleFilterChange} label='Filter by'>
              <MenuItem value="qaTeam">QA Team</MenuItem>
              <MenuItem value="csm">CSM</MenuItem>
            </Select>
          </FormControl>
          {filterBy === 'csm' && <CsmSelector />}
          {filterBy === 'qaTeam' && <QaTeamSelector />}
        </Box>
      </Box>
      <ProjectedSuiteFailureChart />
          <TaskHeatmap />
      {filterBy === 'qaTeam' && <TeamCapacityPanel />}
      <ClientSummaryTable />
    </Box>
  );
}


import _ from 'lodash';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { ResponsiveContainer } from 'recharts';

import WolfLoader from '../../WolfLoader/WolfLoader';
import { WeeklyInvestigationChart } from '../charts';
import { InvestigationTasksTable, WeeklyInvestigationComparisonTable } from '../tables';
import WeeklyTeamInvestigationTable from '../tables/WeeklyTeamInvestigationTable';

import {
  computeAverageAndMedianAssistedInvestigationTasksPerWeek,
  computeInvestigationAggregatesByWeek,
  getInvestigationInsights,
  protectFilters,
  filterQAEsBySearchTerm,
} from '../helpers';

export default function TeamInvestigationView({ filters }) {
  const [selectedQAE, setSelectedQAE] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // protect team filters from potential stale state
  filters = protectFilters(filters);

  const {
    data: investigationData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['investigationData', filters],
    queryFn: () => getInvestigationInsights(filters),
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const aggregatedTeamInvestigationDataGroupedByWeek = computeInvestigationAggregatesByWeek(
    { primaryData: investigationData.teamInvestigationDataGroupedByWeek, assistedData: investigationData.teamAssistedInvestigationDataGroupedByWeek },
    true,
  );

  const teamAverageAssistedInvestigationDataGroupedByWeek = computeAverageAndMedianAssistedInvestigationTasksPerWeek(
    investigationData.teamAssistedInvestigationDataGroupedByWeek,
    investigationData.team.members,
  );

  const allWeeks = Object.keys(investigationData.teamAggregateData.weekly);

  const teamAverageSuitesInvestigated = (investigationData.teamAggregateData.totals.teamNumberOfSuitesInvestigated / allWeeks.length).toFixed(2);

  const teamAverageFailuresInvestigated = (investigationData.teamAggregateData.totals.teamNumberOfRunsInvestigated / allWeeks.length).toFixed(2);

  // Filtered QAEs for the combobox
  const filteredQAEs = filterQAEsBySearchTerm(searchTerm, investigationData.team.members);

  if (investigationData?.team?.members && (selectedQAE == null || !investigationData.team.members.includes(selectedQAE))) {
    setSelectedQAE(investigationData.team.members[0]);
  }

  return (
    <div className="min-h-full px-4 pb-4 py-2 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-4 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          <img src={investigationData.team.imageUrl} alt="avatar" className="h-14 w-14 rounded-full" />
          <span className="font-semibold">{investigationData.team.name}</span>
        </div>
      </div>
      <div className="isolate flex-col border">
        {/* Team "At A Glance" Header */}
        <div className="isolate grid grid-cols-2">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Suites Investigated: {investigationData.teamAggregateData.totals.teamNumberOfSuitesInvestigated}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Suites Investigated: {teamAverageSuitesInvestigated}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Failures Investigated: {investigationData.teamAggregateData.totals.teamNumberOfRunsInvestigated}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Failures Investigated: {teamAverageFailuresInvestigated}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Total Bug Failures: {investigationData.teamAggregateData.totals.teamNumberOfRunsReportedAsBugs}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Investigation Time: {_.meanBy(_.values(aggregatedTeamInvestigationDataGroupedByWeek), 'avgInvestigationTime').toFixed(2)}{' '}
            minutes
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-r">
            Team Suites Assisted: {_.sumBy(_.values(investigationData.teamAssistedInvestigationDataGroupedByWeek), 'length')}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Average Suites Assisted: {_.meanBy(_.values(teamAverageAssistedInvestigationDataGroupedByWeek), 'mean').toFixed(2)}
          </span>
        </div>
      </div>
      {investigationData && (
        <ResponsiveContainer width="100%" height="100%">
          <WeeklyInvestigationChart data={investigationData} />
        </ResponsiveContainer>
      )}
      {investigationData && (
        <div className="border mb-4 mt-4 p-4">
          <WeeklyTeamInvestigationTable investigationData={investigationData} />
        </div>
      )}

      <div className="border p-4">
        <div className="flex flex-col items-center space-y-4">
          <div className="flex flex-wrap items-center justify-center space-x-4 mb-4">
            <span className="text-lg font-semibold">Select QAE to view their weekly investigation data:</span>
            <div className="relative w-64">
              <Combobox as="div" value={selectedQAE} onChange={setSelectedQAE}>
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(event) => setSearchTerm(event.target.value)}
                  displayValue={(qae) => qae?.name || ''}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                {filteredQAEs.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredQAEs
                      .sort((a, b) => {
                        if (a.isLead) return 1;
                        if (b.isLead) return -1;
                        return a.name.localeCompare(b.name);
                      })
                      .map((qae) => (
                        <Combobox.Option
                          key={qae.qawId}
                          value={qae}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'}`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <img src={qae.avatar48} alt="avatar" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                <span className={`ml-3 truncate ${selected ? 'font-semibold' : ''}`}>{qae.name}</span>
                              </div>
                              {selected && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                )}
              </Combobox>
            </div>
          </div>
        </div>

        {selectedQAE && (
          <WeeklyInvestigationComparisonTable
            qaeData={investigationData.teamAggregateData[selectedQAE.email]}
            teamData={{
              weekly: investigationData.teamAggregateData.weekly,
              totals: investigationData.teamAggregateData.totals,
            }}
            targetQAE={selectedQAE}
            targetTeam={investigationData.team}
          />
        )}
      </div>

      <div className="border mb-8 p-4 grid">
        <InvestigationTasksTable investigationTasks={investigationData.teamAggregateData.teamRawSuites} team={investigationData.team} />
      </div>
    </div>
  );
}

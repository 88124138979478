import {
  Box,
  Button,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  FormControl,
  ListItemText,
  Avatar,
} from '@mui/material';
import { NoInputDatePicker } from './subComponents';
import dayjs, { Dayjs } from 'dayjs';
import { FormEvent, useState } from 'react';
import { styles } from './utils';
import { Blocker, ClientSummaryTableRow, User } from '../types';
import { Edit, Preview } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendPostRequest } from '@/utils/tanstackNetwork';
import { usePlanningContext } from '@/components/context/PlanningContext';

const today = dayjs();

const exampleDescription = `## Description
<!-- E.g. Test budget limit has been reached. -->

## Links to follow up threads
<!-- Example of formatting, replace with actual links 😁-->
[Last follow up here](https://qawolfhq.slack.com/archives/C03TCLML9J6/p1731621021432049)


<!-- Click the icon in the lower right corner to see the formatted markdown -->
`;

type MilestoneBlockerReqBody = {
  title: string;
  description: string;
  nextFollowUpDate: Dayjs;
  customerId: string;
  assigneeId: string;
};

export function MsBlockerCreationForm({
  client,
  toggleParent,
  isPreview,
  setIsPreview,
}: {
  client: ClientSummaryTableRow;
  toggleParent: () => void;
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;
}) {
  const queryClient = useQueryClient();

  const { user } = usePlanningContext();
  const qawUsers = queryClient.getQueryData<User[]>(['getAllQAWUsers']);

  const [showResMsg, setShowResMsg] = useState(false);
  const [values, setValues] = useState({
    title: '',
    description: exampleDescription,
    nextFollowUpDate: today.add(1, 'week'),
    assigneeId: user.qawId,
  });
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    nextFollowUpDate: '',
    assigneeId: '',
  });

  const mutation = useMutation({
    mutationKey: ['create-milestone-blocker'],
    mutationFn: (body: MilestoneBlockerReqBody) => sendPostRequest(`/milestones/${client.id}/create-blocker`, body),
    onSettled: () => {
      setShowResMsg(true);
    },
    onSuccess: (resData: Blocker) => {
      queryClient.setQueriesData({ queryKey: ['clientSummaries'] }, (prev?: ClientSummaryTableRow[]) => updateClientSummaryCache(resData, prev));
    },
  });

  const updateValues = (key: string, val: string | Dayjs | undefined) => {
    setValues((prev) => ({ ...prev, [key]: val }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrors({
      title: values.title ? '' : 'Title is required',
      description: values.description && values.description !== exampleDescription ? '' : 'Description is required',
      nextFollowUpDate: values.nextFollowUpDate ? '' : 'Next follow up date is required',
      assigneeId: values.assigneeId ? '' : 'Assignee is required',
    });

    if (errors.title || errors.description || errors.nextFollowUpDate || errors.assigneeId) {
      return;
    }

    mutation.mutate({
      title: values.title,
      description: values.description,
      nextFollowUpDate: values.nextFollowUpDate,
      assigneeId: values.assigneeId,
      customerId: client.id,
    });
  };

  return (
    <Box sx={{ p: '1em 4em 1em 4em', display: 'flex', flexDirection: 'column', gap: 2, ...styles }} component="form" onSubmit={handleSubmit}>
      <TextField
        id="title"
        label="Blocker Title"
        placeholder="What is the blocking issue?"
        value={values.title}
        onChange={(e) => updateValues('title', e.target.value)}
        required
        helperText={errors.title}
        error={!!errors.title}
      />

      <Box sx={{ position: 'relative', width: '100%', height: 'max-content' }}>
        <MDEditor
          value={values.description}
          onChange={(val?: string) => {
            updateValues('description', val ?? '');
          }}
          preview={isPreview ? 'preview' : 'edit'}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
            components: {
              // Makes links open in new tab and allow Slack app communication
              a: ({ children, ...props }) => (
                <a {...props} target="_blank" rel="opener">
                  {children}
                </a>
              ),
            },
          }}
          height={350}
          data-color-mode="light"
          textareaProps={{
            placeholder: 'Enter details about the blocking issue and include links to follow up threads, etc.',
          }}
          style={{
            boxSizing: 'border-box',
            outline: errors.description ? '1px solid red' : 'none',
          }}
          hideToolbar={isPreview}
        />
        <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
          <Tooltip title={isPreview ? 'Edit' : 'Preview'}>
            <span>
              <IconButton onClick={() => setIsPreview(!isPreview)} disableRipple>
                {isPreview ? <Edit sx={{ fontSize: '1.2em' }} /> : <Preview sx={{ fontSize: '1.2em' }} />}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      <NoInputDatePicker
        label="Next Follow Up Date"
        minDate={today}
        value={values.nextFollowUpDate}
        onChange={(val: Dayjs) => updateValues('nextFollowUpDate', val)}
        required
        helperText={errors.nextFollowUpDate}
        error={!!errors.nextFollowUpDate}
        slotProps={{ textField: { sx: { width: '100%' } } }}
      />

      <FormControl>
        <InputLabel id="assignee-label">Assignee</InputLabel>
        <Select
          size="small"
          labelId="assignee-label"
          label="Assignee"
          value={values.assigneeId}
          onChange={(e) => updateValues('assigneeId', e.target.value)}
          renderValue={(val) => {
            const user = qawUsers?.find((user) => user.qawId === val);
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar src={user?.avatar48} />
                <ListItemText primary={user?.name} />
              </Box>
            );
          }}
        >
          {qawUsers?.map((user) => (
            <MenuItem key={user.qawId} value={user.qawId} sx={{ flexDirection: 'row', gap: 1 }}>
              <Avatar src={user.avatar48} />
              <ListItemText primary={user.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button type="submit" variant="contained">
        Create Blocker
      </Button>

      <Snackbar
        open={showResMsg}
        ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
        autoHideDuration={3000}
        onClose={() => {
          setShowResMsg(false);
          // Close the after a successful mutation
          if (!mutation.isError) toggleParent();
        }}
        message={
          !mutation.isError
            ? '🎉 Created milestone blocker successfully!'
            : "⛔️ Couldn't create milestone blocker, please reach out in #dragons for assistance."
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}

function updateClientSummaryCache(updatedBlocker: Blocker, prev?: ClientSummaryTableRow[]) {
  if (!prev) return;
  return prev.map((clientSummary) => {
    // Find the client summary that matches the milestone blocker's customer id
    if (clientSummary.id === updatedBlocker.customerId) {
      // Add the new milestone blocker to the active milestone blockers for the customer
      return {
        ...clientSummary,
        activeMilestoneBlockers: [...clientSummary.activeMilestoneBlockers, updatedBlocker],
      } as ClientSummaryTableRow;
    }
    // If the client summary does not match the milestone blocker's customer id, return the client summary unchanged
    return clientSummary;
  });
}

import { Fragment, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, TooltipProps } from 'recharts';

import { UtilizationEvents } from '../timeUtilizationTypes';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { stringToColor, hslStringToRgbString } from '../../../utils/colorUtils';

dayjs.extend(duration);

export default function ActivityChart({ events }: { events: UtilizationEvents }) {
  const [suiteId, setSuiteId] = useState(undefined);

  const sortedRawData = events.rawData.sort((a, b) => a.start - b.start);

  // define bounds for x-axis domain
  const domainStart = dayjs(sortedRawData[0].start).unix();
  const domainEnd = dayjs(sortedRawData[sortedRawData.length - 1].end).unix();

  const formattedData = sortedRawData.map((event) => {
    // get the time range for the event
    const range = [event.start / 1000, event.end ? event.end / 1000 : dayjs(event.start).add(5, 'minutes').unix()];

    // we add 60s in the case that the start & and are the same to show _something_ on the chart
    if (range[0] === range[1]) range[1] += 60;

    // calculate the duration of the event
    const durationInSeconds = range[1] - range[0];
    const duration = dayjs.duration(durationInSeconds, 's').format('HH:mm:ss');

    // return the formatted data
    return {
      name: `${event.typeOfAction}`,
      customer: event.customer,
      value: range,
      duration,
      qae: event.qae,
      suiteId: 'suiteId' in event ? event.suiteId : event.originalData.reportedSuiteId,
      issueName: 'originalData' in event ? event.originalData.issueName : undefined,
    };
  });

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', color: '#333', padding: '10px', borderRadius: '4px' }}>
          <strong>{`${label}`}</strong>
          {payload.map((data, index) => (
            <Fragment key={index}>
              <p>
                <strong>Action</strong>: {data.payload.name}
              </p>
              <p>
                <strong>Time Spent</strong>: {data.payload.duration}
              </p>
              <p>
                <strong>Start:</strong> {dayjs.unix(data.payload.value[0]).format('MM/DD/YY h:mm a')}
              </p>
              <p>
                <strong>End:</strong> {dayjs.unix(data.payload.value[1]).format('MM/DD/YY h:mm a')}
              </p>
              <p>
                <strong>QAE:</strong> {data.payload.qae}
              </p>
              <p>
                <strong>Suite ID:</strong> {data.payload.suiteId}
              </p>
              {data.payload.issueName && (
                <p>
                  <strong>Issue Name:</strong> {data.payload.issueName}
                </p>
              )}
            </Fragment>
          ))}
        </div>
      );
    }

    return null;
  };

  formattedData.sort((a, b) => a.customer.localeCompare(b.customer));

  return (
    <>
      {suiteId && <div>Suite ID: {suiteId}</div>}
      <ResponsiveContainer width="100%" height={formattedData.length * 11 < 500 ? 500 : formattedData.length * 11}>
        <BarChart data={formattedData} layout="vertical" margin={{ top: 20, right: 30, left: 90, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} />
          <XAxis type="number" domain={[domainStart, domainEnd]} tickFormatter={(unix) => dayjs.unix(unix).format('MM/DD/YY h:mm a')} />
          <YAxis
            type="category"
            dataKey="customer"
            interval={0}
            tickLine={false}
            textAnchor="end"
            tickFormatter={(value, index) => {
              return index === formattedData.findIndex((d) => d.customer === value) ? value : '';
            }}
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            fill="#000000"
            onClick={(payload) => {
              setSuiteId(payload.payload.suiteId);
            }}
          >
            {formattedData.map((entry, index) => (
              <Cell key={`${entry.customer}-cell-${index}`} fill={hslStringToRgbString(stringToColor(entry.customer))} strokeWidth={2} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

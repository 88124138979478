import { ArrowBack, Cancel, ExpandMore, Save, Edit } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, List, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { Content, Inner, Header, Title, ScrollBox, StyledDrawer, SubDrawerBtn } from './subComponents';
import { DataCard } from './DataCard';
import SubMilestone from './SubMilestone';
import { getIsCurrent, getIsFuture, getRequiredVelocity } from './utils';
import dayjs, { Dayjs } from 'dayjs';
import { Milestone, ClientSummaryTableRow, MilestoneProgress } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendPutRequest } from '@/utils/tanstackNetwork';
import { usePlanningContext } from '@/components/context/PlanningContext';

export type UpdatedMilestoneValues = {
  name: string;
  notes: string;
  targetTestCount: number;
  startDate: Dayjs;
  externalDueDate: Dayjs;
  testVelocity: number;
  subMilestones: MilestoneProgress[];
};

export function MsDrawer({ milestone, closeParents, client }: { milestone: Milestone; closeParents: () => void; client: ClientSummaryTableRow }) {
  const queryClient = useQueryClient();
  const { user } = usePlanningContext();

  // Set the initial values for the milestone
  const initialMilestoneValues = {
    name: milestone.name,
    notes: milestone.notes,
    targetTestCount: milestone.targetTestCount,
    startDate: dayjs(milestone.startDate),
    externalDueDate: dayjs(milestone.externalDueDate),
    testVelocity: milestone.testVelocity,
    subMilestones: milestone.subMilestones,
  };

  // Set to open if it is the current milestone and there are no blockers
  const [isOpen, setIsOpen] = useState(client.activeMilestoneBlockers.length === 0 && getIsCurrent(milestone));
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedMilestoneValues, setUpdatedMilestoneValues] = useState(initialMilestoneValues);
  const [showResMsg, setShowResMsg] = useState(false);

  const toggleSelf = () => {
    setIsUpdating(false);
    setUpdatedMilestoneValues(initialMilestoneValues);
    setIsOpen((prev) => !prev);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  const isManagerOrQaLeadOrDragonOrEric = user.isManager || client.qaLead.id === user.qawId || user.teamId === 34 || user.id === 9;

  // Determine if the milestone is current, future, or past
  const isCurrentMilestone = getIsCurrent(milestone);
  const isFutureMilestone = getIsFuture(milestone);
  const isPastMilestone = !isCurrentMilestone && !isFutureMilestone;

  // Determine which subMilestone weeks have already passed and which are remaining
  const pastWeeks = isFutureMilestone ? [] : updatedMilestoneValues.subMilestones.filter((sm) => dayjs().isAfter(dayjs(sm.dueDate)));
  const remainingWeeks = isFutureMilestone
    ? updatedMilestoneValues.subMilestones
    : updatedMilestoneValues.subMilestones.filter((sm) => dayjs(sm.dueDate).isAfter(dayjs().startOf('day')));

  // Calculate the required velocity and planned velocity for the milestone
  const totalActiveTestCount = client.activeTestCount;
  const requiredVelocity = getRequiredVelocity(updatedMilestoneValues.targetTestCount, totalActiveTestCount, remainingWeeks.length);

  // Mutation to update the milestone
  const mutation = useMutation({
    mutationKey: ['updateMilestone'],
    mutationFn: ({ milestoneId, updatedMilestoneValues }: { milestoneId: string; updatedMilestoneValues: UpdatedMilestoneValues }) => {
      return sendPutRequest(`/milestones/${milestoneId}/update-milestone`, {
        updatedMilestoneValues: {
          ...updatedMilestoneValues,
          startDate: updatedMilestoneValues.startDate.toDate(),
          externalDueDate: updatedMilestoneValues.externalDueDate.toDate(),
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientSummary', client.id] });
      setShowResMsg(true);
      setIsUpdating(false);
    },
  });

  const handleUpdate = () => {
    mutation.mutate({ milestoneId: milestone.id.toString(), updatedMilestoneValues });
  };

  return (
    <Box>
      <SubDrawerBtn onClick={toggleSelf} text={`${milestone.name} ${isCurrentMilestone ? `(Current)` : ``}`} />
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Title text={`${client.name}`} />

              {/* Only display the update button for current and future milestones */}
              {!isPastMilestone && isManagerOrQaLeadOrDragonOrEric &&
                (!isUpdating ? (
                  <Button
                    sx={{ minWidth: '100px' }}
                    onClick={() => {
                      setIsUpdating((prev) => !prev);
                      setUpdatedMilestoneValues(initialMilestoneValues);
                    }}
                    disableRipple
                    endIcon={<Edit />}
                    variant="outlined"
                    size="small"
                  >
                    Edit
                  </Button>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', gap: 1 }}>
                    <Button
                      sx={{ minWidth: '100px' }}
                      onClick={() => {
                        setIsUpdating(false);
                        setUpdatedMilestoneValues(initialMilestoneValues);
                      }}
                      disabled={mutation.isPending}
                      disableRipple
                      endIcon={<Cancel />}
                      variant="outlined"
                      size="small"
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ minWidth: '100px' }}
                      onClick={handleUpdate}
                      disableRipple
                      endIcon={<Save />}
                      variant="outlined"
                      size="small"
                      disabled={mutation.isPending}
                    >
                      Save
                    </Button>
                  </Box>
                ))}
            </Box>
          </Header>
          <Content sx={{ height: '90vh' }}>
            <ScrollBox sx={{ height: '100%' }}>
              <DataCard
                milestone={milestone}
                isCurrentMilestone={isCurrentMilestone}
                isFutureMilestone={isFutureMilestone}
                requiredVelocity={requiredVelocity}
                isUpdating={isUpdating}
                updatedMilestoneValues={updatedMilestoneValues}
                setUpdatedMilestoneValues={setUpdatedMilestoneValues}
                totalActiveTestCount={totalActiveTestCount}
              />
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', p: '.5em' }}>
                <Box sx={{ width: '100%' }}></Box>
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                  <Typography variant="caption">Tests Created / Req'd</Typography>
                </Box>
                <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                  <Typography variant="caption">Total Active / Expected</Typography>
                </Box>
              </Box>
              {!!pastWeeks.length && (
                <Accordion
                  sx={{
                    borderRadius: '4px',
                    '&:before': {
                      display: 'none', // Remove the top border
                    },
                  }}
                  disableGutters
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>Past Weeks</AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {pastWeeks.map((sm, i) => {
                      const previousWeek = pastWeeks[i - 1];
                      const initialTestCountThisWeek = previousWeek ? previousWeek.actualTests : milestone.initialTestCount;
                      const thisWeeksTestCount = sm.actualTests - (initialTestCountThisWeek as number);

                      // Calculate the required velocity for this week, using the previous week's actual tests as a baseline
                      const requiredVelocityThisWeek =
                        i === 0
                          ? Math.round(
                              getRequiredVelocity(
                                updatedMilestoneValues.targetTestCount,
                                milestone.initialTestCount as number,
                                milestone.subMilestones.length - i,
                              ),
                            )
                          : Math.round(
                              getRequiredVelocity(
                                updatedMilestoneValues.targetTestCount,
                                previousWeek.actualTests,
                                milestone.subMilestones.length - i,
                              ),
                            );

                      return (
                        <SubMilestone
                          key={sm.id}
                          subMilestone={sm}
                          thisWeeksTestCount={thisWeeksTestCount}
                          requiredVelocityThisWeek={requiredVelocityThisWeek}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}
              <List>
                {remainingWeeks.map((sm) => {
                  // Calculate the number of tests created this week, using the last week's actual tests as a baseline or
                  // the milestone's initial test count if this is the first week
                  const lastWeek = pastWeeks.at(-1);
                  const initialTestCountThisWeek = lastWeek
                    ? lastWeek.actualTests
                    : !isNaN(milestone.initialTestCount as number)
                    ? milestone.initialTestCount
                    : sm.actualTests;
                  const thisWeeksTestCount = sm.actualTests - (initialTestCountThisWeek as number);
                  const requiredVelocityThisWeek = Math.ceil(
                    getRequiredVelocity(updatedMilestoneValues.targetTestCount, lastWeek?.actualTests || sm.actualTests, remainingWeeks.length),
                  );

                  return (
                    <SubMilestone
                      key={sm.id}
                      subMilestone={sm}
                      thisWeeksTestCount={thisWeeksTestCount}
                      requiredVelocityThisWeek={requiredVelocityThisWeek}
                    />
                  );
                })}
              </List>
            </ScrollBox>
          </Content>
        </Inner>
      </StyledDrawer>
      <Snackbar
        open={showResMsg}
        ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
        autoHideDuration={3000}
        onClose={() => {
          setShowResMsg(false);
        }}
        message={
          !mutation.isError
            ? '🎉 Updated milestone successfully!'
            : "⛔️ Couldn't update milestone, please reach out in #dragons for assistance."
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}

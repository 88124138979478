import { usePlanningContext } from '@/components/context/PlanningContext';
import { ExpandMore } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { Accordion, Typography, AccordionDetails, AccordionSummary, Skeleton } from '@mui/material';
import { sendPostRequest } from '@/utils/tanstackNetwork';
import { SuiteFailureData } from './types';
import Chart from './Chart';
import { SyntheticEvent, useState, useEffect } from 'react';
import DaySelector from './DaySelector';
import dayjs from 'dayjs';

export default function ProjectedSuiteFailureChart() {
  const { selectedQaTeamIds, selectedCsmQawId, filterBy } = usePlanningContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);

  const suiteFailurePredictions = useQuery<SuiteFailureData[]>({
    queryKey: ['projectedSuiteFailureChart', selectedQaTeamIds, selectedCsmQawId, filterBy],
    queryFn: () => sendPostRequest('/client-summaries/suite-failure-predictions', { qaTeamIds: selectedQaTeamIds, selectedCsmQawId: filterBy === 'csm' ? selectedCsmQawId : null }),
    enabled: isExpanded,
  });

  useEffect(() => {
    if (suiteFailurePredictions.data && suiteFailurePredictions.data.length > 0) {
      const dayOfWeek = dayjs(suiteFailurePredictions.data[0].forecast.forecastStart).day();
      setSelectedDay(dayOfWeek);
    }
  }, [suiteFailurePredictions.data]);

  const handleAccordionChange = (_event: SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  return (
    <Accordion onChange={handleAccordionChange} disableGutters>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">Projected Suite Failure Chart</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!suiteFailurePredictions.isFetched && <Skeleton variant="rectangular" height={440} />}
        {suiteFailurePredictions.isFetched && (
          <DaySelector
            days={[0, 1, 2, 3, 4, 5, 6, 7]}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            startDay={suiteFailurePredictions.data?.[0] ? dayjs(suiteFailurePredictions.data[0].forecast.forecastStart).day() : 0}
          />
        )}
        {suiteFailurePredictions.isFetched && <Chart data={suiteFailurePredictions.data as SuiteFailureData[]} selectedDay={selectedDay} />}
      </AccordionDetails>
    </Accordion>
  );
}

import { BugCard } from './cardTypes/BugCard';
import { InvestigationCard } from './cardTypes/InvestigationCard';
import { MessageCard } from './cardTypes/MessageCard';
import { GenericTaskCard } from './cardTypes/GenericTaskCard';
import { PromotionTaskCard } from './cardTypes/PromotionTaskCard';
import ToggleButton from './ToggleButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { sendPostRequest } from '@/utils/network';

/**
 * Renders an investigation column with various types of cards.
 * @param {object} props - The component props.
 * @param {string} props.title - The title of the column.
 * @param {import('../../types').QAWTask[]} props.tasks - The triage cards.
 * @param {Function} props.toggleClaimed - The function to toggle claimed status.
 * @param {{id: string, email: string}} props.user - The user object.
 * @param {Function} props.suiteAction - The suite action function.
 * @param {Function} props.setShowNotesModal - The function to set show notes modal.
 * @param {Function} props.setNotesModalData - The function to set notes modal data.
 * @param {Function} [props.setShowNewTaskModal] - The function to set show new task modal.
 * @param {Function} [props.setNewTaskModalData] - The function to set new task modal data.
 * @param {boolean} [props.showUserTasksOnly] - Whether to show only the user's tasks.
 * @param {Function} [props.setShowUserTasksOnly] - The function to set show user tasks only.
 * @param {boolean} props.showMessageTasks - Whether to show Message tasks.
 * @param {Function} props.setShowMessageTasks - The function to set show Message tasks.
 * @param {boolean} props.showGenericTasks - Whether to show generic tasks.
 * @param {Function} props.setShowGenericTasks - The function to set show generic tasks.
 * @param {boolean} props.showTriageTasks - Whether to show triage tasks.
 * @param {Function} props.setShowTriageTasks - The function to set show triage tasks.
 * @param {Function} props.setEditingNotes - The function to set editing notes.
 * @param {boolean} props.hasUserClaimedMaxTasks - Whether the user has claimed the max number of tasks allowed.
 * @param {boolean} props.showPromotionTasks - Whether to show Promotion tasks.
 * @param {Function} props.setShowPromotionTasks - The function to set show Promotion tasks.
 * @param {boolean} props.primaryTeamOnly - Whether to show Primary team tasks only
 * @param {Function} props.setPrimaryTeamOnly - The function to set Primary team tasks only
 * @param {boolean} [props.showUserTodosOnly] - Whether to show only the user's todo tasks.
 * @param {Function} [props.setShowUserTodosOnly] - The function to set show user todo tasks only.
 * @param {Function} props.setToastData - The function to set toast data.
 * @returns {import('react').JSX.Element} The rendered component.
 */
export function InvestigationColumn({
  title,
  tasks,
  toggleClaimed,
  user,
  suiteAction,
  setShowNotesModal,
  setNotesModalData,
  setShowNewTaskModal,
  setNewTaskModalData,
  setEditingNotes,
  showUserTasksOnly,
  showMessageTasks,
  setShowMessageTasks,
  setShowUserTasksOnly,
  showGenericTasks,
  setShowGenericTasks,
  showTriageTasks,
  setShowTriageTasks,
  hasUserClaimedMaxTasks,
  showPromotionTasks,
  setShowPromotionTasks,
  primaryTeamOnly,
  setPrimaryTeamOnly,
  showUserTodosOnly,
  setShowUserTodosOnly,
  setToastData,
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newTaskSuiteId, setNewTaskSuiteId] = useState('');

  const queryClient = useQueryClient();

  const addSuiteMutation = useMutation({
    mutationFn: (data) => {
      return sendPostRequest('/add-investigation-task', data).then((response) => {
        if (response.success === false) {
          throw new Error(response.error.response.data.message || 'Failed to add suite');
        }
        return response;
      });
    },
    onSuccess: () => {
      setToastData({
        title: 'Success',
        message: 'Suite added',
        isSuccess: true,
      });
    },
    onError: (error) => {
      setToastData({
        title: 'Error',
        message: error.message || 'Failed to add suite',
        isSuccess: false,
      });
    },
    onSettled: () => {
      setShowConfirmModal(false);
      setNewTaskSuiteId('');
      queryClient.invalidateQueries({ queryKey: ['openTasks'] });
    },
  });

  /**
   * Returns the appropriate card component based on the task type and subtype.
   * @param {import('../../types').QAWTask} task - The task object.
   * @returns {import('react').JSX.Element} - The card component.
   */
  function getCardComponent(task) {
    if (task.type === 'generic') {
      if (task.subType === 'message') {
        return (
          <MessageCard
            task={task}
            toggleClaimed={toggleClaimed}
            user={user}
            suiteAction={suiteAction}
            setShowNewTaskModal={setShowNewTaskModal}
            setNewTaskModalData={setNewTaskModalData}
          />
        );
      } else if (task.subType === 'bugVerification') {
        return <BugCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
      } else {
        return <GenericTaskCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
      }
    } else if (task.type === 'suiteInvestigation') {
      return (
        <InvestigationCard
          task={task}
          toggleClaimed={toggleClaimed}
          userEmail={user.email}
          suiteAction={suiteAction}
          setShowNotesModal={setShowNotesModal}
          setNotesModalData={setNotesModalData}
          setEditingNotes={setEditingNotes}
          hasUserClaimedMaxTasks={hasUserClaimedMaxTasks}
        />
      );
    } else if (task.type === 'promotion') {
      return <PromotionTaskCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
    } else {
      return <GenericTaskCard task={task} toggleClaimed={toggleClaimed} user={user} suiteAction={suiteAction} />;
    }
  }

  function AddSuiteButton() {
    return (
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md border border-gray-600 px-2.5 py-1.5 text-xs font-semibold text-gray-600 shadow-sm h-8 hover:bg-gray-50 hover:text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        onClick={() => {
          setShowConfirmModal(true);
        }}
      >
        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Add Suite
      </button>
    );
  }

  return (
    <>
      <section aria-labelledby="section-1-title">
        <div className="flex justify-between items-center gap-x-4 bg-gray-50 p-6 rounded-t-lg shadow">
          <div className="text-sm font-medium leading-6 text-gray-900">{title}</div>
          {title === 'In Progress' && (
            <div className="flex flex-wrap justify-start items-center space-x-4">
              <AddSuiteButton />
              <ToggleButton labelText="My Tasks Only" enabled={showUserTasksOnly} setEnabled={setShowUserTasksOnly} />
            </div>
          )}
          {title === 'To Do' && (
            <div className="flex flex-wrap justify-start items-start space-x-4">
              {' '}
              {/* Adjusted for responsive wrapping and spacing */}
              <ToggleButton labelText="Investigation" enabled={showTriageTasks} setEnabled={setShowTriageTasks} />
              <ToggleButton labelText="Promotions" enabled={showPromotionTasks} setEnabled={setShowPromotionTasks} />
              <ToggleButton labelText="Messages" enabled={showMessageTasks} setEnabled={setShowMessageTasks} />
              <ToggleButton labelText="Generic" enabled={showGenericTasks} setEnabled={setShowGenericTasks} />
              <ToggleButton labelText="Main Team" enabled={primaryTeamOnly} setEnabled={setPrimaryTeamOnly} />
              <div className="pl-4 border-l border-gray-300" title="Shows Tasks assigned to you as well as unassigned tasks">
                <ToggleButton labelText="My Tasks Only" enabled={showUserTodosOnly} setEnabled={setShowUserTodosOnly} />
              </div>
            </div>
          )}
        </div>
        <div className="rounded-b-lg bg-white shadow pb-6">
          {tasks.length === 0 && (
            <div className="px-6 pt-4">
              <p className="text-gray-500">No active items in this column.</p>
            </div>
          )}

          {tasks.map((task) => (
            <div key={task.id} className="px-2 pt-2">
              {getCardComponent(task)}
            </div>
          ))}
        </div>
      </section>

      {/* Add Suite Modal */}
      <Transition appear show={showConfirmModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowConfirmModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Add a Suite
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Enter the Suite ID to add a new Investigation Task. The Suite ID is the ID that follows `/runs/` in the suite URL.
                    </p>
                    <p className="text-sm text-gray-500 mt-2">
                      You will need to use the "check" button in the menu to refresh failure counts and close this task.
                    </p>
                    <input
                      type="text"
                      value={newTaskSuiteId}
                      onChange={(e) => setNewTaskSuiteId(e.target.value)}
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder={'suiteId'}
                    />
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setShowConfirmModal(false);
                        setNewTaskSuiteId('');
                      }}
                    >
                      Cancel
                    </button>
                    <div>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => addSuiteMutation.mutate({ suiteId: newTaskSuiteId, claim: false })}
                        disabled={!newTaskSuiteId}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 ml-2 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={() => addSuiteMutation.mutate({ suiteId: newTaskSuiteId, claim: true })}
                        disabled={!newTaskSuiteId}
                      >
                        Add & Claim
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { formatTime } from '../timeUtlilzationHelpers';

import { UtilizationEvents } from '../timeUtilizationTypes';

export default function TotalActivityTable({ events }: { events: UtilizationEvents }) {
  const formattedData = Object.entries(events?.totalTimeInMsPerCustomer)
    .filter(([, value]) => value.durationInMs > 0)
    .map(([key, value]) => ({
      name: key,
      value: value.durationInMs,
      duration: value.duration,
    }));
  formattedData.sort((a, b) => b.value - a.value);

  return (
    <TableContainer
      component={Paper}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 0,
        m: 0,
        boxShadow: 'none',
        width: '30%',
      }}
    >
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: 'primary.main' }}>Customer</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: 'primary.main' }} align="right">
              Time Spent
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedData.map((row) => (
            <TableRow key={row.name}>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.duration}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ pl: 6, textAlign: 'left', fontWeight: 'bold', color: 'primary.main' }}>Total time spent</TableCell>
            <TableCell align="right">{formatTime(formattedData.reduce((acc, row) => acc + row.value, 0))}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

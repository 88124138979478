import { Box } from '@mui/material';
import { getAllJobSources, getAllSubmissions, getAllCandidates } from './HiringUtils/api';
import WolfLoader from '../WolfLoader/WolfLoader';
import { useQuery } from '@tanstack/react-query';

import Header from '../Layout/Header';
import Tabs from '../UtilityComponents/TabComponents/tabs';


import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import HiringOverview from './HiringOverview';
import { finalRoundStatusMap } from './HiringUtils/StatusConstants';

export default function HiringInternalDashboard() {
  // Sets the order seen on the dash
  const defaultTabs = [
    { type: "Candidate View", tableData: {}, current: true },
    { type: "Final Round View", tableData: {}, current: false },
    { type: "Metrics", tableData: {}, current: false },
    { type: "Archived", tableData: {}, current: false },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);
  const [tabs, setTabs] = useState(() => paramsMap.activeTab ? defaultTabs.map((tab) => ({ ...tab, current: tab.type === paramsMap.activeTab })) : defaultTabs);

  // handle init and update to search params
  useEffect(() => {
    // set active tab
    searchParams.set('activeTab', tabs.find((tab) => tab.current).type);

    // remove date params if not on metrics tab
    if (tabs.find((tab) => tab.current).type !== 'Metrics') {
      searchParams.delete('gte');
      searchParams.delete('lte');
      searchParams.delete('source');
    }

    setSearchParams(searchParams);
  }, [searchParams, tabs]);

  const fetchCombinedData = async () => {
    const [submission, jobSource, candidates] = await Promise.all([
      getAllSubmissions(),
      getAllJobSources(),
      getAllCandidates(),
    ]);
    return { submission, jobSource, candidates };  // Combine the data
  };

  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['combinedData'],
    queryFn: fetchCombinedData,
    select: (data) => {
      
      const activeSubmissions = data.submission;
      const jobSources = data.jobSource;
      const allCandidates = data.candidates;

      for (let i = 0; i < activeSubmissions.length; i++) {
        const sourceId = data.jobSource.find((item) => item.id === data.submission[i]["jobSourceId"]);
        activeSubmissions[i]["jobSourceName"] = sourceId.sourceName;
      }

      return { activeSubmissions, jobSources, allCandidates };
    },
    refetchInterval: 60 * 1000,
  });

  // Handle loading state
  if (isFetching && isLoading) return (<WolfLoader />);

  // Handle error state
  if (isError) {
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>{'Error loading client summary...'}</h1>
        <p>{error.message}</p>
      </div>
    );
  }
  // Formatting data
  const candidateData = {
    data: data.activeSubmissions.filter((submission) => !submission.isArchived && !finalRoundStatusMap.includes(submission.status)),
    allCandidates: data.allCandidates,
  };

  const finalRoundData = data.activeSubmissions.filter((submission) => finalRoundStatusMap.includes(submission.status) && !submission.isArchived);
  const jobSourcesFormatted = data.jobSources.filter((sources) => sources.activeStatus !== false); // removes any inactive job sources
  const metricsData = {
    candidates: data.activeSubmissions.filter((submission) => submission.dateSubmitted && submission.jobSourceId !== 9), // removes backDated candidates from metrics
    jobSources: jobSourcesFormatted.map((source) => source.sourceName),
  };
  const archivedData = data.activeSubmissions.filter((submission) => submission.isArchived);

  // Inputting data into tabs
  tabs.find((tab) => tab.type === 'Candidate View').tableData = candidateData;
  tabs.find((tab) => tab.type === 'Final Round View').tableData = finalRoundData;
  tabs.find((tab) => tab.type === 'Metrics').tableData = metricsData;
  tabs.find((tab) => tab.type === 'Archived').tableData = archivedData;

  return (
    <Box >
      {/* @ts-ignore */}
      <Header text={`${tabs.find((t) => t.current).type}`} />
      <Tabs tabs={tabs} setTabs={setTabs} activeTab={tabs.find((tab) => tab.current)} />
      <HiringOverview
        activeTab={tabs.find((tab) => tab.current)}
        data={tabs.find((tab) => tab.current).tableData}
      />
    </Box>
  );
}

import React from 'react';
import { useCommunications } from '../../context/CommunicationsContext';
import { MessageFeed } from './MessageFeed';
import { InternalResource } from '../data/types';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Badge } from '@/components/ui/badge';
//  TODO: Comment back in when feature is ready
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
// import { AddItemModal } from './AddItemModal';
import { CheckCircle, Circle, MessageSquare, GitBranch, Play, Bug, Link } from 'lucide-react';

interface ConversationDetailsProps {
  conversationId: number;
}

const CUSTOMER_LOGO = 'https://api.dicebear.com/6.x/initials/svg?seed=AC'; // Placeholder for Acme Corp logo

export const ConversationDetails: React.FC<ConversationDetailsProps> = ({ conversationId }) => {
  const { getConversation, getCustomerById } = useCommunications();
  const conversation = getConversation(Number(conversationId));
  const customer = getCustomerById(conversation?.customerId || 0);
  if (!conversation) {
    return <div className="flex-1 p-4">Conversation not found</div>;
  }

  // TODO: Comment back in when feature is ready
  // const [pinnedThread, setPinnedThread] = React.useState<string | null>(null);
  // const handlePinThread = React.useCallback((threadId: string | null) => {
  //   setPinnedThread((prevPinnedThread) => (prevPinnedThread === threadId ? null : threadId));
  // }, []);

  // const handleAddRelevantItem = () => {
  //   console.log('handleAddRelevantItem');
  // };

  // const togglePinThread = (threadId: string) => {
  //   handlePinThread(threadId === pinnedThread ? null : threadId);
  // };

  return (
    <div className="flex flex-1 h-screen overflow-x-auto">
      <div className="flex-1 flex flex-col">
        <div className="bg-white p-4 border-b border-gray-200 shadow-sm">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <img
                src={customer?.logo || CUSTOMER_LOGO}
                alt={customer?.officialName || 'Customer Logo'}
                width={40}
                height={40}
                className="rounded-full mr-3"
              />
              <h2 className="text-2xl font-bold text-gray-800">{customer?.officialName || 'Customer Name'}</h2>
            </div>
            <Badge variant={conversation.completed ? 'secondary' : 'outline'} className="text-sm px-2 py-1">
              {conversation.completed ? <CheckCircle className="w-4 h-4 mr-1" /> : <Circle className="w-4 h-4 mr-1" />}
              {conversation.completed ? 'Completed' : 'In Progress'}
            </Badge>
          </div>
          <p className="text-sm text-gray-600 mb-4">{conversation.summary}</p>
          <div className="flex flex-wrap gap-2">
            {/* {conversation.threads.map((thread: Thread) => (
              // TODO: Comment back in when feature is ready
              <TooltipProvider key={thread.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={`flex items-center space-x-1 px-2 py-1 rounded-full cursor-pointer transition-all duration-200 ${
                        pinnedThread === thread.id ? 'bg-gray-200 text-gray-800' : 'bg-gray-100 text-gray-600'
                      }`}
                      onClick={() => togglePinThread(thread.id)}
                      style={{ borderLeft: `4px solid ${thread.color}` }}
                    >
                      <span className="text-sm font-medium">{thread.id}</span>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="p-0 h-auto text-gray-500 hover:text-gray-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          togglePinThread(thread.id);
                        }}
                      >
                        <Pin className={`w-3 h-3 ${pinnedThread === thread.id ? 'fill-current text-gray-800' : ''}`} />
                      </Button>
                      {thread.completed && <CheckCircle className="w-3 h-3 text-green-500" />}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{thread.completed ? 'Completed' : 'In Progress'}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))} */}
          </div>
        </div>
        <div className="flex-1 overflow-hidden">
          {
            //  TODO: Comment back in when feature is ready
          }
          <MessageFeed conversation={conversation} /* onPinThread={handlePinThread} pinnedThread={pinnedThread} */ />
        </div>
      </div>
      <div className="w-96 border-l border-gray-200 bg-white overflow-hidden flex flex-col">
        <ScrollArea className="flex-1">
          <div className="p-4 space-y-6">
            <div className="flex items-center justify-between mb-4">{/* <AddItemModal onAddItem={handleAddRelevantItem} /> */}</div>
            <ResourceSection
              title="Related Conversations"
              icon={<MessageSquare className="w-4 h-4" />}
              resources={conversation.internalResources.filter((r) => r.type === 'conversation')}
            />
            <ResourceSection
              title="Related Workflows"
              icon={<GitBranch className="w-4 h-4" />}
              resources={conversation.internalResources.filter((r) => r.type === 'workflow')}
            />
            <ResourceSection
              title="Related Runs"
              icon={<Play className="w-4 h-4" />}
              resources={conversation.internalResources.filter((r) => r.type === 'run')}
            />
            <ResourceSection
              title="Related Bugs"
              icon={<Bug className="w-4 h-4" />}
              resources={conversation.internalResources.filter((r) => r.type === 'bug')}
            />
            <ResourceSection
              title="Other Resources"
              icon={<Link className="w-4 h-4" />}
              resources={conversation.internalResources.filter((r) => r.type === 'other')}
            />
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

type ResourceSectionProps = {
  title: string;
  icon: React.ReactNode;
  resources: InternalResource[];
};

function ResourceSection({ title, icon, resources }: ResourceSectionProps) {
  if (resources.length === 0) return null;

  return (
    <div>
      <h4 className="text-sm font-semibold mb-2 flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
      </h4>
      <div className="grid grid-cols-1 gap-2">
        {resources.map((resource) => (
          <Button key={resource.id} variant="outline" className="justify-start text-gray-700 hover:text-gray-900" asChild>
            <a href={resource.link} target="_blank" rel="noopener noreferrer">
              <span className="truncate">{resource.title}</span>
            </a>
          </Button>
        ))}
      </div>
    </div>
  );
}

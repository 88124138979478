import dayjs from 'dayjs';

export const getStartDateAndEndDate = (filterType, customDates) => {
    let startDate, endDate;
    const today = dayjs();

    switch (filterType) {
      case 'pastDue':
        startDate = null;
        endDate = today.subtract(1, 'day');
        break;
      case 'thisWeek':
        startDate = today.startOf('week');
        endDate = today.endOf('week');
        break;
      case 'nextWeek':
        startDate = today.add(1, 'week').startOf('week');
        endDate = today.add(1, 'week').endOf('week');
        break;
      case 'noDueAt':
        startDate = today;
        endDate = today;
        break;
      case 'custom':
        // Get dates from existing filter if available
        startDate = customDates[0] ? dayjs(customDates[0]) : null;
        endDate = customDates[1] ? dayjs(customDates[1]) : null;
        break;
      default:
        startDate = null;
        endDate = null;
    }
    return { startDate, endDate };
  };

export const nodeColors = {
  success: {
    background: '#22C55E',  // green-500
    border: '#86EFAC',      // green-300
  },
  mixed: {
    background: '#EAB308',  // yellow-500
    border: '#FDE047',      // yellow-300
  },
  failure: {
    background: '#EF4444',  // red-500
    border: '#FCA5A5',      // red-300
  },
  default: {
    background: '#4B5563',  // gray-600
    border: '#9CA3AF',      // gray-400
  },
  dimmed: {
    background: '#374151',  // gray-700
    border: '#6B7280',      // gray-500
  },
};

export const stylesheet = [
  {
    selector: 'node',
    style: {
      backgroundColor: nodeColors.default.background,
      label: 'data(label)',
      color: '#E5E7EB',
      textValign: 'center',
      textHalign: 'center',
      fontSize: '12px',
      padding: '10px',
      textWrap: 'wrap',
      textMaxWidth: '100px',
      shape: 'roundrectangle',
      width: 'label',
      height: 'label',
      borderWidth: '2px',
      borderColor: nodeColors.default.border,
    },
  },
  {
    selector: 'node.success',
    style: {
      backgroundColor: nodeColors.success.background,
      borderColor: nodeColors.success.border,
    },
  },
  {
    selector: 'node.mixed',
    style: {
      backgroundColor: nodeColors.mixed.background,
      borderColor: nodeColors.mixed.border,
    },
  },
  {
    selector: 'node.failure',
    style: {
      backgroundColor: nodeColors.failure.background,
      borderColor: nodeColors.failure.border,
    },
  },
  {
    selector: 'node.dimmed',
    style: {
      backgroundColor: nodeColors.dimmed.background,
      borderColor: nodeColors.dimmed.border,
      opacity: 0.5,
    },
  },
  {
    selector: 'edge',
    style: {
      width: 2,
      lineColor: '#4B5563',
      targetArrowColor: '#4B5563',
      targetArrowShape: 'triangle',
      curveStyle: 'bezier',
    },
  },
  {
    selector: '.path-highlighted',
    style: {
      lineColor: '#3B82F6',
      targetArrowColor: '#3B82F6',
      width: 3,
      transitionProperty: 'line-color, target-arrow-color, width',
      transitionDuration: '0.3s',
      zIndex: 999,
    },
  },
];


import FileNode from './FileNode';
import { FaFile } from 'react-icons/fa';

const MonacoFileExplorer = ({ files, onFileSelect, currentFile }) => {
  const isFileSelected = (file) => {
    return currentFile && currentFile.name === file.name;
  };

  return (
    <div
      style={{
        width: '25%',
        height: '100%',
        borderRight: '1px solid #ccc',
        padding: '10px',
        background: '#111',
        color: '#d4d4d4',
        overflowY: 'auto',
      }}
    >
      <h3>File Explorer</h3>
      <div >
        {/* Render Folders */}
        {files.folders &&
          Object.entries(files.folders).map(([folderName, folderNode]) => (
            folderName === "qa_wolf_take_home" ? 
              <FileNode
                key={folderName}
                node={folderNode}
                name={folderName}
                onFileSelect={onFileSelect}
                currentFile={currentFile}
                level={0}
                openOnDefault={true}
              /> :
              <FileNode
                key={folderName}
                node={folderNode}
                name={folderName}
                onFileSelect={onFileSelect}
                currentFile={currentFile}
                level={0}
                openOnDefault={false}
              />

          ))}

        {/* Render Files at Root Level */}
        {files.files && files.files.length > 0 && (
          <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
            {files.files.map((file) => (
              <li
                key={file.name}
                onClick={() => (
                  onFileSelect(file)
                )}
                style={{
                  cursor: 'pointer', marginBottom: '5px', display: 'flex', alignItems: 'center', backgroundColor: `${isFileSelected(file) ? "#404040" : "#111"} `,
                }}
              >
                <FaFile style={{ marginRight: '5px' }} />
                <span>{file.name}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div >
  );
};

export default MonacoFileExplorer;

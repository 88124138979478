import { useQueries, UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useEffect } from 'react';
import { sendGetRequest } from '../../utils/tanstackNetwork';
import { ClientSummaryTableRow } from '../Planning/Summary/types';
import { StoredUser } from '../HowlUtilization/types';
import QAWTask from '@/types';
import { CustomTableState } from '../shared/ColumnComponents/types';

export type StoredQaTeam = {
  id: number;
  name: string;
  qawId: string;
  email: string;
  teamName: string;
  packId: number;
  imageUrl: string;
  active: boolean;
  selectedInSettings: boolean;
};

export type CsmUser = {
  id: number;
  name: string;
  qawId: string;
  email: string;
  avatar48: string;
};

export type QaTeamCapacity = {
  totalQaeHours: number;
  oooHours: number;
  avgInvestigationHours: number;
  hoursAvailable: number;
  avgTestsCreatedOrMaintainedPerHour: number;
  avgMeetingHours: number;
  estCapacity: number;
  testsCreatedAndMaintainedThisWeek: number;
};

export type PlanningContextType = {
  user: StoredUser;
  qaTeams: StoredQaTeam[];
  selectedWeek: dayjs.Dayjs;
  setSelectedWeek: Dispatch<SetStateAction<dayjs.Dayjs>>;
  selectedQaTeamIds: number[];
  setSelectedQaTeamIds: Dispatch<SetStateAction<number[]>>;
  allTeamsSelected: boolean;
  setAllTeamsSelected: Dispatch<SetStateAction<boolean>>;
  qaTeamCapacity: UseQueryResult<QaTeamCapacity>;
  clientSummaries: UseQueryResult<ClientSummaryTableRow[]>;
  csms: UseQueryResult<CsmUser[]>;
  totalCreationScheduledForCurrentWeek: number;
  totalMaintenanceScheduledForCurrentWeek: number;
  remainingCapacity: number;
  showApproveAllButton: boolean;
  isMoreThanOneQaTeamSelected: boolean;
  selectedCsmQawId: string | null;
  setSelectedCsmQawId: Dispatch<SetStateAction<string | null>>;
  filterBy: 'qaTeam' | 'csm';
  setFilterBy: Dispatch<SetStateAction<'qaTeam' | 'csm'>>;
  openPlanningDrawerId: string | null;
  setOpenPlanningDrawerId: Dispatch<SetStateAction<string | null>>;
  tableState: CustomTableState<QAWTask> & { groupCRandMRs: boolean; includeChildTasks: { status?: boolean; dueDate?: boolean } };
  setTableState: Dispatch<SetStateAction<CustomTableState<QAWTask>>>;
  selectedView: 'testCoverageRequest' | 'testMaintenance' | 'blockers';
  setSelectedView: Dispatch<SetStateAction<'testCoverageRequest' | 'testMaintenance' | 'blockers'>>;
};

export const defaultTableState: Partial<
  CustomTableState<QAWTask> & { groupCRandMRs: boolean; includeChildTasks: { status?: boolean; dueDate?: boolean } }
> = {
  groupCRandMRs: true,
  globalFilterFn: 'contains',
  pagination: {
    pageIndex: 0,
    pageSize: 100,
  },
  rowSelection: {},
  columnFilters: [],
  currentColumnFilterType: {},
  includeChildTasks: {
    dueDate: true,
    status: true,
  },
  columnOrder: [
    'mrt-row-select',
    'customer',
    'type',
    'steps',
    'blockedTests',
    'name',
    'parentTask',
    'assigneeName',
    'dueDate',
    'status',
    'progress',
    'priority',
    'age',
    'notes',
    'workflowStatus',
    'childCount',
  ],
};

export const unblockedStatuses = [
  'inProgress',
  'needHelp',
  'bugged',
  'done',
  'draft',
  'changesRequested',
  'readyForReview',
  'toDo',
  'inReview',
  'scheduled',
];
export const incompleteStatuses = [
  'inProgress',
  'needHelp',
  'bugged',
  'draft',
  'changesRequested',
  'readyForReview',
  'toDo',
  'inReview',
  'scheduled',
  'bugged',
  'blocked',
];

const PlanningContext = createContext<PlanningContextType | object>({});

function getQueryParams(
  selectedQaTeamIds: number[],
  selectedWeek: string,
  selectedCsmQawId: string | null = null,
  filterBy: 'qaTeam' | 'csm' = 'qaTeam',
) {
  const params = new URLSearchParams();
  selectedQaTeamIds.forEach((id) => params.append('qaTeamIds', id.toString()));
  params.append('selectedWeek', selectedWeek);
  if (filterBy === 'csm' && selectedCsmQawId) {
    params.append('selectedCsmQawId', selectedCsmQawId);
  }
  return params.toString();
}

export function PlanningContextProvider({ children }: { children: ReactNode }) {
  const testing = window.location.href.startsWith('http://localhost');
  
  const searchParams = new URLSearchParams(window.location.search);
  const qaTeamIdFromUrl = Number(searchParams.get('qaTeamId'));

  const user = JSON.parse(localStorage.getItem('user') || '{}') as StoredUser;
  const qaTeams: StoredQaTeam[] = JSON.parse(localStorage.getItem('teams') || '[]').filter(
    (team: StoredQaTeam) => team.teamName !== 'Seals' && (testing || team.teamName !== 'TestTeam'),
  );
  const storedSelectedQaTeamIds: number[] = JSON.parse(localStorage.getItem('selectedQaTeamIds') || '[]');

  if (qaTeams.length === 0) {
    return <PlanningContext.Provider value={{}}>{children}</PlanningContext.Provider>;
  }

  const [selectedCsmQawId, setSelectedCsmQawId] = useState<string | null>(user.isCSM ? user.qawId : null);
  const [selectedQaTeamIds, setSelectedQaTeamIds] = useState(() => {
    // If the search params contain a qaTeamId, use it
    if (qaTeamIdFromUrl) {
      return [qaTeamIdFromUrl];
    }

    // If there are stored selected QA team IDs, use them, otherwise use the first QA team
    return storedSelectedQaTeamIds.length > 0 ? storedSelectedQaTeamIds : [qaTeams[0].id];
  });
  const [allTeamsSelected, setAllTeamsSelected] = useState(() => {
    // If the search params contain a qaTeamId, default to false
    if (qaTeamIdFromUrl) {
      return false;
    }

    // If there are stored selected QA team IDs compare them to the total number of QA teams, otherwise default to false
    return storedSelectedQaTeamIds.length > 0 ? storedSelectedQaTeamIds.length === qaTeams.length : false;
  });

  const [selectedWeek, setSelectedWeek] = useState(dayjs().startOf('week'));

  const [showApproveAllButton, setShowApproveAllButton] = useState(false);
  const [isMoreThanOneQaTeamSelected, setIsMoreThanOneQaTeamSelected] = useState(false);
  const [filterBy, setFilterBy] = useState<'qaTeam' | 'csm'>(user.isCSM ? 'csm' : 'qaTeam');
  const [openPlanningDrawerId, setOpenPlanningDrawerId] = useState<string | null>(null);
  const [tableState, setTableState] = useState<CustomTableState<QAWTask>>(defaultTableState as CustomTableState<QAWTask>);
  const [selectedView, setSelectedView] = useState<'testCoverageRequest' | 'testMaintenance' | 'blockers'>('testCoverageRequest');

  const [qaTeamCapacity, clientSummaries, csms] = useQueries({
    queries: [
      {
        queryKey: ['qaTeamCapacity', selectedWeek.format('YYYY-MM-DD'), selectedQaTeamIds],
        queryFn: () => sendGetRequest(`/client-summaries/qa-team-capacity?${getQueryParams(selectedQaTeamIds, selectedWeek.format('YYYY-MM-DD'))}`),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['clientSummaries', selectedWeek.format('YYYY-MM-DD'), selectedQaTeamIds, selectedCsmQawId, filterBy],
        queryFn: () =>
          sendGetRequest(`/client-summaries?${getQueryParams(selectedQaTeamIds, selectedWeek.format('YYYY-MM-DD'), selectedCsmQawId, filterBy)}`),
        placeholderData: [],
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['csms'],
        queryFn: () => sendGetRequest('/client-summaries/csms'),
        refetchOnWindowFocus: false,
      },
    ],
  });

  useEffect(() => {
    // Only show the approve all button if the user is the manager of all selected QA teams
    const selectedQaTeams = qaTeams.filter((team) => selectedQaTeamIds.includes(team.id));
    const isQaTeamManager = user.isManager && selectedQaTeams.every((team) => team.packId === user.teamId);

    // Disable the approva all button if more than one QA team is selected
    const isMoreThanOneTeamSelected = selectedQaTeamIds.length > 1;

    // When the selected QA team changes, update the showApproveAllButton state
    setShowApproveAllButton(isQaTeamManager);
    setIsMoreThanOneQaTeamSelected(isMoreThanOneTeamSelected);

    // When CSMs are fetched, update the selectedCsmQawId state
    if (csms.data) {
      setSelectedCsmQawId((prev) => (prev ? prev : csms.data[0].qawId));
    }
  }, [selectedQaTeamIds, selectedWeek, csms.data]);

  const capacityData = qaTeamCapacity.data;
  const clientSummaryData = clientSummaries.data;

  const totalCreationScheduledForCurrentWeek =
    clientSummaryData?.reduce((acc: number, curr: ClientSummaryTableRow) => acc + curr.creation.scheduledTestCount, 0) || 0;
  const totalMaintenanceScheduledForCurrentWeek =
    clientSummaryData?.reduce((acc: number, curr: ClientSummaryTableRow) => acc + curr.maintenance.scheduledTestCount, 0) || 0;
  const remainingCapacity =
    (capacityData?.estCapacity || 0) - (totalCreationScheduledForCurrentWeek || 0) - (totalMaintenanceScheduledForCurrentWeek || 0);

  return (
    <PlanningContext.Provider
      value={{
        user,
        qaTeams,
        selectedWeek,
        setSelectedWeek,
        selectedQaTeamIds,
        setSelectedQaTeamIds,
        allTeamsSelected,
        setAllTeamsSelected,
        qaTeamCapacity,
        clientSummaries,
        csms,
        totalCreationScheduledForCurrentWeek,
        totalMaintenanceScheduledForCurrentWeek,
        remainingCapacity,
        showApproveAllButton,
        isMoreThanOneQaTeamSelected,
        selectedCsmQawId,
        setSelectedCsmQawId,
        filterBy,
        setFilterBy,
        openPlanningDrawerId,
        setOpenPlanningDrawerId,
        tableState,
        setTableState,
        selectedView,
        setSelectedView,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
}

export function usePlanningContext() {
  return useContext(PlanningContext) as PlanningContextType;
}

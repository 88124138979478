import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';

type AvatarGroupProps = {
  participants: Array<{ id: string; name: string; avatar: string }>;
  max?: number;
};

export function AvatarGroup({ participants, max = 3 }: AvatarGroupProps) {
  const visibleParticipants = participants.slice(0, max);
  const remainingCount = participants.length - max;

  return (
    <div className="flex -space-x-3">
      {visibleParticipants.map((participant) => (
        <TooltipProvider key={participant.id}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Avatar className="inline-block border-2 border-background w-6 h-6">
                <AvatarImage src={participant.avatar} alt={participant.name} />
                <AvatarFallback>{participant.name.charAt(0)}</AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent>
              <p>{participant.name}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
      {remainingCount > 0 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Avatar className="inline-block border-2 border-background w-6 h-6 bg-gray-300">
                <AvatarFallback>+{remainingCount}</AvatarFallback>
              </Avatar>
            </TooltipTrigger>
            <TooltipContent>
              <p>{remainingCount} more participant(s)</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
}

import { useState, useCallback } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import CandidateDetailPanel from '../HiringCandidatePanel/CandidateDetailPanel';
import FinalRoundDetailPanel from '../FinalRoundView/FinalRoundDetailPanel';

const RenderFinalRoundDetailPanels = ({ row, data }) => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = useCallback((event, newValue) => {
        setTabValue(newValue);
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="detail panel tabs"
                variant="fullWidth"
            >
                <Tab label="Final Round Info" />
                <Tab label="Editor" />
                <Tab label="Extra Info" />
            </Tabs>
            {tabValue === 0 && (
                <Box value={tabValue} index={0}>
                    <FinalRoundDetailPanel row={row} data={data} />
                </Box>
            )}
            {tabValue === 1 && (
                <Box value={tabValue} index={1}>
                    <CandidateDetailPanel
                        row={row}
                        data={data}
                        idx={0}
                        showTab={false}
                        height={"100%"}
                    />
                </Box>
            )}
            {tabValue === 2 && (
                <Box value={tabValue} index={2}>
                    <CandidateDetailPanel
                        row={row}
                        data={data}
                        idx={1}
                        showTab={false}
                        height={"100%"}
                    />
                </Box>
            )}
        </Box>
    );
};

export default RenderFinalRoundDetailPanels;

import { useEffect, useState, useRef } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '../../ui/button';
import { Command, CommandEmpty, CommandList, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Conversation, Customer } from '../data/types';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tooltip, TooltipTrigger, TooltipProvider, TooltipContent } from '@/components/ui/tooltip';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { useCommunications } from '../../context/CommunicationsContext';
import { SavedViewState } from '../data/types';

interface CustomerDropdownProps {
  onSelectCustomer: (customerId: number) => void;
  selectedCustomerId: number | null;
  customers: Customer[];
  conversations: Conversation[];
}

export function CustomerDropdown({ onSelectCustomer, selectedCustomerId, conversations, customers }: CustomerDropdownProps) {
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const preventTooltipRef = useRef(false);
  const { selectAllCustomers, setSelectAllCustomers, setSelectedCustomerId, selectedQaTeamId } = useCommunications();

  useEffect(() => {
    if (preventTooltipRef.current) {
      const timer = setTimeout(() => {
        preventTooltipRef.current = false;
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [selectedCustomerId]);

  const savedCustomerId = localStorage.getItem('selectedCustomerId');
  const selectedCustomer = customers?.find((customer) => customer.id === Number(selectedCustomerId));
  const locallySavedCustomer = customers?.find((customer) => String(customer.id) === savedCustomerId);
  const currentCustomer = selectAllCustomers ? null : selectedCustomer || locallySavedCustomer || customers[0];

  const savedViewState: SavedViewState | null = localStorage.getItem('savedViewState')
    ? JSON.parse(localStorage.getItem('savedViewState') as string)
    : null;

  const handleAllCustomersSelect = () => {
    preventTooltipRef.current = true;
    setTooltipOpen(false);
    setSelectedCustomerId(null);
    setSelectAllCustomers(true);
    setOpen(false);
    if (selectedQaTeamId && savedViewState) {
      localStorage.setItem(
        'savedViewState',
        JSON.stringify({
          ...savedViewState,
          customerId: {
            ...savedViewState.customerId,
            [selectedQaTeamId]: {
              selectAllCustomers: true,
              customerId: null,
            },
          },
        }),
      );
    }
  };

  const handleCustomerSelect = (customerId: number) => {
    preventTooltipRef.current = true;
    setTooltipOpen(false);
    setSelectAllCustomers(false);
    onSelectCustomer(customerId);
    setOpen(false);

    if (selectedQaTeamId && savedViewState) {
      localStorage.setItem(
        'savedViewState',
        JSON.stringify({
          ...savedViewState,
          customerId: {
            ...savedViewState.customerId,
            [selectedQaTeamId]: {
              selectAllCustomers: false,
              customerId: customerId,
            },
          },
        }),
      );
    }
  };

  return (
    <TooltipProvider>
      <Tooltip
        open={tooltipOpen && !preventTooltipRef.current}
        onOpenChange={(open) => {
          if (!preventTooltipRef.current) {
            setTooltipOpen(open);
          }
        }}
      >
        <Popover open={open} onOpenChange={setOpen}>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full overflow-x-auto justify-between cursor-pointer h-14"
                onClick={() => setTooltipOpen(false)}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center min-w-0">
                    <Avatar className="w-6 h-6 flex-shrink-0">
                      <AvatarImage
                        src={selectAllCustomers ? '' : selectedCustomer?.logo || ''}
                        alt={selectAllCustomers ? '' : selectedCustomer?.officialName || ''}
                      />
                      <AvatarFallback>{selectAllCustomers ? '' : selectedCustomer?.officialName || ''}</AvatarFallback>
                    </Avatar>
                    <div className="ml-2 truncate">{selectAllCustomers ? 'All Customers' : currentCustomer?.officialName}</div>
                  </div>
                  <div className="flex items-center gap-2 ml-2">
                    {selectAllCustomers ? (
                      <>
                        {conversations.filter((conv) => !conv.completed).length ? (
                          <Badge variant="destructive">{conversations.filter((conv) => !conv.completed).length}</Badge>
                        ) : null}
                      </>
                    ) : currentCustomer ? (
                      <>
                        {conversations.filter((conv) => conv.customerId === currentCustomer.id && !conv.completed).length ? (
                          <Badge variant="destructive">
                            {conversations.filter((conv) => conv.customerId === currentCustomer.id && !conv.completed).length}
                          </Badge>
                        ) : null}
                      </>
                    ) : null}
                    <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                  </div>
                </div>
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent side="top" align="start">
            <p>{currentCustomer?.officialName}</p>
          </TooltipContent>
          <ScrollArea className="h-full">
            <PopoverContent className="w-full p-0">
              <Command>
                <CommandInput placeholder="Search customers..." />
                <CommandEmpty>No customer found.</CommandEmpty>

                <CommandList className="max-h-[75vh] min-w-full w-72 overflow-y-auto">
                  <CommandItem className="min-w-full w-full p-0 cursor-pointer h-11" onSelect={handleAllCustomersSelect}>
                    <Check className={cn('mr-2 h-4 w-4', selectAllCustomers ? 'opacity-100' : 'opacity-0')} />
                    <span>All Customers</span>
                  </CommandItem>
                  {customers?.map((customer: Customer) => (
                    <CommandItem
                      className="min-w-full w-full p-0 cursor-pointer h-11"
                      key={customer?.id}
                      onSelect={() => handleCustomerSelect(customer?.id)}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          selectAllCustomers ? 'opacity-0' : selectedCustomerId === customer.id ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                      <Avatar className="w-6 h-6 flex-shrink-0">
                        <AvatarImage src={customer?.logo || 'https://stock.adobe.com/search?k=default+avatar'} alt={customer?.officialName || ''} />
                        <AvatarFallback>{customer?.officialName.charAt(0)}</AvatarFallback>
                      </Avatar>
                      {customer?.officialName}
                      <>
                        {conversations.filter((conv) => conv.customerId === customer.id && !conv.completed).length ? (
                          <Badge className="ml-auto mr-2" variant="destructive">
                            {conversations.filter((conv) => conv.customerId === customer.id && !conv.completed).length}
                          </Badge>
                        ) : (
                          <Badge className="ml-auto mr-2" variant="outline">
                            0
                          </Badge>
                        )}
                      </>
                    </CommandItem>
                  ))}
                </CommandList>
              </Command>
            </PopoverContent>
          </ScrollArea>
        </Popover>
      </Tooltip>
    </TooltipProvider>
  );
}

import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { CustomTooltip } from '../HiringUtils/helperFunctions';
import dayjs from 'dayjs';

export default function FinalRoundLevelBarChart({ data, filters }) {
    // Define valid levels
    const validLevels = ['Passed', 'Close', 'Ran out of Time', 'Waste of Time'];

    // Initialize an empty object to group counts by level and source
    const groupedData = {};

    // Initialize array to count the total number of interviews
    let total = [];

    // Initialize an object to track total counts per job source
    const totalCountsBySource = {};

    // Process data to calculate counts per level and source
    data.candidates.forEach((submission) => {
        if (
            dayjs(submission.finalRoundDate).isValid() &&
            dayjs(filters.gte).isValid() &&
            dayjs(filters.lte).isValid() &&
            dayjs(submission.finalRoundDate).isBetween(dayjs(filters.gte), dayjs(filters.lte), "day", "[]") &&
            validLevels.includes(submission.level)
        ) {
            // Keep track of the total number of submissions
            total.push(submission);

            // Ensure the level exists in the groupedData
            if (!groupedData[submission.level]) {
                groupedData[submission.level] = { level: submission.level };
            }

            // Increment the count for the specific level and source
            groupedData[submission.level][submission.jobSourceName] =
                (groupedData[submission.level][submission.jobSourceName] || 0) + 1;

            // Increment the total count for the source
            totalCountsBySource[submission.jobSourceName] =
                (totalCountsBySource[submission.jobSourceName] || 0) + 1;
        }
    });

    // Convert the grouped data object into an array
    const formattedData = Object.values(groupedData);

    // Get all unique sources to ensure all levels have consistent keys
    const allSources = [
        ...new Set(data.candidates.map((submission) => submission.jobSourceName)),
    ];

    // Ensure every level has a count for each source, even if it's 0
    formattedData.forEach((entry) => {
        allSources.forEach((source) => {
            if (entry[source] === undefined) {
                entry[source] = 0;
            }
        });
    });

    // Add missing levels to formattedData with zero counts for all sources
    validLevels.forEach((level) => {
        if (!formattedData.some((entry) => entry.level === level)) {
            const newEntry = { level };
            allSources.forEach((source) => {
                newEntry[source] = 0; // Set count to 0 for all sources
            });
            formattedData.push(newEntry);
        }
    });

    // Add total counts for each source
    const totalCountsEntry = { level: 'Total' };
    allSources.forEach((source) => {
        totalCountsEntry[source] = totalCountsBySource[source] || 0; // Use 0 if no submissions for this source
    });

    return (
        <div>
            <div style={{ width: '100%', height: '300px', margin: '20px 0px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={1000}
                        height={300}
                        data={formattedData}
                        margin={{
                            right: 10,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis
                            dataKey="level"
                            tick={{
                                fontSize: 12,
                                width: 20,
                            }}
                            tickFormatter={(label) => label === "Total" ? "" : label}
                            interval={0} // Show all labels
                        />
                        <YAxis
                            domain={[0, 'dataMax']}
                            label={{ value: 'Total Count', angle: -90, position: 'insideLeft' }}
                            allowDecimals={false}
                        />
                        <Tooltip
                            content={<CustomTooltip filters={filters} finalRound={true} totalCount={filters.source === "All" ? total.length : totalCountsBySource[filters.source]} />}
                        />
                        {(filters.source === "All" ? allSources : [filters.source]).map((source) => (
                            <Bar
                                key={source}
                                dataKey={source}
                                stackId="submissions"
                                fill={hslStringToRgbString(stringToColor(source))}
                                opacity={0.65}
                            />
                        ))}
                        <Legend
                            payload={[
                                { value: `Totals Based on Final Round Levels`, color: hslStringToRgbString(stringToColor(filters.source)) },
                            ]}
                            verticalAlign="top"
                            height={32}
                            iconType='circle'
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

import { UtilizationView } from './views';
import { QaeUtilizationFilter, QaeUtilizationTab, TeamUtilizationFilter, TeamUtilizationTab } from './timeUtilizationTypes';

interface TimeUtilizationBoardProps {
  activeTab: QaeUtilizationTab | TeamUtilizationTab;
  filters: {
    team: TeamUtilizationFilter;
    qae: QaeUtilizationFilter;
  };
}

export default function TimeUtilizationBoard({ activeTab, filters }: TimeUtilizationBoardProps) {
  const viewMap = {
    'Team Investigation': <UtilizationView filters={filters.team} />,
    'QAE Investigation': <UtilizationView filters={filters.qae} />,
  };

  return (
    <div className="min-h-full py-4">
      <div>{viewMap[activeTab.type]}</div>
    </div>
  );
}

import { classNames } from '../../InvestigationBoard/helpers.jsx';

import {
  computeTeamInsightsForCreationAndOutliningCompTable,
  computeQAEInsightsForCreationAndOutliningCompTable,
  computeComparisonInsightsForCreationAndOutliningCompTable,
  determineColorForCreationAndOutliningCompTable,
} from './tableHelpers';

import { sumAllCreationOutliningMaintenanceTasks } from '../helpers';

function WeeklyTeamCreationTable({ creationInsights, teamSums }) {
  const CATEGORIES = [
    'Workflows Created',
    'Tests Created',
    'Average Tests Created Per Week',
    'Median Tests Created Per Week',
    'Outlining Tasks Completed',
    'Max Tests Per Week',
    'Min Tests Per Week',
  ];

  const computedTeamInsights = computeTeamInsightsForCreationAndOutliningCompTable(creationInsights, teamSums);

  const qaeData = creationInsights.team.members
    .filter((member) => !member.isManager)
    .sort((a, b) => {
      if (a.isLead) return 1;
      if (b.isLead) return -1;
      return a.name.localeCompare(b.name);
    })
    .map((qae) => {
      const qaeTasksByWeekAndType = creationInsights.teamTasksByWeekAndType.filter((x) => x.completed_by_qaw_id === qae.qawId);
      const qaeSums = sumAllCreationOutliningMaintenanceTasks(qaeTasksByWeekAndType);
      const qaeInsights = computeQAEInsightsForCreationAndOutliningCompTable({ qae, qaeTasksByWeekAndType }, qaeSums);
      const comparisonInsights = computeComparisonInsightsForCreationAndOutliningCompTable(qaeInsights, computedTeamInsights);

      return {
        qae,
        qaeInsights,
        comparisonInsights,
      };
    });

  return (
    <div className="overflow-x-auto border border-gray-600 shadow-sm">
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            <th className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-100 min-w-[180px] border-b border-gray-400"></th>
            <th className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-900 bg-gray-100 min-w-[110px] border-b border-gray-400"></th>
            {qaeData.map((data, index) => (
              <th
                key={`qae-metric-${index}`}
                colSpan={2}
                className="px-4 py-2 text-center text-sm font-medium text-gray-900 bg-gray-100 border-b border-l border-gray-400"
              >
                <div className="flex items-center justify-center space-x-2">
                  <img src={data.qae.avatar48} alt={`${data.qae.name}'s avatar`} className="h-6 w-6 rounded-full" />
                  <span>{data.qae.name.split(' ')[0]}</span>
                </div>
              </th>
            ))}
          </tr>
          <tr>
            <th className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-200 border-b border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
              Metric
            </th>
            <th className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
              Team Total
            </th>
            {qaeData.map((_, index) => (
              <>
                <th
                  key={`metric-header-${index}`}
                  className="px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400"
                >
                  Metric
                </th>
                <th
                  key={`comparison-header-${index}`}
                  className="px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400"
                >
                  Comparison
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {CATEGORIES.map((category, categoryIndex) => (
            <tr
              key={categoryIndex}
              className={classNames(
                'divide-x divide-x-gray-200 border-b border-b-gray-200 hover:border-t-2 hover:border-b-2 hover:border-gray-400 hover:shadow-lg',
                category === 'Median Tests Created Per Week' && 'border-b-2 border-b-gray-400',
              )}
            >
              <td className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-800 min-w-[240px] bg-white outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                {category}
              </td>
              <td className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-900 border-l border-gray-400 bg-white outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                {computedTeamInsights[category] || 0}
              </td>
              {qaeData.map((data, colIndex) => (
                <>
                  <td
                    key={`metric-${categoryIndex}-${colIndex}`}
                    className={classNames(
                      'px-4 py-2 text-center text-sm border-l border-gray-400',
                      [4, 5, 6].includes(categoryIndex)
                        ? ''
                        : determineColorForCreationAndOutliningCompTable(
                            category,
                            data.comparisonInsights[category],
                            data.qae,
                            creationInsights.team,
                            teamSums,
                          ),
                    )}
                  >
                    {data.qaeInsights[category] || 0}
                  </td>
                  <td
                    key={`comparison-${categoryIndex}-${colIndex}`}
                    className={classNames(
                      'px-4 py-2 text-center text-sm',
                      [4, 5, 6].includes(categoryIndex)
                        ? ''
                        : determineColorForCreationAndOutliningCompTable(
                            category,
                            data.comparisonInsights[category],
                            data.qae,
                            creationInsights.team,
                            teamSums,
                          ),
                    )}
                  >
                    {data.comparisonInsights[category] || 0}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WeeklyTeamCreationTable;

import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Header from '../Layout/Header';
import { TeamUtilizationFilters, QaeUtilizationFilters } from './filters';
import TimeUtilizationBoard from './TimeUtilizationBoard';
import { Tabs } from '../UtilityComponents';
import { TeamUtilizationTab, QaeUtilizationTab, TeamUtilizationFilter, QaeUtilizationFilter } from './timeUtilizationTypes';

export default function TimeUtilization() {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  const defaultTabs: (QaeUtilizationTab | TeamUtilizationTab)[] = [
    { type: 'Team Investigation', current: true },
    { type: 'QAE Investigation', current: false },
  ];

  const defaultTeamFilter: TeamUtilizationFilter = {
    entity: {
      type: 'team',
      data: {
        name: paramsMap.teamName ?? 'Hammerheads',
      },
    },
    gte: paramsMap.gte ? dayjs(paramsMap.gte).format('YYYY-MM-DD') : dayjs().startOf('hour').subtract(1, 'day').format('YYYY-MM-DD'),
    lte: paramsMap.lte ? dayjs(paramsMap.lte).format('YYYY-MM-DD') : dayjs().startOf('hour').format('YYYY-MM-DD'),
  };

  const defaultQaeFilter: QaeUtilizationFilter = {
    entity: {
      type: 'qae',
      data: {
        id: paramsMap.qae ?? 'clqwg72cw0b7d46018vyxonn8',
      },
    },
    gte: paramsMap.gte ? dayjs(paramsMap.gte).format('YYYY-MM-DD') : dayjs().startOf('hour').subtract(1, 'day').format('YYYY-MM-DD'),
    lte: paramsMap.lte ? dayjs(paramsMap.lte).format('YYYY-MM-DD') : dayjs().startOf('hour').format('YYYY-MM-DD'),
  };

  const [appliedFilters, setAppliedFilters] = useState<{ team: TeamUtilizationFilter; qae: QaeUtilizationFilter }>({
    team: defaultTeamFilter,
    qae: defaultQaeFilter,
  });

  const [tabs, setTabs] = useState(paramsMap.activeTab ? defaultTabs.map((t) => ({ ...t, current: t.type === paramsMap.activeTab })) : defaultTabs);

  const activeTab = tabs.find((t) => t.current);

  // Consolidated useEffect for updating search params
  useEffect(() => {
    const currentTab = tabs.find((t) => t.current)?.type;
    const newSearchParams = new URLSearchParams(searchParams);

    // Update the active tab in search params
    newSearchParams.set('activeTab', currentTab ?? '');

    // Determine which filters to use based on the active tab
    const currentFilters = currentTab === 'Team Investigation' ? appliedFilters.team : appliedFilters.qae;

    // Update and clear irrelevant params
    if (currentTab === 'Team Investigation') {
      const teamData = currentFilters.entity?.data;
      if ('name' in teamData) {
        newSearchParams.set('teamName', teamData.name);
      } else {
        newSearchParams.delete('teamName');
      }
      newSearchParams.delete('qae'); // Remove QAE param since we're on Team tab
    }

    if (currentTab === 'QAE Investigation') {
      const qaeData = currentFilters.entity?.data;
      if ('id' in qaeData) {
        newSearchParams.set('qae', qaeData.id);
      } else {
        newSearchParams.delete('qae');
      }
      newSearchParams.delete('teamName'); // Remove Team param since we're on QAE tab
    }

    // Handle date ranges
    newSearchParams.set('gte', currentFilters.gte);
    newSearchParams.set('lte', currentFilters.lte);

    // Set all updated params at once
    setSearchParams(newSearchParams);
  }, [tabs, appliedFilters, setSearchParams]);

  return (
    <div className="min-h-full px-4">
      <Header text="Time Utilization" />
      {activeTab?.type === 'Team Investigation' && <TeamUtilizationFilters filters={appliedFilters.team} setFilters={setAppliedFilters} />}
      {activeTab?.type === 'QAE Investigation' && <QaeUtilizationFilters filters={appliedFilters.qae} setFilters={setAppliedFilters} />}
      <Tabs tabs={tabs} setTabs={setTabs} activeTab={activeTab ?? { type: 'Team Investigation', current: true }} />
      <TimeUtilizationBoard activeTab={activeTab ?? { type: 'Team Investigation', current: true }} filters={appliedFilters} />
    </div>
  );
}

import FinalRoundTable from './FinalRoundView/FinalRoundTable';
import HiringTable from './CandidateView/CandidateSubmissionTable';
import ArchiveTable from './ArchivedView/ArchiveTable';
import MetricsView from './Metrics/MetricsView';

function HiringOverview({ activeTab, data }) {
  const viewMap = {
    'Candidate View': <HiringTable data={data} />,
    'Final Round View': <FinalRoundTable data={data} />,
    'Metrics': <MetricsView data={data} />,
    'Archived': <ArchiveTable data={data} />,
  };
  return <div>{viewMap[activeTab.type]}</div>;
}

export default HiringOverview;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAllTasks } from './api';
import TeamSelector from '../Layout/TeamSelector';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TasksTable from '../shared/TasksTable';
import { sendPostRequest, sendPutRequest } from '../../utils/network';
import { Button } from '@mui/material';
import SavedViews from './SavedViews';
import createNewTask from './CreateNewTask';
import CreateNewTaskModal from './CreateNewTaskModal';
import SaveViewModal from './SaveViewModal';
import Toast from '../Layout/Toast';
import { deepEqual, flattenTaskData } from './helpers';
import dayjs from 'dayjs';
import { getStartDateAndEndDate } from '../shared/ColumnComponents/helpersForDueDates';

export default function Tasks() {
  const userQawId = localStorage.getItem('userId');
  const [enabledTeams, setEnabledTeams] = useState([]);
  const [toastData, setToastData] = useState(null);
  const defaultTableState = {
    columnFilters: [],
    columnFilterFn: {},
    includeChildTasks: {},
    columnOrder: [],
    columnPinning: {
      left: ['mrt-row-expand', 'mrt-row-select'],
    },
    columnSizing: {},
    currentColumnFilterType: {},
    columnVisibility: {
      blockedTests: false,
    },
    globalFilter: '',
    globalFilterFn: ['contains'],
    groupCRandMRs: false,
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    rowSelection: {},
    sorting: [],
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [selectedView, setSelectedView] = useState(0);
  const [draftName, setDraftName] = useState('');
  const [openViewNameModal, setOpenViewNameModal] = useState(false);

  // React Router hooks
  const location = useLocation();
  const navigate = useNavigate(); // For React Router v6

  // Ref to prevent looping
  const isInitialLoad = useRef(true);

  // Update URL search parameters
  useEffect(() => {
    // Do not set search params on initial load
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    const searchParams = new URLSearchParams(location.search);

    Object.entries(tableState).forEach(([key, value]) => {
      if (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim() === '') ||
        deepEqual(value, defaultTableState[key])
      ) {
        searchParams.delete(key); // Delete key if the value is empty
      } else {
        if (typeof value === 'object') {
          searchParams.set(key, JSON.stringify(value));
        } else {
          searchParams.set(key, value.toString());
        }
      }
    });

    // Handle 'selectedView' separately
    if (selectedView) {
      searchParams.set('selectedView', JSON.stringify(selectedView));
    } else {
      searchParams.delete('selectedView');
    }

    // Handle enabledTeams separately
    if (enabledTeams && enabledTeams.length) {
      let teamIds = enabledTeams.map((team) => team.id);

      // Set local storage
      let teamsInStorage = JSON.parse(localStorage.getItem('teams'));
      let updatedTeamsInStorage = teamsInStorage.map((team) => {
        return {
          ...team,
          active: teamIds.includes(team.id),
        };
      });
      localStorage.setItem('teams', JSON.stringify(updatedTeamsInStorage));

      // Set search params
      searchParams.set('enabledTeams', JSON.stringify(teamIds));
    } else {
      searchParams.delete('enabledTeams');
    }

    // Update the URL with the new search params
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    );
  }, [tableState, selectedView, enabledTeams]);

  // Set table state based on URL on initial load
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const newTableState = { ...defaultTableState };

    searchParams.forEach((value, key) => {
      try {
        // Handle 'selectedView' separately from tableState
        if (key === 'selectedView') {
          setSelectedView(JSON.parse(value));
        }
        // Handle 'enabledTeams" separately from tableState
        else if (key === 'enabledTeams') {
          let teamsInStorage = JSON.parse(localStorage.getItem('teams'));
          let teamIDsFromParams = JSON.parse(value);

          // Set local storage
          let updatedTeamsInStorage = teamsInStorage.map((team) => {
            return {
              ...team,
              active: teamIDsFromParams.includes(team.id),
            };
          });
          localStorage.setItem('teams', JSON.stringify(updatedTeamsInStorage));

          // Set enabledTeams from search params
          let teamsFromParams = teamsInStorage.filter((team) => teamIDsFromParams.includes(team.id));
          setEnabledTeams(teamsFromParams);
        }
        // Handle tableState
        else {
          // Parse all values except globalFilter
          const parsedValue = key === 'globalFilter' ? value : JSON.parse(value);
          newTableState[key] = parsedValue;
        }
      } catch (error) {
        console.error(`Failed to parse ${key}:`, error);
        if (key !== 'selectedView' && key in newTableState) {
          newTableState[key] = value; // Assign raw value if JSON parsing fails
        }
      }
    });

    // If there is a dueDate filter, update the dates in columnFilters
    if (newTableState.currentColumnFilterType.dueDate) {
      // Filter out dueDate filter from columnFilters
      const newFilters = newTableState.columnFilters.filter((f) => f.id !== 'dueDate');

      // Get start and end dates for the filter type
      const { startDate, endDate } = getStartDateAndEndDate(
        newTableState.currentColumnFilterType.dueDate,
        newTableState.columnFilters.find((f) => f.id === 'dueDate')?.value, // Provide the value of the dueDate filter incase of custom date range
      );

      // Only add dueDate filter if at least one date is not null
      if (startDate !== null || endDate !== null) {
        newFilters.push({
          id: 'dueDate',
          value: [startDate, endDate],
        });
      }

      // Add dates to columnFilters
      newTableState.columnFilters = newFilters;
    } else {
      // Update dueDate columnFilter so that dates are either undefined or use dayjs
      let dueDateIndex = newTableState.columnFilters?.findIndex((filter) => filter.id === 'dueDate');
      if (dueDateIndex >= 0) {
        // Update date to a dayjs object, or to undefined if it's falsy
        newTableState.columnFilters[dueDateIndex].value = newTableState.columnFilters[dueDateIndex].value.map((date) =>
          !date ? (date = undefined) : (date = dayjs(date)),
        );
      }
    }

    // Apply the new state if any search parameters are found
    if (deepEqual(newTableState, tableState)) {
      setTableState(tableState);
    } else {
      setTableState(newTableState);
    }
    isInitialLoad.current = true; // Set this after initial load
  }, []);

  const clearSelectedView = () => {
    setSelectedView(0);
  };

  const queryKey = ['allTasks'];
  const { data, isPlaceholderData, isFetching, isError, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getAllTasks(),
    select: (data) => flattenTaskData(data, tableState.groupCRandMRs),
    placeholderData: [],
    refetchInterval: 60 * 1000,
  });

  const queryClient = useQueryClient();

  const saveView = useMutation({
    mutationFn: async () => {
      const savedViewState = { ...tableState };
      (savedViewState.pagination.pageIndex = 0), delete savedViewState.rowSelection;
      Object.entries(savedViewState).forEach(([key, value]) => {
        if (deepEqual(value, defaultTableState[key])) {
          delete savedViewState[key];
        }
      });
      return await sendPostRequest('/save-table-view', { userQawId, name: draftName, state: savedViewState, tableName: 'taskTable' });
    },
    onError: (error) => {
      setToastData({
        title: 'Saving View Failed',
        message: error.message || 'An error occurred while saving the view. Please try again.',
        isSuccess: false,
      });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({
        queryKey: ['savedTableViews'],
      });
      setSelectedView(data.id);
      setDraftName(''); // Clear the draft name
      setToastData({
        title: 'View Created Successfully',
        message: `Your view, ${data.name}, has been created successfully.`,
        isSuccess: true,
      });
    },
  });

  const deleteView = useMutation({
    mutationFn: async (viewId) => {
      return await sendPostRequest('/delete-table-view', { viewId });
    },
    onError: (error) => {
      setToastData({
        title: 'Saving View Failed',
        message: error.message || 'An error occurred while saving the view. Please try again.',
        isSuccess: false,
      });
    },
    onSuccess: ({ viewId }) => {
      queryClient.refetchQueries({
        queryKey: ['savedTableViews'],
      });
      setToastData({
        title: 'View Deleted Successfully',
        message: '',
        isSuccess: true,
      });
      if (selectedView === viewId) {
        setSelectedView(0);
      }
    },
  });

  const updateView = useMutation({
    mutationFn: async (viewId) => {
      const savedViewState = { ...tableState };
      (savedViewState.pagination.pageIndex = 0), delete savedViewState.rowSelection;
      Object.entries(savedViewState).forEach(([key, value]) => {
        if (deepEqual(value, defaultTableState[key])) {
          delete savedViewState[key];
        }
      });
      return await sendPutRequest('/update-table-view', {
        id: viewId,
        state: savedViewState,
      });
    },
    onError: (error) => {
      setToastData({
        title: 'Updating View Failed',
        message: error.message || 'An error occurred while updating the view. Please try again.',
        isSuccess: false,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['savedTableViews'],
      });
      setToastData({
        title: 'View Updated Successfully',
        message: '',
        isSuccess: true,
      });
    },
  });

  const saveViewButton = (
    <Button onClick={() => setOpenViewNameModal(true)} variant="contained">
      Save View
    </Button>
  );

  const handleTaskCreate = async (taskData) => {
    // Add API call to create task
    try {
      const res = await createNewTask(taskData);

      if (!res.success) {
        throw new Error(res.error);
      }

      setToastData({
        title: 'Task Created',
        message: `Task created successfully: ${res.data.taskDetails.name}`,
        isSuccess: true,
      });
    } catch (error) {
      setToastData({
        title: 'Task Creation Failed',
        message: error.message || 'An error occurred while creating the task. Please try again.',
        isSuccess: false,
      });
    }
    // Refresh tasks list
    queryClient.refetchQueries({
      queryKey,
    });
  };

  if (isError) return <span>Error: {error.message}</span>;

  return (
    <>
      <TeamSelector enabledTeams={enabledTeams} setEnabledTeams={setEnabledTeams} refetchQuery={refetch} />
      <div className="flex flex-row justify-between gap-2 m-4">
        <SavedViews
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          setTableState={setTableState}
          deleteView={deleteView}
          updateView={updateView}
          setToastData={setToastData}
          defaultTableState={defaultTableState}
          tableState={tableState}
        />
        <CreateNewTaskModal onTaskCreate={handleTaskCreate} enabledTeams={enabledTeams} />
      </div>
      <TasksTable
        data={data}
        initialInstanceState={{}}
        setToastData={setToastData}
        showSkeletons={isPlaceholderData && isFetching}
        enableSavingViews={true}
        tableState={tableState}
        setTableState={setTableState}
        saveViewButton={saveViewButton}
        clearSelectedView={clearSelectedView}
        selectedView={selectedView}
        defaultTableState={defaultTableState}
        queryClient={queryClient}
        queryKey={queryKey}
      />
      <SaveViewModal open={openViewNameModal} setOpen={setOpenViewNameModal} name={draftName} setName={setDraftName} mutateTableView={saveView} />
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </>
  );
}

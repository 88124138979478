import React, { useState, useEffect } from 'react';
import { ScrollArea } from '../../ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { cn } from '@/lib/utils';
import { CustomerDropdown } from './CustomerDropdown';
import { useCommunications } from '../../context/CommunicationsContext';
import { Conversation } from '../data/types';
import { Badge } from '../../ui/badge';
import { AvatarGroup } from './AvatarGroup';
import { formatDate } from '../utils/date';
import { MessageSquare, Calendar, GitBranch, CheckCircle } from 'lucide-react';
import { QaTeamDropdown } from './QaTeamDropdown';
import WolfLoader from '@/components/WolfLoader/WolfLoader';
import { SavedViewState } from '../data/types';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import { ClaimAndUnclaimButton } from './ClaimingButtons';
// import { SiSlack } from '@icons-pack/react-simple-icons';
// import MicrosoftIcon from '@mui/icons-material/Microsoft';

interface ConversationListProps {
  customerId: number | null;
  onSelectConversation: (conversationId: number, state: 'inProgress' | 'completed') => void;
  selectedConversationId: number | null;
}

export function ConversationList({ onSelectConversation, selectedConversationId }: ConversationListProps) {
  const {
    customers,
    conversations,
    selectedCustomerId,
    selectAllCustomers,
    setSelectedCustomerId,
    qaTeams,
    selectedQaTeamId,
    setSelectedQaTeamId,
    isLoading,
    getConversationsForCustomer,
    setSelectedConversationId,
  } = useCommunications();

  // Load saved view state from localStorage
  const savedViewState: SavedViewState | null = localStorage.getItem('savedViewState')
    ? JSON.parse(localStorage.getItem('savedViewState') as string)
    : null;

  const [activeTab, setActiveTab] = useState<'inProgress' | 'completed'>(() => {
    const savedViewState: SavedViewState | null = localStorage.getItem('savedViewState')
      ? JSON.parse(localStorage.getItem('savedViewState') as string)
      : null;

    return (savedViewState?.activeTab as 'inProgress' | 'completed') || 'inProgress';
  });

  const [isTabLoading, setIsTabLoading] = useState(false);

  const allConversationsForCustomer = getConversationsForCustomer(Number(selectedCustomerId));
  const allConversationsForQaTeam = conversations;

  const filteredConversations = selectAllCustomers
    ? allConversationsForQaTeam.filter((conv) => (activeTab === 'inProgress' ? !conv.completed : conv.completed))
    : allConversationsForCustomer.filter((conv) => (activeTab === 'inProgress' ? !conv.completed : conv.completed));

  const sortConversations = (conversations: Conversation[]) => {
    //
    return conversations.sort((a, b) => {
      // Check if conversation requires reply based on getStateBadge logic
      const aRequiresReply = !a.completed && a.messages[0]?.sender === 'external' && a.messages[0]?.messageTask?.status !== 'done';
      const bRequiresReply = !b.completed && b.messages[0]?.sender === 'external' && b.messages[0]?.messageTask?.status !== 'done';

      // If both require reply, sort by oldest external message first
      if (aRequiresReply && bRequiresReply) {
        // Find the latest external message in each conversation
        const aMostRecentExternalMessage = a.messages.find((msg) => msg.sender === 'external');
        const bMostRecentExternalMessage = b.messages.find((msg) => msg.sender === 'external');

        if (aMostRecentExternalMessage && bMostRecentExternalMessage) {
          // Sort oldest first for conversations requiring reply
          const aMostRecentExternalMessageTimestamp = new Date(Number(aMostRecentExternalMessage.timestamp) * 1000).getTime();
          const bMostRecentExternalMessageTimestamp = new Date(Number(bMostRecentExternalMessage.timestamp) * 1000).getTime();

          return aMostRecentExternalMessageTimestamp - bMostRecentExternalMessageTimestamp;
        }
      }

      // If one requires reply and other doesn't, prioritize the one requiring reply
      if (aRequiresReply && !bRequiresReply) return -1;
      if (!aRequiresReply && bRequiresReply) return 1;

      // For all other cases, sort by newest message first
      const aLastMessage = a.messages[0];
      const bLastMessage = b.messages[0];
      return new Date(aLastMessage.timestamp).getTime() - new Date(bLastMessage.timestamp).getTime();
    });
  };

  const sortedConversations = sortConversations(filteredConversations);

  const getStateBadge = (conversation: Conversation) => {
    if (!conversation.completed) {
      // Messages sorted from newest to oldest
      const conversationMessages = conversation.messages;
      const lastMessage = conversation.messages[0];
      const lastMessageAssociatedTask = lastMessage.messageTask;

      if (lastMessage.sender === 'external' && lastMessageAssociatedTask?.status !== 'done') {
        return <Badge variant="destructive">Requires Reply</Badge>;
      } else if (conversationMessages.length > 1) {
        return <Badge variant="secondary">Requires Action</Badge>;
      }
    }
    return null;
  };

  // Select handlers
  const handleCustomerSelect = (customerId: number) => {
    setSelectedCustomerId(customerId);
    localStorage.setItem(
      'savedViewState',
      JSON.stringify({
        ...savedViewState,
        customerId: customerId,
      }),
    );
  };

  const handleQaTeamSelect = (qaTeamId: number) => {
    setSelectedQaTeamId(qaTeamId);
    setSelectedCustomerId(null);
  };

  // Add this useEffect to handle customer selection after qaTeam changes
  useEffect(() => {
    if (selectedQaTeamId && customers.length > 0 && !selectedCustomerId) {
      const qaTeamCustomers = customers.filter((customer) => customer.qaTeam?.id === selectedQaTeamId);
      if (qaTeamCustomers.length > 0) {
        const firstCustomerId = qaTeamCustomers[0].id;
        setSelectedCustomerId(firstCustomerId);

        // Update localStorage
        localStorage.setItem(
          'savedViewState',
          JSON.stringify({
            ...savedViewState,
            qaTeamId: selectedQaTeamId,
            customerId: firstCustomerId,
          }),
        );
      }
    }
  }, [selectedQaTeamId, customers]);

  // Handle saved view state between inProgress and completed tabs
  const inProgressConversationId =
    selectedCustomerId &&
    savedViewState?.conversationByCustomer &&
    savedViewState?.conversationByCustomer[selectedCustomerId]?.inProgress?.conversationId
      ? savedViewState?.conversationByCustomer[selectedCustomerId]?.inProgress?.conversationId
      : null;

  const completedConversationId =
    selectedCustomerId &&
    savedViewState?.conversationByCustomer &&
    savedViewState?.conversationByCustomer[selectedCustomerId]?.completed?.conversationId
      ? savedViewState?.conversationByCustomer[selectedCustomerId]?.completed?.conversationId
      : null;

  const currentlySelectedConversationInLocalStorage = sortedConversations.find(
    (conv) => conv.id === inProgressConversationId || conv.id === completedConversationId,
  );

  // Handles coversation selection based on local storage
  useEffect(() => {
    if (activeTab === 'inProgress') {
      setSelectedConversationId(inProgressConversationId || sortedConversations[0]?.id);
    } else if (activeTab === 'completed') {
      setSelectedConversationId(completedConversationId || sortedConversations[0]?.id);
    }

    // If no conversation is in local storage, select the first conversation if it exists
    if (!currentlySelectedConversationInLocalStorage) {
      setSelectedConversationId(sortedConversations[0]?.id);
    }
  }, [activeTab, sortedConversations]);

  // Handle tab changes with loading state
  const handleTabChange = (value: string) => {
    setIsTabLoading(true);
    setActiveTab(value as 'inProgress' | 'completed');

    // Short timeout to allow for loading state to show
    setTimeout(() => {
      setIsTabLoading(false);
    }, 150);
  };

  return (
    <div className="w-96 bg-background border-r border-border flex flex-col h-full">
      {isLoading.qaTeams || isLoading.customers || isLoading.messages ? (
        <WolfLoader />
      ) : (
        <>
          <div className="flex-none">
            <div className="max-w-full p-4 border-b border-border">
              <QaTeamDropdown onSelectQaTeam={handleQaTeamSelect} selectedQaTeamId={selectedQaTeamId} qaTeams={qaTeams} />
            </div>
            <div className="max-w-full p-4 border-b border-border">
              <CustomerDropdown
                onSelectCustomer={handleCustomerSelect}
                selectedCustomerId={selectedCustomerId || Number(savedViewState?.customerId)}
                customers={customers}
                conversations={conversations}
              />
            </div>
          </div>
          <Tabs value={activeTab} defaultValue={activeTab} className="flex-1 flex flex-col min-h-0" onValueChange={handleTabChange}>
            <TabsList className="grid w-full grid-cols-2 flex-none">
              <TabsTrigger value="inProgress">In Progress</TabsTrigger>
              <TabsTrigger value="completed">Completed</TabsTrigger>
            </TabsList>
            <div className="flex-1 min-h-0">
              <TabsContent value="inProgress" className="h-full">
                {isTabLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <WolfLoader />
                  </div>
                ) : (
                  <ConversationItems
                    conversations={sortedConversations}
                    onSelectConversation={onSelectConversation}
                    selectedConversationId={selectedConversationId}
                    getStateBadge={getStateBadge}
                    activeTab="inProgress"
                  />
                )}
              </TabsContent>

              <TabsContent value="completed" className="h-full">
                {isTabLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <WolfLoader />
                  </div>
                ) : (
                  <ConversationItems
                    conversations={sortedConversations}
                    onSelectConversation={onSelectConversation}
                    selectedConversationId={selectedConversationId}
                    getStateBadge={getStateBadge}
                    activeTab="completed"
                  />
                )}
              </TabsContent>
            </div>
          </Tabs>
        </>
      )}
    </div>
  );
}

interface ConversationItemsProps {
  conversations: Conversation[];
  onSelectConversation: (conversationId: number, state: 'inProgress' | 'completed') => void;
  selectedConversationId: number | null | undefined;
  getStateBadge: (conversation: Conversation) => React.JSX.Element | null;
  activeTab: 'inProgress' | 'completed';
}

function ConversationItems({ conversations, onSelectConversation, selectedConversationId, getStateBadge, activeTab }: ConversationItemsProps) {
  const { isLoading } = useCommunications();

  const getClaimedStatus = (conversation: Conversation) => {
    const messages = conversation.messages;

    let claimedStatus;

    for (const message of messages) {
      if (message.messageTask && message.messageTask?.assignedTo?.id) {
        claimedStatus = 'claimed';
      } else if (message.messageTask && !message.messageTask?.assignedTo?.id) {
        // Return unclaimed if one message is unclaimed in conversation
        return 'unclaimed';
      }
    }

    return claimedStatus;
  };

  const requiresReply = (conversation: Conversation) => {
    const message = conversation.messages[0];
    if (message.sender === 'external' && message.messageTask?.status !== 'done') {
      return true;
    }
    return false;
  };

  const getRequiresReplyCount = (conversations: Conversation[], claimedStatus: string) => {
    return conversations.filter((conv) => getClaimedStatus(conv) === claimedStatus && requiresReply(conv)).length;
  };

  return (
    <ScrollArea className="h-full w-96 max-w-96">
      {!isLoading.messages && activeTab === 'inProgress' ? (
        <div className="w-96 max-w-96">
          <div className="max-w-96 p-4 space-y-2">
            <Accordion className="max-w-96" type="multiple" defaultValue={['unclaimed', 'claimed']}>
              {/* Unclaimed Conversations */}
              <AccordionItem value="unclaimed" className="border-b-0">
                <AccordionTrigger className="flex justify-between items-center">
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">Unclaimed</span>
                    <Badge variant={getRequiresReplyCount(conversations, 'unclaimed') > 0 ? 'destructive' : 'default'}>
                      {getRequiresReplyCount(conversations, 'unclaimed')}
                    </Badge>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="pb-0">
                  {conversations
                    .filter((conv) => getClaimedStatus(conv) === 'unclaimed')
                    .map((conversation) => (
                      <ConversationCard
                        key={conversation.id}
                        conversation={conversation}
                        selectedConversationId={selectedConversationId || null}
                        onSelectConversation={onSelectConversation}
                        getStateBadge={getStateBadge}
                        claimedStatus={getClaimedStatus(conversation)}
                      />
                    ))}
                </AccordionContent>
              </AccordionItem>

              {/* Claimed Conversations */}
              <AccordionItem value="claimed">
                <AccordionTrigger className="flex justify-between items-center bg-success/10 hover:bg-success/20">
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">Claimed</span>
                    <Badge variant={getRequiresReplyCount(conversations, 'claimed') > 0 ? 'destructive' : 'default'}>
                      {getRequiresReplyCount(conversations, 'claimed')}
                    </Badge>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="pb-0">
                  {conversations
                    .filter((conv) => getClaimedStatus(conv) === 'claimed')
                    .map((conversation) => (
                      <ConversationCard
                        key={conversation.id}
                        conversation={conversation}
                        selectedConversationId={selectedConversationId || null}
                        onSelectConversation={onSelectConversation}
                        getStateBadge={getStateBadge}
                        claimedStatus={getClaimedStatus(conversation)}
                      />
                    ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      ) : (
        // For completed tab, just show conversations without accordions
        <div className="w-96 max-w-96">
          <div className="max-w-96 p-4 space-y-2">
            {conversations.map((conversation) => (
              <ConversationCard
                key={conversation.id}
                conversation={conversation}
                selectedConversationId={selectedConversationId || null}
                onSelectConversation={onSelectConversation}
                getStateBadge={getStateBadge}
                claimedStatus={getClaimedStatus(conversation)}
              />
            ))}
          </div>
        </div>
      )}
    </ScrollArea>
  );
}

interface ConversationCardProps {
  conversation: Conversation;
  selectedConversationId: number | null;
  onSelectConversation: (id: number, state: 'inProgress' | 'completed') => void;
  getStateBadge: (conversation: Conversation) => React.ReactNode;
  claimedStatus: string | undefined;
}

function ConversationCard({ conversation, selectedConversationId, onSelectConversation, getStateBadge, claimedStatus }: ConversationCardProps) {
  const latestMessage = conversation.messages[0];
  const latestClaimedMessage = conversation.messages.find((msg) => msg.messageTask && msg.messageTask?.assignedTo?.id);
  const agents = conversation.participants.filter((p) => p.isAgent);
  const customers = conversation.participants.filter((p) => !p.isAgent);
  const [conversationHovered, setConversationHovered] = useState(false);

  return (
    <div className="relative">
      <div
        key={conversation.id}
        className={cn(
          'w-full max-h-72 overflow-y-hidden text-left p-4 mb-4 rounded-md transition-colors border border-border',
          'hover:bg-accent hover:text-accent-foreground',
          'focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 cursor-pointer',
          selectedConversationId === conversation.id ? 'bg-accent text-accent-foreground' : 'bg-card text-card-foreground',
        )}
        onClick={() => onSelectConversation(conversation.id, conversation.completed ? 'completed' : 'inProgress')}
        onMouseEnter={() => setConversationHovered(true)}
        onMouseLeave={() => setConversationHovered(false)}
      >
        {claimedStatus === 'claimed' && (
          <div className="mb-1 inline-flex items-center justify-right space-x-2 bg-blue-100 rounded-full px-3 py-1">
            <img
              src={latestClaimedMessage?.messageTask?.assignedTo?.avatarUrl}
              alt={latestClaimedMessage?.messageTask?.assignedTo?.name}
              className="w-5 h-5 rounded-full"
            />
            <p className="text-xs truncate text-blue-800">
              {' '}
              {conversation.completed ? <span>completed by </span> : <span>claimed by </span>}
              <span className="font-semibold">{conversation.claimedStatus}</span>
            </p>
          </div>
        )}
        <div className="mb-2">
          <h3 className="font-semibold text-sm truncate">{conversation.title}</h3>
        </div>

        <p className="text-xs text-muted-foreground mb-2 line-clamp-1">{conversation.summary}</p>

        <div className="flex justify-between items-center text-xs text-muted-foreground mb-2">
          <div className="flex items-center space-x-1">
            <Calendar className="w-3 h-3" />
            <span>{formatDate(latestMessage.timestamp)}</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-1">
              <MessageSquare className="w-3 h-3" />
              <span>{conversation.messages.length}</span>
            </div>
            <div className="flex items-center space-x-1">
              <GitBranch className="w-3 h-3" />
              <span>{conversation.threads.length}</span>
            </div>
          </div>
        </div>

        <div className="border-t border-border pt-2 max-h-70 overflow-hidden">
          <div className="flex justify-between items-baseline text-xs mb-1">
            <span className="font-medium truncate mr-2">{latestMessage.user}</span>
            <span className="text-muted-foreground whitespace-nowrap">{formatDate(latestMessage.timestamp)}</span>
          </div>
          <p className="text-sm text-muted-foreground line-clamp-2 break-words truncate">{latestMessage.messageContent}</p>
        </div>

        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center space-x-1 justify-between w-full">
            {conversation.completed ? (
              <>
                <CheckCircle className="w-3 h-3 text-muted-foreground" />
                <span className="text-xs text-muted-foreground">{formatDate(latestMessage.timestamp)}</span>
              </>
            ) : (
              getStateBadge(conversation)
            )}

            {/* Claim/Unclaim Buttons */}
            {conversationHovered && !conversation.completed && (
              <ClaimAndUnclaimButton conversation={conversation} action={claimedStatus === 'claimed' ? '/unclaim' : '/claim'} />
            )}
          </div>
          <div className="flex items-center">
            <AvatarGroup participants={agents} max={2} />
            <div className="mx-1 h-4 w-px bg-border"></div>
            <AvatarGroup participants={customers} max={2} />
          </div>
        </div>
      </div>
    </div>
  );
}

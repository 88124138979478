import { Button } from '@/components/ui/button';
import { sendPostRequest } from '@/utils/network';

function generateRandomString(length: number): string {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);

  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += charset[array[i] % charset.length];
  }
  return randomString;
}

export const SlackAuthButton = () => {
  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  const handleSlackAuth = async () => {
    const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI;
    const qawId = currentUser?.qawId;
    const state = `${generateRandomString(10)}:${qawId}`; // Use a unique state for security

    // Save state to redis in backend
    const response = await sendPostRequest('/state-authorization', { state });

    if (response?.success) {

      const authUrl = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=chat:write,users:read&user_scope=users:read&redirect_uri=${redirectUri}&state=${state}`;

      // Open the URL in a new tab to authorize the app and generate/store a Slack user token for current user
      window.open(authUrl, '_blank');
    } else {
      console.error('Failed to save state to redis', response);
    }
  };

  return (
    <Button className="mt-5" onClick={handleSlackAuth}>
      Authorize
    </Button>
  );
};

import { ElementDefinition, Core } from 'cytoscape';
import { Run, FailureSignatureData, BuildingBlock, NodeState } from './types';

const createBlockNode = (blockId: string, visitCounts: Map<string, number>): ElementDefinition => ({
  data: {
    id: blockId,
    label: blockId,
    type: 'block',
    visitCount: visitCounts.get(blockId) || 1,
  },
});

const createBlockEdge = (sourceId: string, targetId: string): ElementDefinition => ({
  data: {
    id: `${sourceId}-${targetId}`,
    source: sourceId,
    target: targetId,
  },
});

export const transformToGraphElements = (failureSignatureData: FailureSignatureData): ElementDefinition[] => {
  if (!failureSignatureData?.runs) {
    return [];
  }

  const graphElements: ElementDefinition[] = [];
  const nodeIds = new Set<string>();
  const edgeIds = new Set<string>();
  const blockVisitCounts = new Map<string, number>();

  failureSignatureData.runs.forEach((run: Run) => {
    const firstAttempt = run.attempts[0];
    firstAttempt.blockSequence.forEach((blockId: string) => {
      blockVisitCounts.set(blockId, (blockVisitCounts.get(blockId) || 0) + 1);
    });

    firstAttempt.blockSequence.forEach((blockId: string, blockIndex: number) => {
      if (!nodeIds.has(blockId)) {
        graphElements.push(createBlockNode(blockId, blockVisitCounts));
        nodeIds.add(blockId);
      }

      if (blockIndex !== 0) {
        // Check if edge already exists from previous block
        const prevBlockId = firstAttempt.blockSequence[blockIndex - 1];
        const edgeId = `${prevBlockId}-${blockId}`;

        if (!edgeIds.has(edgeId)) {
          graphElements.push(createBlockEdge(prevBlockId, blockId));
          edgeIds.add(edgeId);
        }
      }
    });
  });

  return graphElements;
};

export const getAllBlockIds = (runs: Run[]): Set<string> => {
  const allBlockIds = new Set<string>();
  if (!runs) return allBlockIds;

  runs.forEach((run) => {
    if (run?.attempts) {
      run.attempts.forEach((attempt) => {
        if (attempt?.blockSequence) {
          attempt.blockSequence.forEach((blockId) => {
            if (blockId) {
              allBlockIds.add(blockId);
            }
          });
        }
      });
    }
  });
  return allBlockIds;
};

export const createBlockIdToNameMap = (blockIds: Set<string>, buildingBlocks: BuildingBlock[]): Map<string, string> => {
  const blockIdToName = new Map<string, string>();
  if (!blockIds || !buildingBlocks) return blockIdToName;

  Array.from(blockIds).forEach((blockId) => {
    if (!blockId) return;

    const matchingBlock = buildingBlocks.find((block) => block?.name && blockId.toLowerCase().includes(block.name.toLowerCase()));
    if (matchingBlock?.name) {
      blockIdToName.set(blockId, matchingBlock.name);
    }
  });
  return blockIdToName;
};

export const getBuildingBlocks = (
  dictionary: Record<string, BuildingBlock | BuildingBlock[]> | undefined,
  blockIds: Set<string>,
): BuildingBlock[] => {
  if (!dictionary) return [];

  // Convert all dictionary entries to BuildingBlock array
  const blocks = Object.entries(dictionary)
    .map(([, block]) => (Array.isArray(block) ? block[0] : block))
    .filter((block): block is BuildingBlock => !!block);

  // Log any block IDs that don't have corresponding dictionary entries
  Array.from(blockIds).forEach((id) => {
    if (!blocks.some((block) => block.name === id)) {
      console.warn(`Block ID "${id}" not found in dictionary`);
    }
  });

  return blocks;
};

export const calculateNodeStates = (currentData: FailureSignatureData | null) => {
  const states = new Map<string, NodeState>();

  currentData?.runs?.forEach((run: Run) => {
    const firstAttempt = run.attempts[0];
    const sequence = firstAttempt.blockSequence ?? [];
    if (!sequence) return;

    sequence.forEach((blockId: string, index: number) => {
      const currentState = states.get(blockId) || { success: 0, failure: 0 };

      // Only count as failure if it's the last block in a failed attempt
      if (firstAttempt.status === 'fail' && index === sequence.length - 1) {
        currentState.failure++;
      } else {
        currentState.success++;
      }

      states.set(blockId, currentState);
    });
  });
  return states;
};

export const updateNodeClasses = (
  cy: Core | null,
  nodeStates: Map<string, NodeState>,
  selectedWorkflow: string | null,
  selectedBlock: string | null,
  currentData: FailureSignatureData | null,
) => {
  if (!cy) return;

  cy.nodes().forEach((node) => {
    const state = nodeStates.get(node.id());
    if (!state) return;

    // Remove existing state classes
    node.removeClass('success mixed failure dimmed highlighted');

    // Update tooltip
    const tooltipText = `Success: ${state.success}\nFailure: ${state.failure}`;
    node.data('tooltip', tooltipText);

    if (selectedWorkflow) {
      // If a workflow is selected, handle specific workflow view
      const workflow = currentData?.runs?.find((run: Run) => run.workflow.id === selectedWorkflow);
      if (workflow) {
        const firstAttempt = workflow.attempts[0];
        const sequence = firstAttempt.blockSequence;

        if (sequence.includes(node.id())) {
          const isLastNode = sequence[sequence.length - 1] === node.id();
          if (isLastNode) {
            // Color last node based on attempt status
            node.addClass(firstAttempt.status === 'pass' ? 'success' : 'failure');
          } else {
            // All other nodes in sequence are green
            node.addClass('success');
          }
        } else if (node.id() === selectedBlock) {
          // Selected block should not be dimmed, even if not in sequence
          if (state.failure === 0) {
            node.addClass('success');
          } else if (state.success === 0) {
            node.addClass('failure');
          } else {
            node.addClass('mixed');
          }
          node.addClass('highlighted');
        } else {
          // Dim nodes not in sequence and not selected
          node.addClass('dimmed');
        }
      }
    } else if (selectedBlock === node.id()) {
      // If a building block is selected, color based on its overall state
      if (state.failure === 0) {
        node.addClass('success');
      } else if (state.success === 0) {
        node.addClass('failure');
      } else {
        node.addClass('mixed');
      }
      node.addClass('highlighted');
      // Dim other nodes
      cy.nodes().forEach((otherNode) => {
        if (otherNode.id() !== node.id()) {
          otherNode.addClass('dimmed');
        }
      });
    } else if (!selectedBlock && !selectedWorkflow) {
      // Global state coloring when nothing is selected
      if (state.failure === 0) {
        node.addClass('success');
      } else if (state.success === 0) {
        node.addClass('failure');
      } else {
        node.addClass('mixed');
      }
    }
  });
};

export const highlightSequence = (
  cy: Core | null,
  sequence: string[],
  selectedWorkflow: string | null,
  currentData: FailureSignatureData | null,
  shouldZoom: boolean | boolean = true,
) => {
  if (!cy) return;

  // Reset all nodes to default state
  cy.nodes().removeClass('success mixed failure dimmed');
  cy.edges().removeClass('path-highlighted');

  // Highlight nodes in sequence
  sequence.forEach((blockId: string, index: number) => {
    const node = cy.$(`#${blockId}`);
    const isLastNode = index === sequence.length - 1;

    // Color based on workflow status for last node
    if (isLastNode) {
      const workflow = currentData?.runs?.find((run: Run) => run.workflow.id === selectedWorkflow);
      if (workflow) {
        const firstAttempt = workflow.attempts[0];
        node.addClass(firstAttempt.status === 'pass' ? 'success' : 'failure');
      }
    } else {
      // All other nodes in sequence are green
      node.addClass('success');
    }

    // Highlight edges between sequential nodes
    if (index < sequence.length - 1) {
      const nextBlockId = sequence[index + 1];
      const edge = cy.edges(`[source = "${blockId}"][target = "${nextBlockId}"]`);
      edge.addClass('path-highlighted');
    }
  });

  // Dim nodes not in sequence
  cy.nodes().forEach((node) => {
    if (!sequence.includes(node.id())) {
      node.addClass('dimmed');
    }
  });

  // Only zoom if requested
  if (shouldZoom) {
    const sequenceElements = cy.elements('node.success, node.failure, .path-highlighted');
    if (sequenceElements.length > 0) {
      cy.animate({
        fit: {
          eles: sequenceElements,
          padding: 50,
        },
        duration: 500,
      });
    }
  }
};

export const centerOnNode = (cy: Core | null, blockId: string) => {
  if (!cy) return;
  const node = cy.$(`#${blockId}`);
  if (node.length) {
    cy.animate({
      center: {
        eles: node,
      },
      zoom: 2,
      duration: 500,
    });
  }
};

export const handleBlockSelection = (
  cy: Core | null,
  blockId: string,
  selectedBlock: string | null,
  setSelectedBlock: (blockId: string | null) => void,
  setSelectedWorkflow: (workflowId: string | null) => void,
  setSelectedSequenceIndex: (index: number) => void,
  currentData?: FailureSignatureData | null,
  setActiveView?: (view: 'workflows' | 'blocks') => void,
  selectedWorkflow: string | null = null,
  fromGraphClick: boolean | boolean = false,
) => {
  if (!cy) return;

  // Auto-switch to blocks view when a block is selected from graph
  if (fromGraphClick) {
    setActiveView?.('blocks');
    // Scroll the block into view in the side panel
    setTimeout(() => {
      const blockElement = document.querySelector(`[data-block-id="${blockId}"]`);
      blockElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100); // Small delay to ensure view switch has completed
  }

  // Toggle selection if clicking the same block
  if (blockId === selectedBlock) {
    setSelectedBlock(null);
    // Reset node states while preserving workflow sequence
    if (currentData) {
      const nodeStates = calculateNodeStates(currentData);
      updateNodeClasses(cy, nodeStates, selectedWorkflow, null, currentData);
      if (selectedWorkflow) {
        const workflow = currentData.runs?.find((run) => run.workflow.id === selectedWorkflow);
        if (workflow) {
          const firstAttempt = workflow.attempts[0];
          highlightSequenceEdges(cy, firstAttempt.blockSequence);
        }
      }
    }
    return;
  }

  // Select the new block
  setSelectedBlock(blockId);
  const node = cy.$(`#${blockId}`);

  // Find and center on the node
  if (node.length) {
    cy.animate({
      center: {
        eles: node,
      },
      zoom: 2,
      duration: 500,
    });
  }

  // Update node states while preserving workflow highlighting if it exists
  if (currentData) {
    const nodeStates = calculateNodeStates(currentData);
    // If there's a selected workflow, keep its highlighting
    const workflow = currentData.runs?.find((run) => run.workflow.id === selectedWorkflow);
    if (workflow) {
      const firstAttempt = workflow.attempts[0];
      updateNodeClasses(cy, nodeStates, selectedWorkflow, blockId, currentData);
      // Re-highlight the sequence edges
      highlightSequenceEdges(cy, firstAttempt.blockSequence);
    } else {
      updateNodeClasses(cy, nodeStates, null, blockId, currentData);
    }
  }
};

// Helper function to highlight sequence edges without affecting node states or zoom
export const highlightSequenceEdges = (cy: Core | null, sequence: string[]) => {
  if (!cy) return;

  cy.edges().removeClass('path-highlighted');

  sequence.forEach((blockId, index) => {
    if (index < sequence.length - 1) {
      const nextBlockId = sequence[index + 1];
      const edge = cy.edges(`[source = "${blockId}"][target = "${nextBlockId}"]`);
      edge.addClass('path-highlighted');
    }
  });
};

export const getBlockReferences = (blockId: string, currentData: FailureSignatureData | null): { passed: Run[]; failed: Run[] } => {
  const references = {
    passed: [] as Run[],
    failed: [] as Run[],
  };

  if (!currentData?.runs) return references;

  currentData.runs.forEach((run) => {
    const firstAttempt = run.attempts[0];
    if (firstAttempt.blockSequence.includes(blockId)) {
      if (firstAttempt.status === 'pass') {
        references.passed.push(run);
      } else {
        references.failed.push(run);
      }
    }
  });

  return references;
};

export const handleWorkflowSelection = (
  cy: Core | null,
  run: Run,
  selectedWorkflow: string | null,
  setSelectedWorkflow: (workflowId: string | null) => void,
  setSelectedBlock: (blockId: string | null) => void,
  setSelectedSequenceIndex: (index: number) => void,
  currentData: FailureSignatureData | null,
) => {
  if (!cy) return;

  // Reset block selection when selecting a workflow
  setSelectedBlock(null);

  const newWorkflowId = selectedWorkflow === run.workflow.id ? null : run.workflow.id;
  setSelectedWorkflow(newWorkflowId);
  setSelectedSequenceIndex(0);

  // Reset all previous highlights
  cy.elements().removeClass('highlighted path-highlighted');

  // Only highlight new sequence if selecting a workflow (not unselecting)
  if (newWorkflowId && run.attempts && run.attempts.length > 0) {
    const firstAttempt = run.attempts[0];
    // When selecting a workflow, we do want to zoom to fit
    highlightSequence(cy, firstAttempt.blockSequence, newWorkflowId, currentData, true);
  }
};

export const navigateWorkflowSequence = (
  cy: Core | null,
  direction: 'prev' | 'next',
  selectedWorkflow: string | null,
  selectedSequenceIndex: number,
  currentData: FailureSignatureData | null,
  setSelectedSequenceIndex: (index: number) => void,
) => {
  if (!cy || !selectedWorkflow || !currentData?.runs) return;

  const selectedRun = currentData.runs.find((run: Run) => run.workflow.id === selectedWorkflow);
  if (!selectedRun?.attempts?.length) return;

  const firstAttempt = selectedRun.attempts[0];
  if (!firstAttempt.blockSequence) return;

  const sequence = firstAttempt.blockSequence;
  const newIndex = direction === 'next' ? Math.min(selectedSequenceIndex + 1, sequence.length - 1) : Math.max(selectedSequenceIndex - 1, 0);

  setSelectedSequenceIndex(newIndex);
  centerOnNode(cy, sequence[newIndex]);
};

export const handleSequenceBlockSelection = (cy: Core | null, blockId: string, index: number, setSelectedSequenceIndex: (index: number) => void) => {
  if (!cy) return;
  setSelectedSequenceIndex(index);
  centerOnNode(cy, blockId);
};

export const exampleData = {
  runs: [
    {
      id: 'example-1',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 1',
        id: 'workflow-1',
        steps: [
          {
            id: 'step_1',
            name: 'Login to Application',
            order: 1,
            isUtility: false,
            code: "await page.login('user@example.com', 'password123');",
          },
          {
            id: 'step_2',
            name: 'Navigate to Dashboard',
            order: 2,
            isUtility: false,
            code: "await page.goto('/dashboard');",
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-1',
          status: 'pass',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['staggerStart', 'buildUrl', 'logIn', 'browserLogin', 'apiLogin'],
        },
      ],
      investigationState: 'none',
    },
    {
      id: 'example-2',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 2',
        id: 'workflow-2',
        steps: [
          {
            id: 'step_1',
            name: 'Login to Application',
            order: 1,
            isUtility: false,
            code: "await page.login('user@example.com', 'password123');",
          },
          {
            id: 'step_2',
            name: 'Navigate to Dashboard',
            order: 2,
            isUtility: false,
            code: "await page.goto('/dashboard');",
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-2',
          status: 'fail',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['logIn', 'createBillableMetric', 'createProduct', 'createPlanNoCustomer', 'cleanUpPlans'],
        },
      ],
      investigationState: 'none',
    },
    {
      id: 'example-3',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 3',
        id: 'workflow-3',
        steps: [
          {
            id: 'step_1',
            name: 'Complex Login Flow',
            order: 1,
            isUtility: false,
            code: 'await complexLogin();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-3',
          status: 'pass',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['staggerStart', 'buildUrl', 'browserLogin', 'apiLogin', 'createProduct', 'cleanUpPlans'],
        },
      ],
      investigationState: 'none',
    },
    {
      id: 'example-4',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 4',
        id: 'workflow-4',
        steps: [
          {
            id: 'step_1',
            name: 'Create Resources',
            order: 1,
            isUtility: false,
            code: 'await createResources();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-4',
          status: 'fail',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['buildUrl', 'apiLogin', 'createBillableMetric', 'createProduct', 'createPlanNoCustomer'],
        },
      ],
      investigationState: 'investigating',
    },
    {
      id: 'example-5',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 5',
        id: 'workflow-5',
        steps: [
          {
            id: 'step_1',
            name: 'Customer Management',
            order: 1,
            isUtility: false,
            code: 'await manageCustomer();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-5',
          status: 'pass',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['apiLogin', 'createCustomer', 'updateCustomer', 'deleteCustomer'],
        },
      ],
      investigationState: 'none',
    },
    {
      id: 'example-6',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 6',
        id: 'workflow-6',
        steps: [
          {
            id: 'step_1',
            name: 'Invoice Generation',
            order: 1,
            isUtility: false,
            code: 'await generateInvoice();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-6',
          status: 'fail',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['apiLogin', 'createCustomer', 'createInvoice', 'validateInvoice'],
        },
      ],
      investigationState: 'investigating',
    },
    {
      id: 'example-7',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 7',
        id: 'workflow-7',
        steps: [
          {
            id: 'step_1',
            name: 'Subscription Flow',
            order: 1,
            isUtility: false,
            code: 'await manageSubscription();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-7',
          status: 'pass',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['apiLogin', 'createCustomer', 'createSubscription', 'validateSubscription'],
        },
      ],
      investigationState: 'none',
    },
    {
      id: 'example-8',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 8',
        id: 'workflow-8',
        steps: [
          {
            id: 'step_1',
            name: 'Data Export',
            order: 1,
            isUtility: false,
            code: 'await exportData();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-8',
          status: 'pass',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['apiLogin', 'exportData', 'validateExport'],
        },
      ],
      investigationState: 'none',
    },
    {
      id: 'example-9',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 9',
        id: 'workflow-9',
        steps: [
          {
            id: 'step_1',
            name: 'API Integration',
            order: 1,
            isUtility: false,
            code: 'await testApiIntegration();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-9',
          status: 'fail',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['apiLogin', 'setupApiKey', 'testEndpoints', 'validateResponses'],
        },
      ],
      investigationState: 'resolved',
    },
    {
      id: 'example-10',
      teamName: 'example-team',
      teamId: 'team-1',
      workflow: {
        name: 'Example Workflow 10',
        id: 'workflow-10',
        steps: [
          {
            id: 'step_1',
            name: 'Webhook Management',
            order: 1,
            isUtility: false,
            code: 'await manageWebhooks();',
          },
        ],
      },
      attempts: [
        {
          id: 'attempt-10',
          status: 'pass',
          startedAt: new Date().toISOString(),
          eventsUrl: '',
          eventLogs: [],
          blockSequence: ['apiLogin', 'createWebhook', 'triggerEvent', 'validateWebhook'],
        },
      ],
      investigationState: 'none',
    },
  ],
  buildingBlockDictionary: {
    customerId: 'customer-1',
    blocks: {
      staggerStart: {
        name: 'staggerStart',
        startLine: 2,
        endLine: 17,
        type: 'helper',
        code: [
          'async function staggerStart(page) {',
          'await page.goto(',
          '"https://qawolf-automation.herokuapp.com/stagger/page/metronome",',
          ');',
          'let countdown = parseInt(await page.innerText("#countdown"));',
          'while (countdown) {',
          'countdown = parseInt(await page.innerText("#countdown"));',
          'console.log(`Your test will begin in ${countdown} seconds.`);',
          'await page.waitForTimeout(1 * 1000);',
          '}',
          '}',
        ],
      },
      buildUrl: {
        name: 'buildUrl',
        startLine: 18,
        endLine: 28,
        type: 'helper',
        code: [
          'function buildUrl(route = "/") {',
          'const baseUrl = process.env.URL || process.env.DEFAULT_URL || "https://app.staging.metronome.com/";',
          'if (route === "/" && baseUrl.includes("index.html")) {',
          'return baseUrl;',
          '}',
          'return `${baseUrl}${route}`;',
          '}',
        ],
      },
      logIn: {
        name: 'logIn',
        startLine: 39,
        endLine: 42,
        type: 'helper',
        code: ['async function logIn(options = {}) {', 'return await apiLogin(options);', '}'],
      },
      apiLogin: {
        name: 'apiLogin',
        startLine: 77,
        endLine: 110,
        type: 'helper',
        code: [
          'async function apiLogin(options = {}) {',
          'const token = options.token || process.env.API_TOKEN_ONE;',
          'const apiLoginUrl = `${process.env.API_URL}/experimental/auth/getFrontendTestingUrl`;',
          'const res = await fetch(apiLoginUrl, {',
          'headers: { Authorization: `Bearer ${token}` }',
          '});',
          'const { url } = await res.json();',
          'const page = await context.newPage();',
          'await page.goto(buildUrl(url));',
          'return { page };',
          '}',
        ],
      },
      createInvoice: {
        name: 'createInvoice',
        startLine: 111,
        endLine: 130,
        type: 'helper',
        code: [
          'async function createInvoice(page, invoiceData) {',
          "await page.goto(buildUrl('/invoices'));",
          "await page.getByRole('button', { name: 'Create Invoice' }).click();",
          'await page.fill(\'[name="amount"]\', invoiceData.amount);',
          'await page.click(\'button:has-text("Generate")\');',
          '}',
        ],
      },
      validateInvoice: {
        name: 'validateInvoice',
        startLine: 131,
        endLine: 150,
        type: 'helper',
        code: [
          'async function validateInvoice(page, invoiceId) {',
          'await page.goto(buildUrl(`/invoices/${invoiceId}`));',
          "const status = await page.locator('.invoice-status').textContent();",
          "if (status !== 'paid') {",
          "  throw new Error('Invoice validation failed');",
          '}',
          '}',
        ],
      },
      createSubscription: {
        name: 'createSubscription',
        startLine: 151,
        endLine: 170,
        type: 'helper',
        code: [
          'async function createSubscription(page, subscriptionData) {',
          "await page.goto(buildUrl('/subscriptions'));",
          "await page.getByRole('button', { name: 'New Subscription' }).click();",
          'await page.fill(\'[name="planId"]\', subscriptionData.planId);',
          'await page.click(\'button:has-text("Create")\');',
          '}',
        ],
      },
    },
  },
} as const;

export const generateCsvData = (currentData: FailureSignatureData | null) => {
  if (!currentData?.runs) return '';

  const blockStats = new Map<
    string,
    {
      total: number;
      failures: number;
      flaky: boolean;
      failureRate: number;
      workflowIds: Set<string>;
    }
  >();

  // Gather statistics for each block
  currentData.runs.forEach((run) => {
    const firstAttempt = run.attempts[0];
    firstAttempt.blockSequence.forEach((blockId, index) => {
      const stats = blockStats.get(blockId) || {
        total: 0,
        failures: 0,
        flaky: false,
        failureRate: 0,
        workflowIds: new Set<string>(),
      };

      stats.total++;
      stats.workflowIds.add(run.workflow.id);

      // Count as failure if it's the last block in a failed attempt
      if (firstAttempt.status === 'fail' && index === firstAttempt.blockSequence.length - 1) {
        stats.failures++;
      }

      // Calculate failure rate and flaky status
      stats.failureRate = (stats.failures / stats.total) * 100;
      stats.flaky = stats.failures > 0 && stats.failures < stats.total;

      blockStats.set(blockId, stats);
    });
  });

  type BlockStats = {
    total: number;
    failures: number;
    failureRate: number;
    flaky: boolean;
    workflowIds: Set<string>;
  };

  // Convert to array and sort by failure rate
  const sortedBlocks = Array.from(blockStats.entries())
    .map(([blockId, stats]) => ({
      blockId,
      ...stats,
      // A block is flaky if it has both successes and failures, but not if it's 100% failure
      flaky: stats.failures > 0 && stats.failures < stats.total && stats.failureRate !== 100,
    }))
    .sort((a, b) => {
      // Sort by failure rate first (highest to lowest)
      return b.failureRate - a.failureRate;
    })
    .map(({ blockId, ...stats }) => [blockId, stats] as [string, BlockStats]);

  // Convert to CSV format
  const headers = ['Block ID', 'Total Uses', 'Failures', 'Failure Rate', 'Is Flaky', 'Workflow IDs'];
  const rows = sortedBlocks.map(([blockId, stats]) => {
    return [
      blockId,
      stats.total.toString(),
      stats.failures.toString(),
      `${stats.failureRate.toFixed(2)}%`,
      stats.flaky ? 'Yes' : 'No',
      Array.from(stats.workflowIds).join(';'),
    ].join(',');
  });

  return [headers.join(','), ...rows].join('\n');
};

export const downloadCsv = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

import { Menu, Transition, Dialog } from '@headlessui/react';
import {
  ClockIcon,
  EllipsisHorizontalIcon,
  InboxArrowDownIcon,
  TrashIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

import { parseISO } from 'date-fns';
import { Fragment, useState } from 'react';
import { getTeamInfo } from '../../../utils/helpers';
import { classNames, getBackgroundColor, getElapsed } from '../helpers';
import { useDisabledTasks } from '../../../hooks/useDisabledTasks';

let teamLeadsFromCache = getTeamInfo();
export function PromotionTaskCard({ task, toggleClaimed, user, suiteAction }) {
  const { disabledTasks, setDisabledTasks } = useDisabledTasks();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const isProjectPlanTask = task.name.includes('Update scheduled tasks for this and next week');
  const title = `${task.team.name} - Promotion`;
  const thisTaskAge = getElapsed(parseISO(task?.createdAt));
  const claimedByAnyone = task.assignedTo?.name ? true : false;
  const claimedByCurrentUser = task.assignedTo?.id === user.qawId;

  // enrich the task with extra info
  task.qaTeam = teamLeadsFromCache.filter((x) => x.qawId === task.qaLead.id)[0] || {
    imageUrl: '',
    teamName: 'Unknown',
    name: 'Unknown',
  };

  // get the message and url if task was created from a message
  if (task.notesPlainText) {
    const { url, message } = JSON.parse(task.notesPlainText);
    task.messageUrl = url;
    task.message = message;
  }

  const doneButton = (
    <button
      className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20 hover:ring-2 hover:bg-green-100"
      onClick={() => setShowConfirmModal(true)}
      disabled={disabledTasks[task.id]}
    >
      {disabledTasks[task.id] ? 'Pending...' : isProjectPlanTask ? 'Approve' : 'Done'}
    </button>
  );

  const promoteButton = (
    <button
      className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-purple-700 bg-purple-50 ring-purple-600/20 hover:ring-2 hover:bg-purple-100"
      onClick={() => {
        const url = `https://app.qawolf.com/${task.team.slug}/environments/${task.baseBranchId}/promote/${task.headBranchId}`;
        window.open(url, '_blank'); // Opens the URL in a new tab
      }}
      disabled={disabledTasks[task.id]}
    >
      See Promotion
    </button>
  );

  return (
    <>
      <div className="rounded-xl border border-gray-200 bg-white border-blue-400">
        <div className={classNames('rounded-t-xl flex items-center gap-x-4 border-b border-gray-900/5 p-4', getBackgroundColor(task))}>
          <div className="w-full grid grid-cols-5 ">
            {/* Team image & Client name  */}
            <div className="overflow-hidden flex justify-start text-sm font-medium col-span-2">
              <img
                className="h-6 w-6 rounded-full mr-2"
                src={task.qaTeam.imageUrl}
                alt={`${task.qaTeam.teamName} Team - ${task.qaTeam.name}`}
                title={`${task.qaTeam.teamName} Team - ${task.qaTeam.name}`}
              />
              <p className="truncate flex ext-gray-900 max-w-2/5" title={`${title} | ID ${task.id}`}>
                {title}
              </p>
            </div>
            <div className="flex justify-end col-span-3 xl:gap-1 2xl:gap-2">
              {!claimedByAnyone ? (
                <>
                  {/* Only buttons if unclaimed */}
                  {doneButton}
                  {promoteButton}
                  <button
                    className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100"
                    onClick={() => toggleClaimed(task, true)}
                  >
                    Claim
                  </button>
                </>
              ) : (
                <>
                  {/* Assigned user deatils if claimed */}
                  <div className="flex justify-right xl:gap-1 2xl:gap-2">
                    <img
                      className="h-6 w-6 rounded-full"
                      src={task.assignedTo?.avatarUrl}
                      alt={task.assignedTo?.name}
                      title={task.assignedTo?.name}
                    />
                    {/* Only show the user's name if it's not the current user to save space */}
                    {claimedByCurrentUser || <h3 className={classNames('truncate text-sm', 'font-medium text-gray-900')}>{task.assignedTo?.name}</h3>}
                  </div>
                  {/* Done buttons if claimed by current user */}
                  {claimedByCurrentUser && <>{doneButton}</>}
                  {claimedByCurrentUser && <>{promoteButton}</>}
                </>
              )}

              {/* 'See' button always visible */}
              {isProjectPlanTask ? (
                <a
                  href={`/teamTasks/${task.team.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
                >
                  See Customer Tasks
                </a>
              ) : (
                task.messageUrl && (
                  <a
                    href={task.messageUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
                  >
                    {task.messageUrl.includes('teams')
                      ? 'See MS Teams Message'
                      : task.messageUrl.includes('discord')
                      ? 'See Discord Message'
                      : 'See Slack Message'}
                  </a>
                )
              )}

              {/* Action Menu */}
              <Menu
                as="div"
                className="relative rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
              >
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-0.5 w-24 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {claimedByAnyone &&
                      (claimedByCurrentUser ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
                              onClick={() => toggleClaimed(task, false)}
                            >
                              <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Unclaim
                            </button>
                          )}
                        </Menu.Item>
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
                              onClick={() => toggleClaimed(task, true)}
                            >
                              <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Claim
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-red-600')}
                          onClick={() => suiteAction(task, '/ignore')}
                        >
                          <TrashIcon className="h-5 w-5 pt-1 mr-1" /> Delete
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
          <div className="py-2">
            <dt className="flex justify-between gap-x-3 text-gray-500 -ml-1">
              <div className="flex">
                <div className="w-1/10">
                  {isProjectPlanTask ? (
                    <ClipboardDocumentCheckIcon className="mt-0.5 w-5 h-5 text-red-500" />
                  ) : (
                    <CheckCircleIcon className="mt-0.5 w-5 h-5 text-red-500" />
                  )}
                </div>
                <div className="ml-2 text-gray-500">
                  <p className="font-medium" title={task.name}>
                    {task.name.split(':').slice(1).join(':').trim()}
                  </p>
                  <br />
                  <p className="text-sm">
                    A PR has been merged. Please review and promote changes if needed from the PR to the main env, delete the PR env, and mark the
                    task as completed.
                  </p>
                </div>
              </div>
              <div className={classNames('flex', 'text-gray-500')}>
                <ClockIcon className={classNames('w-5 h-5  mt-0.5 mr-1', 'text-gray-500')} title={`Task created: ${thisTaskAge}`} />
                <p title={`Task created: ${thisTaskAge}`}>{thisTaskAge}</p>
              </div>
            </dt>
          </div>
        </dl>
      </div>

      {/* Add confirmation modal */}
      <Transition appear show={showConfirmModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowConfirmModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Confirm Action
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      This will delete the PR environment if it hasn't already been deleted. To confirm action, please type the environment name: <span className="font-medium">{task.headBranchName}</span>
                    </p>
                    <input
                      type="text"
                      value={confirmText}
                      onChange={(e) => setConfirmText(e.target.value)}
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder={task.headBranchName}
                    />
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setShowConfirmModal(false);
                        setConfirmText('');
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={() => {
                        setDisabledTasks((prev) => ({ ...prev, [task.id]: true }));
                        suiteAction(task, '/done');
                        setShowConfirmModal(false);
                        setConfirmText('');
                      }}
                      disabled={confirmText !== task.headBranchName}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

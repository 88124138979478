import { Dispatch, SetStateAction, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, List, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { plural } from '../../../InvestigationBoard/helpers';
import { CardItem } from './subComponents';
import { Milestone } from '../types';
import { UpdatedMilestoneValues } from './MsDrawer';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { getNumberOfWeeks } from './utils';
dayjs.extend(LocalizedFormat);

export function DataCard({
  milestone,
  requiredVelocity,
  isUpdating,
  updatedMilestoneValues,
  setUpdatedMilestoneValues,
  totalActiveTestCount,
  isCurrentMilestone,
  isFutureMilestone,
}: {
  milestone: Milestone;
  requiredVelocity: number;
  isUpdating: boolean;
  updatedMilestoneValues: UpdatedMilestoneValues;
  setUpdatedMilestoneValues: Dispatch<SetStateAction<UpdatedMilestoneValues>>;
  totalActiveTestCount: number;
  isCurrentMilestone: boolean;
  isFutureMilestone: boolean;
}) {
  const remainingTests = +(updatedMilestoneValues.targetTestCount - totalActiveTestCount).toFixed(2);
  const activatedStr = `${totalActiveTestCount} Activated`;
  const remainingStr = remainingTests > 0 ? `, ${remainingTests} Remaining` : ` ✅`;

  // Get the milestone directly before and after the current milestone
  const previousMilestone = milestone.previousMilestone;
  const nextMilestone = milestone.nextMilestone;

  /*
    **If the milestone has started, disable the start date picker**

    Minimum Start Date:
     - If there is a previous milestone, use the day after the previous milestone's external due date
     - If there is no previous milestone, use today's date

    Maximum Start Date:
     - If there is a next milestone, use the day before the next milestone's start date
     - If there is no next milestone, use the first day of the week of the current milestone's external due date
  */
  const shouldDisableStartDate = !isFutureMilestone;
  const minStartDate = previousMilestone ? dayjs(previousMilestone.externalDueDate).add(1, 'day') : dayjs();
  const maxStartDate = nextMilestone
    ? dayjs(nextMilestone.startDate).subtract(1, 'day')
    : dayjs(updatedMilestoneValues.externalDueDate).startOf('week');

  /*
    Minimum External Due Date:
     - If the milestone has started
      - Use the dueDate of the current subMilestone
     - If the milestone has not started yet
      - If there is a previous milestone, use the day after the previous milestone's external due date
      - If there is no previous milestone, use the end of the current week

    Maximum External Due Date:
     - If there is a next milestone, use the day before the next milestone's start date
     - If there is no next milestone, there is no maximum external due date
  */
  const currentSubMilestone = milestone.subMilestones.find((subMilestone) => dayjs().isSame(subMilestone.dueDate, 'week'));
  const minExternalDueDate = isCurrentMilestone
    ? dayjs(currentSubMilestone?.dueDate)
    : previousMilestone
    ? dayjs(previousMilestone.externalDueDate).add(1, 'day')
    : dayjs().endOf('week');
  const maxExternalDueDate = nextMilestone ? dayjs(nextMilestone.startDate).subtract(1, 'day') : undefined;

  /*
   Minimum Target Test Count:
    - If there is a previous milestone
     - Use the targetTestCount of the previous milestone + 1
    - If there is no previous milestone
     - Use the activeTests from the first subMilestone week (updated periodically in the `update-milestones` scheduled job)
 
   Maximum Target Test Count:
    - If there is a next milestone
     - Use the next milestone's targetTestCount - 1
    - If there is no next milestone, there is no maximum target test count (Using 10000 as an arbitrary large number)
  */
  const minTargetTestCount = previousMilestone ? previousMilestone.targetTestCount + 1 : milestone.subMilestones[0].actualTests;
  const maxTargetTestCount = nextMilestone ? nextMilestone.targetTestCount - 1 : 10000;

  /*
    Minimum Planned Velocity:
     - Must be enough to reach the minimum target test count minus the initial test count when multiplied by the total number of weeks
    Maximum Planned Velocity:
     - Must not exceed the maximum target test count minus the initial test count when multiplied by the total number of weeks
  */
  const initialTestCount = milestone.initialTestCount || milestone.subMilestones[0].actualTests;
  const numberOfWeeks = getNumberOfWeeks(updatedMilestoneValues.startDate, updatedMilestoneValues.externalDueDate);
  const minNewTests = minTargetTestCount - initialTestCount;
  const maxNewTests = maxTargetTestCount - initialTestCount;
  const minPlannedVelocity = +(minNewTests / numberOfWeeks).toFixed(2);
  const maxPlannedVelocity = +(maxNewTests / numberOfWeeks).toFixed(2);

  const [errors, setErrors] = useState({
    name: '',
    notes: '',
    startDate: '',
    externalDueDate: '',
    targetTestCount: '',
    testVelocity: '',
  });

  const handleUpdate = (key: keyof UpdatedMilestoneValues, value: string | Dayjs) => {
    const updatedValues: Partial<UpdatedMilestoneValues> = {};

    if (key === 'name' || key === 'notes') {
      updatedValues[key] = value as string;
      setUpdatedMilestoneValues((prev) => ({ ...prev, ...updatedValues }));
    }

    if (key === 'startDate') {
      const updatedNumberOfWeeks = getNumberOfWeeks(value as Dayjs, updatedMilestoneValues.externalDueDate);
      const expectedInitialTestCount = milestone.previousMilestone ? milestone.previousMilestone.targetTestCount : milestone.initialTestCount || 0;
      const updatedTestsToActivate = +updatedMilestoneValues.targetTestCount - expectedInitialTestCount;
      const updatedPlannedVelocity = +(updatedTestsToActivate / updatedNumberOfWeeks).toFixed(2);

      if (dayjs(value).isAfter(maxStartDate)) {
        setErrors({ ...errors, [key]: 'Start date must be before ' + maxStartDate.format('MM/DD/YYYY') });
      } else if (dayjs(value).isBefore(minStartDate)) {
        setErrors({ ...errors, [key]: 'Start date must be after ' + minStartDate.format('MM/DD/YYYY') });
      } else {
        setErrors({ ...errors, [key]: '' });
        updatedValues[key] = value as Dayjs;
        updatedValues.testVelocity = updatedPlannedVelocity;

        // Recalculate the submilestones based on the new start date and planned velocity
        const newSubMilestones = Array.from({ length: updatedNumberOfWeeks }, (_, i) => {
          const dueDate = dayjs(value).add(i, 'week').endOf('week');
          const matchingSubMilestone = milestone.subMilestones.find((sm) => dayjs(sm.dueDate).isSame(dueDate, 'week'));
          return {
            id: -i,
            milestone: milestone,
            createdAt: new Date(),
            milestoneId: milestone.id,
            weekNumber: i + 1,
            targetTests: Math.min(
              (milestone.initialTestCount as number) + Math.round((i + 1) * updatedPlannedVelocity),
              updatedMilestoneValues.targetTestCount,
            ),
            dueDate: dueDate.toDate(),
            // If a match is not found, this is a new submilestone, so use the first existing submilestone's activeTestIds and actualTests values
            activeTestIds: matchingSubMilestone ? matchingSubMilestone.activeTestIds : milestone.subMilestones[0].activeTestIds,
            actualTests: matchingSubMilestone ? matchingSubMilestone.actualTests : milestone.subMilestones[0].actualTests,
          };
        });
        updatedValues.subMilestones = newSubMilestones;
      }
      setUpdatedMilestoneValues((prev) => ({ ...prev, ...updatedValues }));
    }

    if (key === 'externalDueDate') {
      const updatedNumberOfWeeks = getNumberOfWeeks(updatedMilestoneValues.startDate, value as Dayjs);
      const expectedInitialTestCount = milestone.previousMilestone ? milestone.previousMilestone.targetTestCount : milestone.initialTestCount || 0;
      const updatedTestsToActivate = +updatedMilestoneValues.targetTestCount - expectedInitialTestCount;
      const updatedPlannedVelocity = +(updatedTestsToActivate / updatedNumberOfWeeks).toFixed(2);

      if (maxExternalDueDate && dayjs(value).isAfter(maxExternalDueDate)) {
        setErrors({ ...errors, [key]: 'External due date must be before ' + maxExternalDueDate.format('MM/DD/YYYY') });
      } else if (minExternalDueDate && dayjs(value).isBefore(minExternalDueDate)) {
        setErrors({ ...errors, [key]: 'External due date must be after ' + minExternalDueDate.format('MM/DD/YYYY') });
      } else {
        setErrors({ ...errors, [key]: '' });
        updatedValues[key] = value as Dayjs;
        updatedValues.testVelocity = updatedPlannedVelocity;

        // Recalculate the submilestones based on the new start date and planned velocity
        const newSubMilestones = Array.from({ length: updatedNumberOfWeeks }, (_, i) => {
          const dueDate = dayjs(updatedMilestoneValues.startDate).add(i, 'week').endOf('week');
          const matchingSubMilestone = milestone.subMilestones.find((sm) => dayjs(sm.dueDate).isSame(dueDate, 'week'));
          return {
            id: -i,
            milestone: milestone,
            createdAt: new Date(),
            milestoneId: milestone.id,
            weekNumber: i + 1,
            targetTests: Math.min(
              (milestone.initialTestCount as number) + Math.round((i + 1) * updatedPlannedVelocity),
              updatedMilestoneValues.targetTestCount,
            ),
            dueDate: dueDate.toDate(),
            // If a match is not found, this is a new submilestone, so use the last existing submilestone's activeTestIds and the current totalActiveTestCount
            activeTestIds: matchingSubMilestone ? matchingSubMilestone.activeTestIds : milestone.subMilestones.at(-1)?.activeTestIds || [],
            actualTests: matchingSubMilestone ? matchingSubMilestone.actualTests : totalActiveTestCount,
          };
        });
        updatedValues.subMilestones = newSubMilestones;
      }
      setUpdatedMilestoneValues((prev) => ({ ...prev, ...updatedValues }));
    }

    if (key === 'targetTestCount') {
      const updatedNumberOfWeeks = getNumberOfWeeks(updatedMilestoneValues.startDate, updatedMilestoneValues.externalDueDate);
      const expectedInitialTestCount = milestone.previousMilestone ? milestone.previousMilestone.targetTestCount : milestone.initialTestCount || 0;
      const updatedTestsToActivate = +value - expectedInitialTestCount;
      const updatedPlannedVelocity = +Math.ceil(updatedTestsToActivate / updatedNumberOfWeeks).toFixed(2);

      if (+value > maxTargetTestCount) {
        setErrors({ ...errors, [key]: 'Target test count must be no more than ' + maxTargetTestCount });
      } else if (+value < minTargetTestCount) {
        setErrors({ ...errors, [key]: 'Target test count must be at least ' + minTargetTestCount });
      } else {
        setErrors({ ...errors, [key]: '' });
        updatedValues.testVelocity = updatedPlannedVelocity;

        // Update the submilestones targetTests based on the new target test count
        updatedValues.subMilestones = updatedMilestoneValues.subMilestones.map((sm, i) => ({
          ...sm,
          targetTests: Math.min(
            (milestone.initialTestCount as number) + Math.ceil((i + 1) * updatedPlannedVelocity),
            +value,
          ),
        }));
      }
      updatedValues[key] = +value;
      setUpdatedMilestoneValues((prev) => ({ ...prev, ...updatedValues }));
    }

    if (key === 'testVelocity') {
      const updatedNumberOfWeeks = getNumberOfWeeks(updatedMilestoneValues.startDate, updatedMilestoneValues.externalDueDate);
      const expectedInitialTestCount = milestone.previousMilestone ? milestone.previousMilestone.targetTestCount : milestone.initialTestCount || 0;
      const updatedTestsToActivate = +value * updatedNumberOfWeeks;
      const updatedTargetTestCount = +(updatedTestsToActivate + expectedInitialTestCount).toFixed(2);

      if (+value > maxPlannedVelocity) {
        setErrors({ ...errors, [key]: 'Planned velocity must be no more than ' + maxPlannedVelocity });
      } else if (+value < minPlannedVelocity) {
        setErrors({ ...errors, [key]: 'Planned velocity must be at least ' + minPlannedVelocity });
      } else {
        setErrors({ ...errors, [key]: '' });
        updatedValues.targetTestCount = updatedTargetTestCount;
        // Update the submilestones targetTests based on the new planned velocity
        updatedValues.subMilestones = updatedMilestoneValues.subMilestones.map((sm) => ({
          ...sm,
          targetTests: Math.min(
            (milestone.initialTestCount as number) + Math.ceil(sm.weekNumber * +value),
            updatedTargetTestCount,
          ),
        }));
      }
      updatedValues[key] = +value;
      setUpdatedMilestoneValues((prev) => ({ ...prev, ...updatedValues }));
    }
  };

  return (
    <Card variant="elevation" sx={{ mb: '2em', mt: '1em' }}>
      <List>
        <CardItem name="Name" value={updatedMilestoneValues.name} handleChange={handleUpdate} isUpdating={isUpdating} keyName={'name'} />
        <CardItem
          name="Start Date"
          value={updatedMilestoneValues.startDate}
          handleChange={handleUpdate}
          isUpdating={isUpdating}
          keyName={'startDate'}
          shouldDisable={shouldDisableStartDate}
          minDate={minStartDate}
          maxDate={maxStartDate}
          error={errors.startDate}
        />
        <CardItem
          name="External Due Date"
          value={updatedMilestoneValues.externalDueDate}
          handleChange={handleUpdate}
          isUpdating={isUpdating}
          keyName={'externalDueDate'}
          minDate={minExternalDueDate}
          maxDate={maxExternalDueDate}
          error={errors.externalDueDate}
        />
        <CardItem name="Expected Starting Test Count" value={`${milestone.previousMilestone?.targetTestCount || milestone.initialTestCount}`} />
        <CardItem name="Actual Starting Test Count" value={`${milestone.initialTestCount}`} />
        <CardItem
          name="Target Active Test Count"
          value={`${updatedMilestoneValues.targetTestCount}`}
          handleChange={handleUpdate}
          isUpdating={isUpdating}
          keyName={'targetTestCount'}
          min={minTargetTestCount}
          max={maxTargetTestCount}
          error={errors.targetTestCount}
        />
        <CardItem name="Current Progress" value={activatedStr + remainingStr} />
        <CardItem
          name="Planned Velocity"
          value={
            isUpdating
              ? updatedMilestoneValues.testVelocity.toString()
              : `${Math.ceil(updatedMilestoneValues.testVelocity)} Test${plural(Math.ceil(updatedMilestoneValues.testVelocity))} per week`
          }
          handleChange={handleUpdate}
          isUpdating={isUpdating}
          keyName={'testVelocity'}
          min={minPlannedVelocity}
          max={maxPlannedVelocity}
          error={errors.testVelocity}
        />
        <CardItem
          name="Required Velocity"
          value={requiredVelocity === Infinity ? 'N/A' : `${Math.max(requiredVelocity, 0)} Test${plural(requiredVelocity)} per week`}
        />
      </List>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle2">Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MDEditor
            value={updatedMilestoneValues.notes}
            onChange={(val?: string) => {
              handleUpdate('notes', val ?? '');
            }}
            preview={isUpdating ? 'edit' : 'preview'}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
              components: {
                // Makes links open in new tab and allow Slack app communication
                a: ({ children, ...props }) => (
                  <a {...props} target="_blank" rel="opener">
                    {children}
                  </a>
                ),
              },
            }}
            height={150}
            data-color-mode="light"
            textareaProps={{
              placeholder: 'Enter details about the blocking issue and include links to follow up threads, etc.',
            }}
            style={{
              boxSizing: 'border-box',
            }}
            hideToolbar={!isUpdating}
          />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

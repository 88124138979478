import { ChangeEvent } from 'react';
import { Box, Checkbox, FormControlLabel, Divider, Typography } from '@mui/material';
import { CustomTableInstance, StatusColumn, StatusFilterProps } from './types';
import QAWTask, { QAWTaskStatus } from '@/types';

export default function StatusFilterSelect({
  column,
  table,
  statuses,
}: {
  column: StatusColumn<QAWTask>;
  table: CustomTableInstance<QAWTask>;
  statuses: StatusFilterProps[];
}) {
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as QAWTaskStatus;
    const isChecked = e.target.checked;

    // Update the filter value based on whether the value was check or unchecked
    const prevFilterValues = column.getFilterValue() || [];
    const newValues = isChecked ? [...prevFilterValues, value] : prevFilterValues.filter((v) => v !== value);

    // Set the new filter value
    column.setFilterValue(newValues);
  };

  const toggleFilterByChildTaskStatus = (e: ChangeEvent<HTMLInputElement>) => {
    // Update the table state to include or exclude child tasks in the status filter
    table.setState((prev) => {
      return {
        ...prev,
        includeChildTasks: {
          ...prev.includeChildTasks,
          status: e.target.checked,
        },
        // Trigger a re-filter when the status filter is toggled by adding a timestamp to the filter
        columnFilters: prev.columnFilters.map((filter) => {
          if (filter.id === 'status') return { ...filter, _refresh: Date.now() };
          return filter;
        }),
      };
    });
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {table.getState().groupCRandMRs && (
        <>
          <FormControlLabel
            control={<Checkbox disableRipple checked={table.getState().includeChildTasks?.status} onChange={toggleFilterByChildTaskStatus} />}
            label="Include Child Tasks in Status Filter"
          />
          <Divider sx={{ my: 1 }} />
        </>
      )}
      {statuses.map((status) => (
        <Box key={status.value} sx={{ display: 'flex', gap: 1, width: '100%' }}>
          <FormControlLabel
            sx={{ width: '100%' }}
            checked={column.getFilterValue()?.includes(status.value)}
            control={<Checkbox disableRipple value={status.value} onChange={handleFilterChange} />}
            label={
              <Box sx={{ backgroundColor: status.color, borderRadius: '4px', py: 0.5, px: 1.5 }}>
                <Typography color={status.textColor}>{status.label}</Typography>
              </Box>
            }
          />
        </Box>
      ))}
    </Box>
  );
}

import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import WolfLoader from '../../WolfLoader/WolfLoader';

import { WeeklyCreationChart } from '../charts';
import { WeeklyCreationComparisonTable } from '../tables';

import WeeklyTeamCreationTable from '../tables/WeeklyTeamCreationTable';
import { getCreationOutlineMaintenanceInsights, protectFilters, sumAllCreationOutliningMaintenanceTasks, filterQAEsBySearchTerm } from '../helpers';

/**
 *
 * @param {object} props
 * @param {import('../types').TeamInsightsFilter} props.filters
 * @returns {import('react').JSX.Element}
 */
export default function TeamCreationView({ filters }) {
  const [selectedQAE, setSelectedQAE] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // protect team filters from potential stale state
  filters = protectFilters(filters);

  // set filter types to ['testCreation', 'outline']
  filters.types = ['testCreation', 'outline'];

  const {
    data: creationInsights,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['creationInsights', filters],
    queryFn: () => getCreationOutlineMaintenanceInsights(filters),
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const teamSums = sumAllCreationOutliningMaintenanceTasks(creationInsights.teamTasksByWeekAndType);

  // Filtered QAEs for the combobox
  const filteredQAEs = filterQAEsBySearchTerm(searchTerm, creationInsights.team.members);

  if (creationInsights?.team?.members && (selectedQAE == null || !creationInsights.team.members.includes(selectedQAE))) {
    setSelectedQAE(creationInsights.team.members[0]);
  }

  return (
    <div className="min-h-screen px-4 pb-4 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-6 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          {filters.entity.type === 'team' && <img src={creationInsights.team.imageUrl} alt="avatar" className="h-14 w-14 rounded-full" />}
          <span className="font-semibold">{creationInsights[filters.entity.type].name}</span>
        </div>
      </div>
      <div className="isolate flex-col divide-y divide-gray-200 border">
        <div className="isolate flex divide-x divide-gray-200">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tests Completed: {teamSums.sumTests}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tasks/Workflows Completed: {teamSums.sumTasks}
          </span>
        </div>
        {teamSums && (
          <div className="isolate flex divide-x divide-gray-200">
            {['sumTests', 'sumTasks'].map((sumType, sumTypeIdx) => (
              <span
                key={sumTypeIdx}
                className="flex-col space-y-2 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-sm"
              >
                <p>
                  Creation - {sumType === 'sumTests' ? 'Tests' : 'Workflows'} {teamSums.sumsByType.testCreation?.[sumType] || 0}
                </p>
                <p>
                  Outline - {sumType === 'sumTests' ? 'Tests' : 'Tasks'} {teamSums.sumsByType.outline?.[sumType] || 0}
                </p>
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="py-4">
        <WeeklyCreationChart data={creationInsights} />
      </div>
      <div className="border p-4 mb-4">
        <WeeklyTeamCreationTable creationInsights={creationInsights} teamSums={teamSums} />
      </div>

      <div className="border p-4">
        <div className="flex flex-col flex-wrap items-center justify-center space-x-4 space-y-4">
          <div className="flex flex-wrap items-center justify-center space-x-4 mb-4">
            <span className="text-lg font-semibold">Select QAE to view their weekly creation and outlining insights:</span>
            <div className="relative w-64">
              <Combobox as="div" value={selectedQAE} onChange={setSelectedQAE}>
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(event) => setSearchTerm(event.target.value)}
                  displayValue={(qae) => qae?.name || ''}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                {filteredQAEs.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredQAEs
                      .sort((a, b) => {
                        if (a.isLead) return 1;
                        if (b.isLead) return -1;
                        return a.name.localeCompare(b.name);
                      })
                      .map((qae) => (
                        <Combobox.Option
                          key={qae.qawId}
                          value={qae}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'}`
                          }
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <img src={qae.avatar48} alt="avatar" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                <span className={`ml-3 truncate ${selected ? 'font-semibold' : ''}`}>{qae.name}</span>
                              </div>
                              {selected && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                )}
              </Combobox>
            </div>
          </div>
        </div>

        {selectedQAE &&
          (() => {
            const selectedQAECreationOutliningTasks = creationInsights.teamTasksByWeekAndType.filter(
              (x) => x.completed_by_qaw_id === selectedQAE.qawId,
            );

            const qaeSums = sumAllCreationOutliningMaintenanceTasks(selectedQAECreationOutliningTasks);

            return (
              <WeeklyCreationComparisonTable
                creationInsights={{
                  qae: selectedQAE,
                  qaeTasksByWeekAndType: selectedQAECreationOutliningTasks,
                }}
                qaeSums={qaeSums}
                teamCreationInsights={creationInsights}
                teamSums={teamSums}
              />
            );
          })()}
      </div>
    </div>
  );
}

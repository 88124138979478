import { useState, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { marked } from 'marked';
import { useHandleSubmissionChange } from '../../HiringUtils/helperFunctions';
import './MarkdownPreviewer.css'; // Import CSS file

function MarkdownPreviewer({ notes, row, style }) {
    const initialFormState = useMemo(() => ({
        notes: notes || '',
    }), [notes, row]);

    const [formState, setFormState] = useState(initialFormState);
    const [edit, setEdit] = useState(false);

    const handleSubmissionChange = useHandleSubmissionChange(setFormState);

    const handleInputChange = (e) => {
        setFormState({ notes: e.target.value });
    };

    const handleSave = () => {
        handleSubmissionChange({
            submissionId: row.id,
            newInfo: formState,
            row,
        });
        setEdit(false);
    };

    return (
        <div style={{ height: '100%' }}>
            <Stack direction="row" spacing={2}>
                <Button variant={edit ? "outlined" : "contained"} onClick={() => setEdit(false)}>
                    Preview
                </Button>
                <Button variant={edit ? "contained" : "outlined"} onClick={() => setEdit(true)}>
                    Write
                </Button>
                <Button variant={"contained" } onClick={handleSave}>
                    Save Notes
                </Button>
            </Stack>

            <div
                style={style || {
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1rem 0',
                    fontFamily: 'Arial, sans-serif',
                    width: '100%',
                    height: '375px',
                }}
            >
                {edit && (
                    <textarea
                        value={formState.notes}
                        onChange={handleInputChange}
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '1rem',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                            fontSize: '16px',
                        }}
                        placeholder="Enter Markdown here..."
                    />
                )}
                {!edit && (
                    <div
                        className="markdown-container"
                        dangerouslySetInnerHTML={{ __html: marked(formState.notes) }}
                    />
                )}
            </div>
        </div>
    );
}

export default MarkdownPreviewer;

import axios from 'axios';
import { logout } from '../components/LoginPage/authActions';
import { checkAuthentication } from '../components/Layout/helpers';

// eslint-disable-next-line no-undef
let baseUrl = `${process.env.REACT_APP_URL}/apis/task-wolf`;

export async function sendPostRequest(url, jsonData) {
  // Skip checkAuthentication if it's part of login request
  let urlWithoutParams = url.split('?')[0];
  const isLoginRequest = ['/sendlink'].includes(urlWithoutParams);
  if (!isLoginRequest) {
    // Check if user is authenticated
    const isAuthenticated = await checkAuthentication();

    // Logout if user isn't authenticated
    if (!isAuthenticated) {
      logout('true');
      return;
    }
  }

  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringifiedData = JSON.stringify(jsonData);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringifiedData,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    // if status isn't a 20*
    if (!result.status.toString().startsWith('20')) {
      throw new Error(result.data.error || 'Request failed');
    }

    return {
      success: true,
      data: result.data,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  }
}

export async function sendGetRequest(url) {
  // Skip checkAuthentication if it's part of login requests
  let urlWithoutParams = url.split('?')[0];
  const isLoginRequest = ['/login', '/checklogin'].includes(urlWithoutParams);
  if (!isLoginRequest) {
    // Check if user is authenticated
    const isAuthenticated = await checkAuthentication();

    // Logout if user isn't authenticated
    if (!isAuthenticated) {
      logout('true');
      return {
        success: false,
        error: null,
        data: null,
      };
    }
  }

  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringiedData = JSON.stringify({});

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringiedData,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
    };
  }
}

export async function sendNoAuthGetRequest(url) {
  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringifiedData = JSON.stringify({});

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringifiedData,
    };

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
    };
  }
}

export async function sendPutRequest(url, jsonData) {
  // Check if user is authenticated
  const isAuthenticated = await checkAuthentication();
  if (!isAuthenticated) {
    logout('true');
    return;
  }

  try {
    const targetUrl = `${baseUrl}${url}`;
    let stringifiedData = JSON.stringify(jsonData);

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: stringifiedData,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
      jsonData: jsonData, // For identifying tasks to re-enable if needed
    };
  }
}

export async function sendMultiPartPostRequest(url, data) {
  try {
    const targetUrl = `${baseUrl}${url}`;

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: targetUrl,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    };

    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    let result = await axios.request(config);

    return {
      success: true,
      data: result.data,
      jsonData: data, // For identifying tasks to re-enable if needed
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: err,
      jsonData: data, // For identifying tasks to re-enable if needed
    };
  }
}

export const layout = {
  name: 'cose-bilkent',
  directed: true,
  rankDir: 'LR',
  padding: 50,
  spacingFactor: 2,
  rankSep: 150,
  nodeSep: 100,
  animate: true,
  animationDuration: 500,
  fit: true,
  edgeSep: 50,
} as const;

import { ReactNode, useState } from 'react';
import QAWTask from '../../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { flattenTaskData } from '../../../Tasks/helpers';
import TasksTable from '../../../shared/TasksTable';
import dayjs from 'dayjs';
import Toast from '../../../Layout/Toast';
import { defaultTableState, usePlanningContext } from '../../../context/PlanningContext';

type ToastData = {
  title: string;
  message: string;
  isSuccess: boolean;
  content: ReactNode | null;
};

export function PlanningTasksTable({
  clientTasks,
  isPlaceholderData,
  isFetching,
  isError,
  error,
  queryKey,
}: {
  clientTasks: QAWTask[];
  isPlaceholderData: boolean;
  isFetching: boolean;
  isError: boolean;
  error: Error | null;
  selectedView: 'testCoverageRequest' | 'testMaintenance' | 'blockers';
  selectedTaskWeek: dayjs.Dayjs | null;
  queryKey: string[];
}) {
  const [toastData, setToastData] = useState<ToastData | null>(null);
  const { tableState, setTableState } = usePlanningContext();
  const queryClient = useQueryClient();

  if (isError)
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>{'Error loading client tasks...'}</h1>
        <p>{error?.message}</p>
      </div>
    );

  return (
    <>
      <Toast
        title={toastData?.title ?? ''}
        message={toastData?.message ?? ''}
        show={toastData !== null}
        onDone={() => setToastData(null)}
        isSuccess={toastData?.isSuccess}
        content={<></>}
      />
      <TasksTable
        data={flattenTaskData(clientTasks, !!tableState.groupCRandMRs)}
        initialInstanceState={{
          columnVisibility: {
            customer: false,
            name: true,
            workflowStatus: false,
            type: false,
            childCount: false,
          },
        }}
        setToastData={setToastData}
        showSkeletons={isPlaceholderData && isFetching}
        tableState={tableState}
        setTableState={setTableState}
        saveViewButton={undefined}
        clearSelectedView={undefined}
        selectedView={undefined}
        defaultTableState={defaultTableState}
        queryClient={queryClient}
        queryKey={queryKey}
      />
    </>
  );
}

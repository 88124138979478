import { XCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { getTeamInfo } from '../../utils/helpers';
import { sendGetRequest, sendPostRequest } from '../../utils/network';
import Toast from '../Layout/Toast';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Settings() {
  let teamsData = getTeamInfo();
  const userId = localStorage.getItem('userId');
  const [allClients, setAllClients] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(teamsData.find((team) => team.selectedInSettings));
  const [selectedTeamClients, setSelectedTeamClients] = useState([]);
  const [unsubbedClients, setUnsubbedClients] = useState([]);
  const [toastData, setToastData] = useState(null);

  if (!selectedTeam) {
    handleSwitchTeams(teamsData[0]);
  }

  // Get list of team's clients on page load
  useEffect(() => {
    (async () => {
      // get all clients
      const rawClients = await sendGetRequest('/clients').catch((e) => {
        throw new Error(e);
      });
      const flattenedClients = Object.entries(rawClients.data).map(([key, value]) => ({ ...value, key }));
      setAllClients(flattenedClients);

      if (userId !== null) {
        // get user details & prefs
        const { data: userPrefs } = await sendPostRequest('/user-prefs', { qawId: userId, page: '/settings' }).catch((e) => {
          throw new Error(e);
        });
        setUnsubbedClients(userPrefs.suiteConfig.excludedClients);
      }
    })();
  }, []);

  // Get list of team's clients on team change
  useEffect(() => {
    const teamClients = allClients.filter((client) => client.qaLead.email === selectedTeam.email);
    setSelectedTeamClients(teamClients);
  }, [selectedTeam, allClients]);

  // switch teams
  function handleSwitchTeams(clickedTeam) {
    const newTeams = teamsData.map((team) => {
      if (team.name === clickedTeam.name) {
        return { ...team, selectedInSettings: true };
      } else {
        return { ...team, selectedInSettings: false };
      }
    });

    if (newTeams) localStorage.setItem('teams', JSON.stringify(newTeams));
    setSelectedTeam(clickedTeam);
  }

  async function unsubscribeFromClient(clientId, clientName) {
    const { data: userPrefs } = await sendPostRequest('/client-unsubscribe', { qawId: userId, clientId }).catch((e) => {
      setToastData({
        title: 'Ruh-roh',
        message: `Something went wrong: ${e.message}`,
        isSuccess: false,
      });
    });

    setUnsubbedClients(userPrefs.suiteConfig.excludedClients);
    setToastData({
      title: 'Success!',
      message: `Unsubscribed from ${clientName}`,
      isSuccess: true,
    });
  }

  async function subscribeToClient(clientId, clientName) {
    const { data: userPrefs } = await sendPostRequest('/client-subscribe', { qawId: userId, clientId }).catch((e) => {
      setToastData({
        title: 'Ruh-roh',
        message: `Something went wrong: ${e.message}`,
        isSuccess: false,
      });
    });

    setUnsubbedClients(userPrefs.suiteConfig.excludedClients);
    setToastData({
      title: 'Success!',
      message: `Subscribed to ${clientName}`,
      isSuccess: true,
    });
  }

  return (
    <>
      <div className="pb-5 ml-4 mr-4 sm:pb-0">
        <h1 className="text-base font-semibold leading-6 text-gray-900">Notification Preferences</h1>
        {/* Team Nav */}
        <div className="mt-3 sm:mt-4">
          <nav className="mb-5 flex space-x-8 border-b border-gray-200 ">
            {teamsData.map((team) => (
              <a
                style={{ cursor: 'pointer' }}
                key={team.qawId}
                id={team.teamName}
                onClick={() => {
                  handleSwitchTeams(team);
                }}
                className={classNames(
                  team.selectedInSettings
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                )}
                aria-current={team.selectedInSettings ? 'page' : undefined}
              >
                {team.teamName}
              </a>
            ))}
          </nav>
        </div>

        <div className="flex justify-between">
          {/* Info box */}
          <div className="rounded-md bg-blue-50 p-4 ml-4 max-w-max">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:justify-between text-sm text-blue-700">
                <ul>
                  <li>
                    By default, when you start a shift from the{' '}
                    <a href="/triage" className="underline font-semibold">
                      Investigation page
                    </a>{' '}
                    you'll be subscribed to all clients for the selected team(s).
                  </li>
                  <li>You can unsubscribe from specific clients here to filter out notifications for their messages & suites.</li>
                  <li>Prefrences are saved between shifts and you'll be subscribed to new clients by default.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Clients table */}
      <div className={`px-4 mt-10`}>
        <h2 className="ml-2 text-base font-semibold leading-6 text-gray-900">Team Clients</h2>
        <div className="relative">
          <table className="min-w-full table-fixed divide-y divide-gray-300 border-t border-gray-300">
            <tbody className="divide-y divide-gray-200 bg-white">
              {selectedTeamClients?.map((client) => (
                <tr key={client.id}>
                  {/* Suites column */}
                  <td className="relative px-7 sm:w-12 sm:px-6">
                    {unsubbedClients?.includes(client.id) ? (
                      <button
                        type="button"
                        className="w-full m-1 inline-flex items-center gap-x-1 rounded-md bg-green-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        onClick={() => subscribeToClient(client.id, client.name)}
                      >
                        <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Subscribe
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="w-full m-1 inline-flex items-center gap-x-1 rounded-md bg-red-400 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        onClick={() => unsubscribeFromClient(client.id, client.name)}
                      >
                        <XCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Unsubscribe
                      </button>
                    )}
                  </td>

                  {/* Name column */}
                  <td
                    className={classNames(
                      'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                      unsubbedClients?.includes(client.id) ? 'text-gray-500' : 'text-gray-900',
                    )}
                  >
                    {client.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </>
  );
}

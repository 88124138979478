import { Bar, BarChart, Area, AreaChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';
import { processHourlyChartData, processWeeklyChartData } from './utils/process-chart-data';
import { SuiteFailureData } from './types';
import { TooltipProps } from 'recharts';
import { stringToColor } from '@/utils/colorUtils';
import dayjs from 'dayjs';

export default function Chart({ data, selectedDay }: { data: SuiteFailureData[]; selectedDay: number }) {
  const isWeekView = selectedDay === 7;
  const chartData = isWeekView ? processWeeklyChartData(data) : processHourlyChartData(data, selectedDay);

  // Sort customers by their total failures (descending)
  const sortedCustomers = [...data].sort((a, b) => {
    const aTotal = a.forecast.forecastData.reduce((sum, point) => sum + point.value, 0);
    const bTotal = b.forecast.forecastData.reduce((sum, point) => sum + point.value, 0);
    return bTotal - aTotal;
  });

  const chartConfig = sortedCustomers.reduce((acc, customer) => {
    acc[`customer${customer.customerId}`] = {
      label: customer.customerName,
      color: stringToColor(customer.customerName),
    };
    return acc;
  }, {} as Record<string, { label: string; color: string }>);

  const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      const activeEntries = payload.filter((entry) => (entry.value as number) >= 0.5);
      const totalFailures = activeEntries.reduce((sum, entry) => sum + (Number(entry.value) || 0), 0);
      const timeLabel = dayjs
        .utc(payload[0]?.payload.date)
        .local()
        .format('dddd MMMM Do, HH:mm');
      return (
        <Box sx={{ bgcolor: 'background.paper', p: 2, border: 1, borderColor: 'divider', borderRadius: 1, boxShadow: 3 }}>
          <Typography variant="subtitle2">{timeLabel}</Typography>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {`Total Failures: ${Math.round(totalFailures)}`}
          </Typography>
          {activeEntries.map((entry) => (
            <Typography key={entry.name} variant="body2" style={{ color: entry.color || '#000' }}>
              {`${entry.name}: ${Math.round(entry.value as number)}`}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  if (isWeekView) {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="date" tickFormatter={(date) => dayjs.utc(date).local().format('MMM D')} interval={24} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {[...sortedCustomers].reverse().map((customer) => (
            <Area
              key={customer.customerId}
              dataKey={`customer${customer.customerId}`}
              stackId="1"
              stroke={chartConfig[`customer${customer.customerId}`].color}
              fill={chartConfig[`customer${customer.customerId}`].color}
              name={customer.customerName}
              isAnimationActive={false}
              connectNulls={true}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <XAxis
          dataKey="date"
          tickFormatter={(date) => {
            return dayjs.utc(date).local().format('HH:mm');
          }}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {[...sortedCustomers].reverse().map((customer) => (
          <Bar
            key={customer.customerId}
            dataKey={`customer${customer.customerId}`}
            stackId="a"
            fill={chartConfig[`customer${customer.customerId}`].color}
            name={customer.customerName}
            isAnimationActive={false}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

import { classNames } from '../../InvestigationBoard/helpers.jsx'; // Ensure this path is correct
import {
  computeTeamInsightsForInvestigationCompTable,
  computeQAEInsightsForInvestigationCompTable,
  computeComparisonInsightsForInvestigationCompTable,
  determineColorForInvestigationCompTable,
} from './tableHelpers';

function WeeklyTeamInvestigationTable({ investigationData }) {
  const CATEGORIES = [
    'Failures Investigated',
    'Average Failures Per Week',
    'Test Steps Investigated',
    'Average Test Steps Per Week',
    'Failures Passed on Retry',
    'Average Failures Passed on Retry Per Week',
    'Failures Passed with Fixes',
    'Average Failures Passed with Fixes Per Week',
    'Failures Investigated as Bug',
    'Average Failures Investigated as Bug Per Week',
    'Failures Investigated as Maintenance',
    'Average Failures Investigated as Maintenance Per Week',
    'Suites Investigated',
    'Average Suites Per Week',
    'Median Suites Per Week',
    'Max Suites Per Week',
    'Min Suites Per Week',
  ];

  const teamAggregateData = {
    weekly: investigationData.teamAggregateData.weekly,
    totals: investigationData.teamAggregateData.totals,
  };

  const computedTeamInsights = computeTeamInsightsForInvestigationCompTable(teamAggregateData);

  const qaeDataList = investigationData.team.members
    .filter((member) => !member.isManager)
    .sort((a, b) => {
      if (a.isLead) return 1;
      if (b.isLead) return -1;
      return a.name.localeCompare(b.name);
    })
    .map((qae) => {
      const qaeData = investigationData.teamAggregateData[qae.email];

      const computedQAEInsights = computeQAEInsightsForInvestigationCompTable(qaeData);
      const comparisonInsights = computeComparisonInsightsForInvestigationCompTable(computedQAEInsights, computedTeamInsights);

      return {
        qae,
        computedQAEInsights,
        comparisonInsights,
      };
    });

  return (
    <div className="w-full h-full overflow-hidden border border-gray-600">
      <div className="overflow-x-auto">
        <table className="min-w-full min-h-full text-left">
          <thead>
            <tr>
              <th className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-100 min-w-[240px] border-b border-gray-400"></th>
              <th className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-900 bg-gray-100 min-w-[110px] border-b border-gray-400"></th>
              {qaeDataList.map((data, index) => (
                <th
                  key={`qae-metric-${index}`}
                  colSpan={2}
                  className="px-4 py-2 text-center text-sm font-medium text-gray-900 bg-gray-100 border-b border-l border-gray-400"
                >
                  <div className="flex items-center justify-center space-x-2">
                    <img src={data.qae.avatar48} alt={`${data.qae.name}'s avatar`} className="h-6 w-6 rounded-full" />
                    <span>{data.qae.name.split(' ')[0]}</span>
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-200 border-b border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                Metric
              </th>
              <th className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400 outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                Team Total
              </th>
              {qaeDataList.map((_, index) => (
                <>
                  <th
                    key={`metric-header-${index}`}
                    className="px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400"
                  >
                    Metric
                  </th>
                  <th
                    key={`comparison-header-${index}`}
                    className="px-4 py-2 text-center text-sm font-medium text-gray-700 bg-gray-200 border-b border-l border-gray-400"
                  >
                    Comparison
                  </th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {CATEGORIES.map((category, categoryIndex) => (
              <tr
                key={categoryIndex}
                className={classNames(
                  'divide-x divide-x-gray-200 border-b border-b-gray-200 hover:border-t-2 hover:border-b-2 hover:border-gray-400 hover:shadow-lg',
                  category === 'Median Suites Per Week' && 'border-b-2 border-b-gray-400',
                )}
              >
                <td className="sticky left-0 px-4 py-2 text-left text-sm font-medium text-gray-800 min-w-[240px] bg-white outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                  {category}
                </td>
                <td className="sticky left-[240px] px-4 py-2 text-center text-sm font-medium text-gray-900 border-l border-gray-400 bg-white outline [outline-width:0.2px] [outline-color:theme(colors.gray.400)] outline-offset-[-0.5px]">
                  {computedTeamInsights[category] || 0}
                </td>
                {qaeDataList.map((data, colIndex) => (
                  <>
                    <td
                      key={`metric-${categoryIndex}-${colIndex}`}
                      className={classNames(
                        'px-4 py-2 text-center text-sm border-l border-gray-400',
                        [15, 16].includes(categoryIndex)
                          ? ''
                          : determineColorForInvestigationCompTable(
                              category,
                              data.comparisonInsights[category],
                              data.qae,
                              investigationData.team,
                              computedTeamInsights,
                            ),
                      )}
                    >
                      {data.computedQAEInsights[category] || 0}
                    </td>
                    <td
                      key={`comparison-${categoryIndex}-${colIndex}`}
                      className={classNames(
                        'px-4 py-2 text-center text-sm',
                        [15, 16].includes(categoryIndex)
                          ? ''
                          : determineColorForInvestigationCompTable(
                              category,
                              data.comparisonInsights[category],
                              data.qae,
                              investigationData.team,
                              computedTeamInsights,
                            ),
                      )}
                    >
                      {data.comparisonInsights[category] || 0}
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WeeklyTeamInvestigationTable;

import { Add, ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { Content, Inner, Header, Title, StyledDrawer, SubDrawerBtn } from './subComponents';

export function CreationDrawer({ children, text, closeParents }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  return (
    <Box>
      <SubDrawerBtn text={text} onClick={toggleSelf} endIcon={<Add sx={{ height: '15px', width: '15px' }} />} />
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Title text={text} />
          </Header>
          <Content>
            {children}
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}

import { useState } from 'react';
import { ClientSummaryTableRow } from '../types';
import { Box, IconButton } from '@mui/material';
import { Content, Header, Inner, StyledDrawer, SubDrawerBtn, Title } from './subComponents';
import { Add, ArrowBack } from '@mui/icons-material';
import { MsBlockerCreationForm } from './MsBlockerCreationForm';

export default function MsBlockerCreationDrawer({ client, closeParents }: {  client: ClientSummaryTableRow; closeParents: () => void; }) {
  // Set to open if it is the current milestone
  const [isOpen, setIsOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
    setIsPreview(false);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  return (
    <Box>
      <SubDrawerBtn onClick={toggleSelf} text={`Create Milestone Blocker`} endIcon={<Add sx={{ height: '15px', width: '15px' }} />}/>
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Title text={`Create Milestone Blocker`} />
          </Header>
          <Content>
            <MsBlockerCreationForm client={client} toggleParent={toggleSelf} isPreview={isPreview} setIsPreview={setIsPreview} />
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}
